import { Route } from 'vue-router';
import Vue from 'vue';

/**
 * Vue is used as a lightweight pub-sub model per https://alligator.io/vuejs/global-event-bus/
 */
const eventBus = new Vue();

// Event names
const traceEventName = 'trace';
const actionEventName = 'action';
const authenticatedEventName = 'authenticated';
const clickEventName = 'click';
const emailVerificationEventName = 'emailVerification';
const errorEventName = 'exception';
const existingUserEventName = 'existingUser';
const userIdentificationEventName = 'userIdentification';
const formSubmissionEventName = 'formSubmission';
const integrationTestResultsEventName = 'integrationTestResults';
const websiteUpdatedEventName = 'websiteUpdated';
const mssDeclinedEventName = 'mssDeclined';
const mssStageCompletedEventName = 'mssStageCompleted';
const pageViewEventName = 'pageView';
const verificationEmailResentEventName = 'verificationEmailResent';
const startedStripeEventName = 'startedStripe';
const intercomLoadedEventName = 'intercomLoaded';
const integrationPageLoadedEventName = 'integrationPageLoaded';
const integrationTestPageLoadedEventName = 'integrationTestPageLoaded';

/**
 * This API is used to enforce type safety for all events and their handlers.
 * We could rely on each client using $emit and $on methods of the vue instance,
 * but it would be easy to fall into a trap of varying contracts for each consumer.
 * This ensures everyone understands the same contract and also promotes testability.
 */
export default {
  publishTraceEvent(message: string, ...args: any[]) {
    eventBus.$emit(traceEventName, message, args);
  },

  subscribeTraceEvent(handler: (message: string, ...args: any[]) => void) {
    eventBus.$on(traceEventName, handler);
  },

  publishTrackActionEvent(actionName: string, actionInfo?:Record<string, unknown>) {
    eventBus.$emit(actionEventName, actionName, actionInfo);
  },

  subscribeTrackActionEvent(handler: (actionName: string, actionInfo?:Record<string, unknown>) => void) {
    eventBus.$on(actionEventName, handler);
  },

  publishTrackClickEvent(itemClicked: string, itemInfo?:Record<string, unknown>) {
    eventBus.$emit(clickEventName, itemClicked, itemInfo);
  },

  subscribeTrackClickEvent(handler: (itemClicked: string, intemInfo?:Record<string, unknown>) => void) {
    eventBus.$on(clickEventName, handler);
  },

  publishAuthenticatedEvent(subClaim: string, merchantId?: string) {
    eventBus.$emit(authenticatedEventName, subClaim, merchantId);
  },

  subscribeAuthenticatedEvent(handler: (subClaim: string, merchantId?: string) => void) {
    eventBus.$on(authenticatedEventName, handler);
  },

  publishEmailVerificationEvent(verified: boolean, merchantId: string, reason = '') {
    eventBus.$emit(emailVerificationEventName, verified, merchantId, reason);
  },

  subscribeEmailVerificationEvent(handler: (verified: boolean, merchantId: string, reason: string) => void) {
    eventBus.$on(emailVerificationEventName, handler);
  },
  
  publishExistingUserEvent(existing: boolean) {
    eventBus.$emit(existingUserEventName, existing);
  },

  subscribeExistingUserEvent(handler: (existing: boolean) => void) {
    eventBus.$on(existingUserEventName, handler);
  },

  publishFormSubmissionEvent(formName: string, valid = true, inputs: any[] = [], ...errorMessages: any[]) {
    eventBus.$emit(formSubmissionEventName, formName, valid, inputs, errorMessages);
  },

  subscribeFormSubmissionEvent(
    handler: (formName: string, valid: boolean, inputs: any[], ...errorMessages: any[]) => void) {
    eventBus.$on(formSubmissionEventName, handler);
  },

  publishIntegrationTestResultsEvent(merchantId: string, success: boolean, widgetVerified: boolean, integrationVerified: boolean) {
    eventBus.$emit(integrationTestResultsEventName, merchantId, success, widgetVerified, integrationVerified);
  },

  subscribeIntegrationTestResultsEvent(handler: (merchantId: string, success: boolean, widgetVerified: boolean, integrationVerified: boolean) => void) {
    eventBus.$on(integrationTestResultsEventName, handler);
  },

  publishWebsiteUpdatedEvent() {
    eventBus.$emit(websiteUpdatedEventName);
  },

  subscribeWebsiteUpdatedEvent(handler: () => void) {
    eventBus.$on(websiteUpdatedEventName, handler);
  },
  
  publishMssDeclinedEvent(declineReason: string) {
    eventBus.$emit(mssDeclinedEventName, declineReason);
  },

  subscribeMssDeclinedEvent(handler: (declineReason: string) => void) {
    eventBus.$on(mssDeclinedEventName, handler);
  },

  publishMssStageCompletedEvent(stage: string, attempts = 0) {
    eventBus.$emit(mssStageCompletedEventName, stage, attempts);
  },

  subscribeMssStageCompletedEvent(handler: (stage: string, attempts: number) => void) {
    eventBus.$on(mssStageCompletedEventName, handler);
  },

  publishPageViewEvent(route: Route) {
    eventBus.$emit(pageViewEventName, route);
  },

  subscribePageViewEvent(handler: (route: Route) => void) {
    eventBus.$on(pageViewEventName, handler);
  },

  publishVerificationEmailResentEvent() {
    eventBus.$emit(verificationEmailResentEventName);
  },

  subscribeVerificationEmailResentEvent(handler: () => void) {
    eventBus.$on(verificationEmailResentEventName, handler);
  },

  /**
   * Publish an event signifying an application error
   * @param error
   * @param handled Was it handled or did it bubble up to the global handler?
   * @param id Used to lookup the error in analytics platforms
   */
  publishErrorEvent(error: Error, handled = true, id?: string) {
    eventBus.$emit(errorEventName, error, handled, id);
  },

  subscribeErrorEvent(handler: (error: Error, handled: boolean, id?: string) => void) {
    eventBus.$on(errorEventName, handler);
  },

  publishStartedStripeEvent() {
    eventBus.$emit(startedStripeEventName);
  },

  subscribeStartedStripeEvent(handler: () => void) {
    eventBus.$on(startedStripeEventName, handler);
  },

  publishIntercomLoadedEvent() {
    eventBus.$emit(intercomLoadedEventName);
  },

  subscribeIntercomLoadedEvent(handler: () => void) {
    eventBus.$on(intercomLoadedEventName, handler);
  },

  publishIntegrationPageLoadedEvent() {
    eventBus.$emit(integrationPageLoadedEventName);
  },

  subscribeIntegrationPageLoadedEvent(handler: () => void) {
    eventBus.$on(integrationPageLoadedEventName, handler);
  },

  publishIntegrationTestPageLoadedEvent() {
    eventBus.$emit(integrationTestPageLoadedEventName);
  },

  subscribeIntegrationTestPageLoadedEvent(handler: () => void) {
    eventBus.$on(integrationTestPageLoadedEventName, handler);
  },
};
