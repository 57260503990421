



















import { DisputeEvidenceFile } from "@/store/dispute/dispute-models";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { disputeStore } from "@/store/store";
import ActionButton from "@/components/controls/ActionButton.vue";

const FileNameCropLength = 36;

@Component({
  components: {
    "action-button": ActionButton,
  },
})
export default class DisputeIssuerEvidenceFile extends Vue {
  @Prop({ required: true })
  disputeId: string;

  @Prop({ required: true })
  file: DisputeEvidenceFile;

  isContentVisible = false;
  isLoadingContent = false;
  fileContentUrl: string | null = null;

  get name() {
    if (this.file.title) {
      return this.file.title;
    }

    if (this.file.filename) {
      return this.cropName(this.file.filename);
    }

    return this.cropName(this.file.id);
  }

  get extension() {
    if (!this.file?.type) {
      return null;
    }

    return this.file.type.toLowerCase().replace(/^\./g, "");
  }

  cropName(filename: string) {
    var parts = filename.split('.');
    var name = parts[0];
    var ext = filename.substring(parts[0].length);
    //if file name without extension contains more than 15 characters   
    if (name.length > FileNameCropLength) {
      return `${name.substring(0, FileNameCropLength - 10)}...${name.substring(name.length - 6)}${ext}`;
    }
  }

  @Watch("isContentVisible")
  async contentBecomesVisible() {
    if (!this.isContentVisible || this.fileContentUrl) {
      return;
    }

    this.isLoadingContent = true;

    const fileContentBlob = await disputeStore.getEvidenceFileBlob({
      disputeId: this.disputeId,
      fileId: this.file.id,
    });
    this.fileContentUrl = URL.createObjectURL(fileContentBlob);

    this.isLoadingContent = false;
  }

  async downloadFile() {
    const filename = this.file.filename ?? `${this.file.id}.${this.extension}`;
    await disputeStore.downloadEvidenceFile({
      disputeId: this.disputeId,
      categoryOrFileId: this.file.id,
      filename,
    });
  }

  beforeUnmount() {
    if (this.fileContentUrl) {
      // Apparently necessary to avoid memeory leaks...
      URL.revokeObjectURL(this.fileContentUrl);
    }
  }
}
