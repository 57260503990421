






import Insights from '@/components/insights/Insights.vue';
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'basic-layout': BasicLayout,
    'insights': Insights,
  }
})
export default class InsightsPage extends Vue {}
