






































































































import { SupportedLocale } from '@/store/application/application-models';
import { applicationStore, authenticationStore, merchantStore } from '@/store/store';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MerchantIdentity } from '@/store/merchant/merchant-models';
import TickIcon from '@/assets/images/icons/tick.svg?inline';

const territoryMap = {
  US: 'USA',
  GB: 'UK'
};

@Component({
  components: {
    'tick-icon': TickIcon
  }
})
export default class Header extends Vue {
  @Prop({ required: false, default: false })
  isMobile: boolean;
  @Prop({ required: false, default: false })
  drawerOpen: boolean;
  @Prop({ required: true })
  toggleNavDrawerFn: () => void;

  headerMenuOpened = false;

  get merchantName(): string {
    return merchantStore.merchantName;
  }

  get userName(): string {
    return authenticationStore.userName;
  }

  get merchantId(): string {
    return authenticationStore.merchantId;
  }

  get canSwitchIdentities(): boolean {
    return this.hasMultipleMerchantIdentities;
  }

  get hasMultipleMerchantIdentities(): boolean {
    return !!this.merchantIdentitiesForUser &&
      this.merchantIdentitiesForUser.length > 1;
  }

  get selectedMerchantName(): string {
    const identities = this.merchantIdentitiesForUser || [];
    const currentIdentity = identities.find(m => m.merchantId === this.merchantId);
    return currentIdentity
      ? currentIdentity.legalName || this.merchantName
      : this.merchantName;
  }

  get merchantIdentitiesForUser(): MerchantIdentity[] {
    return merchantStore.availableMerchantIdentities;
  }

  get hasMultipleSupportedLocales(): boolean {
    return applicationStore.supportedLocales.length > 1;
  }

  get supportedLocales(): SupportedLocale[] {
    return applicationStore.supportedLocales;
  }

  get currentTerritory(): string {
    return authenticationStore.currentTerritory;
  }

  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  get canDisplay(): boolean {
    // Defaults to false at beginning of page load when meta = {}
    return !!this.$route.meta?.showHeader;
  }

  getTerritoryName(territory: string) {
    return territoryMap[territory] || territory;
  }

  toggleSidebar() {
    this.toggleNavDrawerFn();
  }

  hideDropdownMenu() {
    this.headerMenuOpened = false;
  }

  async switchMerchant(identity: MerchantIdentity) {
    await applicationStore.switchMerchant(identity);
    this.hideDropdownMenu();
  }

  async switchLanguage(locale: SupportedLocale) {
    await applicationStore.switchLanguage(locale);
    this.hideDropdownMenu();
  }

  async logout(event: Event) {
    event.preventDefault();

    this.hideDropdownMenu();

    try {
      // Logout and redirect to logout page
      await authenticationStore.logout(false);
    } catch(e) {
      throw new Error(`Error logging out: ${JSON.stringify(e)}`);
    }
  }
}
