import LogMethod from '@/decorators/logger-decorator';
import {
  Invoice,
  InvoiceDetail,
  StatementSearchOptions,
  StatementSearchResult,
  InvoiceTransactionsByOrderResponse,
  StatementDetailsResult,
} from '@/store/statement/statement-models';
import store from '@/store/store';

import {
  config,
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { getObject, RequestUrl } from '../store-requests';


// Set rawError for all Actions in module to true
config.rawError = true;

const DefaultStatementArray = [];
const DefaultStatementCount = 0;
const DefaultLoadingStatements = true;
const DefaultLoadingStatementDetails = true;
const DefaultSearchOptions = { page: 0, pageSize: 0, startDate: '', endDate: '' };
const DefaultStatementDetails = {
  items: [],
  currencyCode: '',
  amount: 0,
  taxAmount: 0,
  openDate: new Date(),
  closeDate: new Date(),
  invoiceNumber: '',
  merchantTradingName: '',
  merchantLegalName: '',
  merchantId: '',
  invoiceId: '',
};

/**
 * The statement store is responsible for managing merchant invoice data and business logic
 */
@Module({
  name: 'statement',
  namespaced: true,
  store,
})
export default class statementStore extends VuexModule {
  statementArray: Invoice[] = DefaultStatementArray;
  statementCount: number = DefaultStatementCount;
  loadingStatements: boolean = DefaultLoadingStatements;
  loadingStatementDetails: boolean = DefaultLoadingStatementDetails;
  lastSearchOptions: StatementSearchOptions = DefaultSearchOptions;
  statementDetails: InvoiceDetail = DefaultStatementDetails;

  /**
   * Get Merchant Statements
   */
  @Action
  async searchStatements(searchOptions: { options: StatementSearchOptions, apiEndDate: string }): Promise<any> {
    this.setLoadingStatements(true);
    // record last search options
    this.setLastSearchOptions(searchOptions.options);

    // We want to capture the UI search values separately from what actually gets searched.
    // To do this, take the end date from the caller and overwrite its value in a clone used only for searching.
    const searchOptionsWithUpdatedEndDate: StatementSearchOptions = {
      ...searchOptions.options,
      endDate: searchOptions.apiEndDate
    };

    const url = {
      service: 'invoicing/search',
      query: searchOptionsWithUpdatedEndDate
    };

    const result = await getObject<StatementSearchResult>({
      url,
      options: {
        dataType: 'statements'
      }
    });
    if (result) {
      this.setStatementSearchResult(result);
    }
    this.setLoadingStatements(false);
  }

  /**
    * Get statement details by statementId
    * @param statementId  
    */
  @Action
  async loadStatementDetails(statementId: string): Promise<any> {
    this.setLoadingStatementDetails(true);

    const transactionRowsLimit = 1000; // Total rows limit
    const minimumHiddenRowsPerTransactionType = 5; // If hidden rows < this number, display all of them

    const url = {
      service: 'invoicing/invoice',
      query: {
        invoiceId: statementId,
        transactionRowsLimit,
        minimumHiddenRowsPerTransactionType
      }
    };

    const result = await getObject<StatementDetailsResult>({
      url,
      options: {
        dataType: 'statement details'
      }
    });
    if (result) {
      this.setStatementDetailsResult(result);
    }

    this.setLoadingStatementDetails(false);
  }

  @Action
  async getStatementTransactionsForOrder(orderId: string): Promise<InvoiceTransactionsByOrderResponse> {
    const url = {
      service: 'invoicing/order',
      query: { orderId }
    };

    const result = await getObject<InvoiceTransactionsByOrderResponse>({
      url,
      options: {
        dataType: 'order transactions'
      }
    });
    return result;
  }

  static getStatementTransactionsCsvUrl(query: any, statementId: string): RequestUrl {
    const url: RequestUrl = {
      service: 'invoicing/invoice/csv',
      query: { ...query, invoiceId: statementId }
    }
    return url;
  }

  static getStatementTransactionsCsvByDateRangeUrl(query: any): RequestUrl {
    const url: RequestUrl = {
      service: 'invoicing/date-range/csv',
      query
    }
    return url;
  }

  @Mutation
  setLastSearchOptions(searchOptions: StatementSearchOptions) {
    this.lastSearchOptions = searchOptions;
  }

  @Mutation
  setLoadingStatementDetails(bool: boolean) {
    this.loadingStatementDetails = bool;
  }

  @Mutation
  setStatementDetailsResult(result: StatementDetailsResult) {
    const { invoiceDetail } = result;
    this.statementDetails = invoiceDetail;
  }

  @Mutation
  setLoadingStatements(bool: boolean) {
    this.loadingStatements = bool;
  }

  @Mutation
  setStatementSearchResult(searchResult: StatementSearchResult) {
    const { value, total } = searchResult;
    this.statementArray = value as Invoice[];
    this.statementCount = total as number;
  }

  @Mutation
  @LogMethod
  reset() {
    this.statementArray = DefaultStatementArray;
    this.statementCount = DefaultStatementCount;
    this.statementDetails = DefaultStatementDetails;
    this.loadingStatements = DefaultLoadingStatements;
    this.loadingStatementDetails = DefaultLoadingStatementDetails;
    this.lastSearchOptions = DefaultSearchOptions;
  }
}
