
















































import InsightsIcon from '@/assets/images/icons/sidebar/InsightsIconLarge.svg?inline';
import OrdersIcon from '@/assets/images/icons/sidebar/OrdersIconLarge.svg?inline';
import PaymentsIcon from '@/assets/images/icons/sidebar/PaymentsIconLarge.svg?inline';
import RightIcon from '@/assets/images/icons/rightIcon.svg?inline';
import TransactionsIcon from '@/assets/images/icons/sidebar/TransactionsIconLarge.svg?inline';
import InvoicesIcon from '@/assets/images/icons/sidebar/InvoicesIconLarge.svg?inline';
import DisputesIcon from '@/assets/images/icons/sidebar/DisputesIconLarge.svg?inline';
import {
  statementsPageName,
  ordersPageName,
  paymentsPageName,
  insightsPageName,
  transactionsPageName,
  disputesPageName
} from '@/router';
import { authenticationStore, featureStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import ActionButton from '../controls/ActionButton.vue';
import DashboardView from '../layouts/DashboardView.vue';

@Component({
  components: {
    'dashboard-view': DashboardView,
    'action-button': ActionButton,
    'orders-icon': OrdersIcon,
    'payments-icon': PaymentsIcon,
    'insights-icon': InsightsIcon,
    'right-icon': RightIcon,
    'transactions-icon': TransactionsIcon,
    'invoices-icon': InvoicesIcon,
    'disputes-icon': DisputesIcon,
  }
})
export default class Dashboard extends Vue {
  get userName(): string {
    return authenticationStore.userName;
  }
  get canAccessInsights() {
    return featureStore.shouldAllowInsightPagesAccess;
  }
  get canAccessDisputes() {
    return featureStore.shouldAllowDisputePagesAccess;
  }
  get canAccessStatements() {
    return featureStore.shouldAllowStatementPagesAccess;
  }
  get canAccessTransactions() {
    return featureStore.shouldAllowTransactionPagesAccess;
  }
  navigateOrders() {
    this.$router.push({ name: ordersPageName });
  }
  navigatePayments() {
    this.$router.push({ name: paymentsPageName });
  }
  navigateTransactions() {
    this.$router.push({ name: transactionsPageName });
  }
  navigateStatements() {
    this.$router.push({ name: statementsPageName });
  }
  navigateInsights() {
    this.$router.push({ name: insightsPageName });
  }
  navigateDisputes() {
    this.$router.push({ name: disputesPageName });
  }
}
