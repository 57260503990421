





















import HeaderNotification from '@/components/common/HeaderNotification.vue';
import { contentStore, merchantStore } from '@/store/store';
import { Notification } from '@/store/content/content-models';
import { Component, Vue } from 'vue-property-decorator';

import { dashboardPageName, mssIntegrationPageName } from '@/router'

@Component({
  components: {
    'header-notification': HeaderNotification
  }
})
export default class HeaderNotificationLayout extends Vue {
  get hasNotifications(): boolean {
    return !!contentStore.topBarContentNotifications && contentStore.topBarContentNotifications.length > 0;
  }

  get contentNotifications(): Notification[] {
    return contentStore.topBarContentNotifications;
  }

  get showMerchantDisabledNotification(): boolean {
    if(merchantStore.isPassedManualReview) {
      return false;
    }

    if(this.$route.name === dashboardPageName || this.$route.name === mssIntegrationPageName) {
      return true;
    }

    return false;
  }
}
