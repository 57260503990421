import { OrderStatusEnum } from '@/store/order/order-models';

export interface TransactionSearchOptions {
  text: string;
  page: number;
  pageSize: number;
  orderBy?: 'dateAsc' | 'dateDesc';
  orderStatuses?: OrderStatusEnum[] | any[];
  isShopify?: boolean;
  minimumCreateDateTime: Date | string;
  maximumCreateDateTime: Date | string;
  csvVersion?: CsvVersion;
}

export interface TransactionSearchResult {
  value: Transaction[];
  total: number;
}

export interface Transaction {
  id: string;
  rowId:number;
  amount: number;
  grossAmount?: number;
  vatAmount?: number;
  vatPercent?: number;
  createdDateTime: Date;
  orderNumber: string;
  orderStatus: OrderStatusEnum;
  merchantId: string;
  reference: string;
  currency: string;
  paymentDate: Date;
  transactionType: TransactionTypeEnum;
  merchantFeeType: MerchantFeeTypeEnum;
  merchantTransactionTypeDisplay: string;
}

export interface TransactionTypeEnum {
  Purchase: 1;
  Payment: 2;
  Refund: 3;
  CashRefund: 4;
  DefaultFee: 5;
  ReverseDefaultFee: 6;
  ServiceFee: 7;
  ServiceFeeCredit: 8;
  InterestFee: 9;
  InterestFeeCredit: 10;
  EstablishmentFee: 11;
  EstablishmentFeeCredit: 12;
  MerchantFundsCapture: 13;
  MerchantFees: 14;
  MerchantReturns: 15;
  MerchantReturnFeeRebate: 16;
  MerchantReturnFee: 17;}

export enum MerchantFeeTypeEnum {
  None,
  MerchantFee,
  TransactionFee
}

export enum CsvVersion {
  V1,
  V2
}

export interface TransactionReportInfo {
  total: number;
  pageLimit: number;
}