






import { Component, Vue } from 'vue-property-decorator';

/**
 * Routing page for Disputes
 */
@Component
export default class DisputesMainPage extends Vue {}
