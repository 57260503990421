











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { }
})
export default class DashboardView extends Vue {
  @Prop({ required: false })
  mode: 'normal' | 'thick' | undefined;
}
