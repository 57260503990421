import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import configuration from '@/configuration';
import LogMethod from '@/decorators/logger-decorator';
import eventBus from '@/event-bus';
import jwtDecode from 'jwt-decode';
import logger from '@/logger';
import moment from 'moment-timezone';
import router, {
  checkEmailPageName,
  mssBusinessInfoPageName,
  mssConnectToStripePageName,
  mssIntegrationPageName,
  sessionExpiredPageName,
  mssAfterStripePageName,
  mssSfdcSignUpPageName
} from '@/router';
import { IdentityByTerritory, UserInfo } from '@/store/authentication/authentication-models';
import { CreateOnboardingMerchantCommand, MerchantIdentity, MssStage } from '@/store/merchant/merchant-models';
import store, {
  applicationStore,
  featureStore,
  merchantStore,
  placeStore,
} from '@/store/store';
import { AuthorizationError } from '@/store/store-models';
import {
  config,
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { Territory } from '../application/application-models';

// Set rawError for all Actions in module to true
config.rawError = true;

// The claim name in the JWT of the MerchantId
const MerchantIdClaimName = 'http://schemas.quadpay.com/ws/2017/claims/merchantid';
// The claim name in the refrsh/access JWT of email_verified
const EmailVerifiedClaimName = 'http://schemas.quadpay.com/ws/2017/claims/emailVerified';
// The claim name of the territories with their associated MerchantIds
const TerritoriesClaimName = 'http://schemas.quadpay.com/ws/2017/claims/territories'
// The claim name in the JWT of the roles
const RolesClaimName = 'https://quadpay.com/roles'

const DefaultAuthenticatedState = false;
const DefaultAccessTokenState = '';
const DefaultDestinationPath = '/dashboard';
const DefaultMerchantId = '';
const DefaultIdentitiesByTerritory = [];
const DefaultIdentity = {
  territory: Territory.US,
  merchant_id: '',
};
const DefaultRoles = [];
const DefaultUserInfo: UserInfo = {};

const DefaultShowExpModal = false;
const DefaultExpirationUnix = 0;
const DefaultTimerId = 0;
const DefaultLastLoginUnix = 0;
// Milliseconds before expiration that will trigger session expiring modal (set to 5 minutes)
const WarningBeforeExpiration = 5 * 60 * 1000;

const loginRedirect = `${window.location.origin}${process.env.BASE_URL}callback`;

const merchantApiUrl = `${configuration.links.apiDomain}merchants/`;

/**
 * The auth store is responsible for managing application authentication
 * flows and business logic.
 */
@Module({
  name: 'authentication',
  namespaced: true,
  store,
})
export default class AuthenticationStore extends VuexModule {
  authenticated: boolean = DefaultAuthenticatedState;
  accessToken: string = DefaultAccessTokenState;
  authClient?: Auth0Client;
  merchantId: string = DefaultMerchantId;
  identitiesByTerritory: IdentityByTerritory[] = DefaultIdentitiesByTerritory;
  currentIdentity: IdentityByTerritory = DefaultIdentity;
  roles: string[] = DefaultRoles;
  userInfo: UserInfo = DefaultUserInfo;
  // Expiration Unix (timestamp in milliseconds)
  expirationUnix: number = DefaultExpirationUnix;
  expirationTimerId: number = DefaultTimerId;
  showExpModal: boolean = DefaultShowExpModal;
  warningTimerId: number = DefaultTimerId;
  lastLoginUnix?: number = DefaultLastLoginUnix;
  loginUnix?: number;
  
  get userName(): string {
    return this.userInfo?.name || this.userInfo?.email || '';
  }

  get hasMultipleMerchantsForTerritory(): boolean {
    const identities = this.currentIdentity?.merchant_id || [];
    return identities.length > 1;
  }

  get hasMultipleIdentities(): boolean {
    return this.identitiesByTerritory.length > 1;
  }

  // For backwards compatibility, territory will always be "US" for old format
  get currentTerritory(): string {
    return this.currentIdentity.territory;
  }

  get isExpired(): boolean {
    return moment().isAfter(moment(this.expirationUnix)) && configuration.featureFlags.sessionExpiration;
  }

  get expirationDateTime(): Date {
    return moment(this.expirationUnix).toDate();
  }

  get lastLogin(): Date {
    return moment(this.lastLoginUnix).toDate();
  }

  @Action
  @LogMethod
  clearTimers() {
    logger.info('Clearing warning & expiration timers');
    window.clearTimeout(this.expirationTimerId);
    window.clearTimeout(this.warningTimerId);
  }

  /**
   * Initialize the Auth0 Client with the appropriate config
   */
  @Action({ rawError: false })
  private async initialize() {
    if (!this.authClient) {
      const authClient = await createAuth0Client({
        domain: configuration.auth.domain,
        client_id: configuration.auth.clientId,
        audience: configuration.auth.audience,
        // Use rotating refresh tokens https://auth0.com/docs/libraries/auth0-spa-js#use-rotating-refresh-tokens
        useRefreshTokens: true,
      });
      this.setAuthClient(authClient);
    }
  }

  private get authenticationClient(): Auth0Client {
    if (!this.authClient) {
      throw new Error('Authentication client is not available');
    }

    return this.authClient;
  }

  /**
   * Log user in and redirect to AuthCallbackPage
   */
  @Action({ rawError: false })
  @LogMethod
  async login({ isSignUp, setPlatform }: { isSignUp: boolean, setPlatform: boolean }) {
    this.setLoginTime();

    await this.initialize();
    let path: any = window.location.pathname;
    if (path === '' || path === '/') path = DefaultDestinationPath;
    this.authenticationClient.loginWithRedirect({
      redirect_uri: loginRedirect,
      appState: { destinationPath: path, setPlatform },
      isSignUp,
    });
  }

  /**
   * Handle Authentication Callback (grab access and id tokens, mark authentication, and store user info)
   */
  @Action
  @LogMethod
  async handleAuthenticationCallback() {
    await this.initialize();
    logger.debug('Handling callback');
    const { appState } = await this.authenticationClient.handleRedirectCallback();

    // Retrieve idToken first for claims verification
    const idToken: any = await this.authenticationClient.getIdTokenClaims();
    
    // Retrieve access token separately to have the correct audience populated
    const accessToken = await this.authenticationClient.getTokenSilently({
      audience: configuration.auth.audience,
      scope: 'openid email profile'
    });
    
    if (!idToken || !accessToken) {
      this.clearAuthenticated();
      throw new AuthorizationError('Unauthorized error handling authentication, either no idToken or accessToken')
    }

    // Once authentication is confirmed, set all auth properties and mark authenticated
    this.setAccessToken(accessToken);
    await this.setMerchantIdFromToken({ token: idToken });

    try {
      await merchantStore.getOnboardingMerchant();
    }
    catch {
      logger.warn("onboarding merchant not available for {merchantId}", this.merchantId);
      // Don't die if onboarding info isn't available
    }
    this.setAuthentication({ token: idToken, isIdToken: true });
    this.setExp(idToken.exp * 1000);

    // Redirect user according to id token properties
    await this.handleAuthenticationRedirect({
      appStatePath: appState.destinationPath,
      isFromCallback: true,
      setPlatform: appState.setPlatform,
    });
  }
  /**
   * Handle Authentication Redirect (evaluate given user info to determine what type of merchant has logged in redirect accordingly)
   * 
   * Valid Possibilities (4): new merchant, onboarding merchant (email not verified), onboarding merchant (email verified), existing merchant
   * @param appStatePath path to redirect to given path is not mss and user is an existing merchant
   * @param isFromCallback whether or not we are coming directly from the auth callback (rather than just the router)
   */
  @Action
  @LogMethod
  async handleAuthenticationRedirect({ appStatePath = '', isFromCallback = false, setPlatform = false }: { appStatePath?: string, isFromCallback?: boolean, setPlatform?: boolean }) {
    // Check Onboarding Merchants Collection
    const onboardingMerchant = await merchantStore.getOnboardingMerchant();
    const isOnboardingMerchant = onboardingMerchant != null;
    
    // Only onboarding merchants will have unverified emails
    if (isOnboardingMerchant && onboardingMerchant?.stage !== MssStage.integrationCompleted) {
      return await this.handleOnboardingMerchant(isFromCallback);
    }

    // Check Merchants Table
    const isExistingMerchant = await merchantStore.getMerchant();
    await merchantStore.getMerchantIdentities();

    if (isExistingMerchant) {
      if (isFromCallback) eventBus.publishExistingUserEvent(true);
      return await this.handleExistingMerchant(appStatePath);
    }

    return await this.handleNewMerchant({ isFromCallback, setPlatform });
  }

  /**
   * Handle redirect for new merchant (just starting MSS)
   */
  @Action
  async handleNewMerchant({ isFromCallback = false, setPlatform }: { isFromCallback?: boolean, setPlatform: boolean }) {
    const { email, sub } = this.userInfo;
    if (!email || !sub) {
      await this.clearAuthenticated();
      throw new Error('Cannot create onboarding merchant without credentials');
    }
    // If logging in publish user event
    if (isFromCallback) eventBus.publishExistingUserEvent(false);

    try {
      const body: CreateOnboardingMerchantCommand = { email, auth0UserId: sub, setPlatform };
      await merchantStore.createOnboardingMerchant(body);

      router.replace({ name: checkEmailPageName });
    } catch(e) {
      logger.error(`Auth error creating onboarding merchant: ${e.message}`);
      throw e;
    }
  }

  /**
   * Handle redirect for onboarding/MSS merchant
   * @param emailVerified 
   */
  @Action
  async handleOnboardingMerchant(isFromCallback: boolean) {
    logger.info('Authenticated user: Onboarding Merchant', this.userInfo);
    if (isFromCallback) eventBus.publishExistingUserEvent(true);
    // If email not verified, user must check email
    const { email_verified } = this.userInfo;
    if (!email_verified) return router.replace({ name: checkEmailPageName });

    // Else must redirect according to stage
    const stage = merchantStore.onboardingMerchant?.stage;

    // We don't have a true identifier to say what the source of sign-up is, so we try to catch the state of the
    // merchant's info to detect if they came through the SFDC form.  This will happen if they are missing only certain
    // bits of data.
    if (stage === MssStage.businessInfoAdded
      && (!merchantStore.onboardingMerchant.businessInfo?.businessAddress?.line1 || merchantStore.onboardingMerchant.businessInfo?.businessAddress?.line1 === 'null')
      && !merchantStore.onboardingMerchant.businessInfo?.ein
      && merchantStore.onboardingMerchant.firstName
      && merchantStore.onboardingMerchant.businessInfo?.businessName
      && merchantStore.onboardingMerchant.businessInfo?.website
      && merchantStore.onboardingMerchant.businessInfo?.platform) {
      return await router.replace({ name: mssSfdcSignUpPageName })
    }

    switch(stage) {
      case MssStage.inquiryReceived:
        return router.replace({ name: checkEmailPageName });
      case MssStage.emailVerified:
        return router.replace({ name: mssBusinessInfoPageName });
      case MssStage.businessInfoAdded:
        // Enforce Redirect to Merchant Info page
        return router.replace({ name: mssSfdcSignUpPageName });
      case MssStage.msaSigned:
        return router.replace({ name: mssConnectToStripePageName });
      case MssStage.stripeAccountCreated:
        return router.replace({ name: mssAfterStripePageName });
      case MssStage.stripeAccountCompleted:
        return router.replace({ name: mssIntegrationPageName });
      // case integrationCompleted handled in handleAuthenticationRedirect
      default:
        throw new Error(`Undefined MSS stage routing: ${stage}`);
    }
  }

  /**
   * Handle redirect for existing merchant
   * @param appStatePath path user was originally trying to reach
   */
  @Action
  async handleExistingMerchant(appStatePath: string) {
    logger.info('Authenticated user: Existing Merchant', this.userInfo);
    // Do not allow existing merchants to enter MSS flow
    const path = /mss/.test(appStatePath) ? DefaultDestinationPath : appStatePath;
    return router.replace(path);
  }
  
  /**
   * Refresh the login credentials of the currently logged in user
   * Gets a new access token
   */
  @Action
  async refreshToken(): Promise<{ email_verified: boolean }> {
    await this.initialize();
    try {
      const accessToken = await this.authenticationClient.getTokenSilently({
        audience: configuration.auth.audience,
        scope: 'openid email profile'
      });
      this.setAccessToken(accessToken);
      
      const decodedAccessToken = jwtDecode(accessToken);
      logger.debug({ decodedAccessToken })

      // Update email_verified & merchantId
      await this.setMerchantIdFromToken({ token: decodedAccessToken });
      this.setAuthentication({ token: decodedAccessToken, isIdToken: false });

      // Reset exp date time and timers
      this.setExp((decodedAccessToken as any).exp * 1000);

      const email_verified = !!this.userInfo.email_verified;
      return { email_verified };
    } catch(e) {
      throw new Error(`Error refreshing auth token: ${e}`);
    }
  }

  /**
   * Resend verification email
   */
  @Action
  async resendVerificationEmail(): Promise<boolean> {
    const url = `${merchantApiUrl}onboarding-users/resend-verification-email`;
    const { email } = this.userInfo;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        authorization: `Bearer ${this.accessToken}`,
        "content-type": "application/json-patch+json",
      },
      body: JSON.stringify({ email }),
    });
    eventBus.publishVerificationEmailResentEvent();
    if (response.status > 226) throw new Error(JSON.stringify(response));
    else return true;
  }

  /**
   * Logout
   * @param redirectToLogin
   */
  @Action({ rawError: false })
  @LogMethod
  async logout(redirectToLogin?: boolean) {
    await applicationStore.resetAllStores();
    
    await this.initialize();
    const redirect = redirectToLogin ? `${window.location.origin}${process.env.BASE_URL}` : `${window.location.origin}${process.env.BASE_URL}logout`;
    await this.authenticationClient.logout({
      returnTo: redirect,
      client_id: configuration.auth.clientId,
    });
  }

  /**
   * Set Expiration and timers
   * @exp number of milliseconds since epoch (as deﬁned by POSIX6) - the exact moment from which this JWT is considered invalid
   */
  @Action
  @LogMethod
  setExp(exp: number = this.expirationUnix) {
    // Clear existing timers
    this.clearTimers();

    // Set expiration unix (milliseconds)
    this.setExpirationUnix(exp);
    logger.info(`Session expires ${moment(this.expirationUnix).toDate()}`);

    // Reset timers
    const millisecondsToExpiration = moment.duration(moment(this.expirationUnix).diff(moment())).asMilliseconds();
    const millisecondsToWarning = millisecondsToExpiration - WarningBeforeExpiration;
    
    // Set Warning Timer (millisecondsToWarning);
    const warningTimerId = window.setTimeout(() => {
      logger.warn(`Session expiring in ${WarningBeforeExpiration/60000} minutes`);
      this.toggleShowExpModal(true);
    }, millisecondsToWarning);

    // Set Expiration Timer (millisecondsToExpiration);
    const expirationTimerId = window.setTimeout(() => {
      logger.warn('Session expired');
      applicationStore.resetAllStores();
      this.toggleShowExpModal(false);
      router.push({ name: sessionExpiredPageName });
    }, millisecondsToExpiration);

    this.setTimerIds({ warningTimerId, expirationTimerId });
  }
  
  @Mutation
  private setLoginTime() {
    this.lastLoginUnix = this.loginUnix || DefaultLastLoginUnix;
    this.loginUnix = moment().unix();
  }

  @Mutation
  private setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
  }

  @Mutation
  private setAuthClient(authClient?: Auth0Client) {
    this.authClient = authClient;
  }

  /**
   * Set merchantId based on token
   * @param token JSON object (idToken or accessToken/refresh token)
   */
  @Mutation
  private async setMerchantIdFromToken(auth: {token: any }) {
    const { token } = auth;

    // Set merchantId
    const territories = token[TerritoriesClaimName];
    // If the user has identities by territory metadata, use the first identity
    if (territories) {
      // Set identity/territory
      this.identitiesByTerritory = territories;
      this.currentIdentity = territories[0];
      applicationStore.switchTerritory(this.currentIdentity);
      this.merchantId = this.currentIdentity.merchant_id;
    } else {
      // If using old format (without territories), simply grab merchantId
      const merchantId = token[MerchantIdClaimName];
      this.merchantId = merchantId;
    }

    const qpRoles = token[RolesClaimName] || [];
    if (Array.isArray(qpRoles) && qpRoles.length) {
      this.roles = qpRoles as string[];
    }

    await featureStore.initialize();
    await placeStore.refreshCountries({ language: applicationStore.currentLocale.language, currentCountry: applicationStore.currentCountry.code });
  }

  /**
   * Set user properties && expiration based and mark as authenticated based on token
   * @param token JSON object (idToken or accessToken/refresh token)
   * @param isIdToken true: auth callback, false: refresh
   */
  @Mutation
  private setAuthentication(auth: { token: any, isIdToken: boolean }) {
    const { token, isIdToken } = auth;
    
    if (isIdToken) {
      logger.debug("ID Token", token)
      // Set userInfo (all properties)
      const { name, email, email_verified, sub } = token;
      this.userInfo = {
        name,
        email,
        email_verified,
        sub,
      };
      
      // Mark as authenticated
      this.authenticated = true;
      // Track authenticated event from login only (not from refresh)
      eventBus.publishAuthenticatedEvent(sub, this.merchantId);
    } else {
      logger.debug("Refresh Token", token)
      // Edit userInfo (email_verified property only)
      const email_verified = token[EmailVerifiedClaimName];
      this.userInfo.email_verified = email_verified;
    }
  }

  @Mutation
  async setIdentity(identity: IdentityByTerritory) {
    this.currentIdentity = identity;

    if(!identity.merchant_ids || identity.merchant_ids.indexOf(this.merchantId) == -1) {
      // Current merchant ID is not in this territory, so use the default.
      this.merchantId = identity.merchant_id;
    }

    await featureStore.initialize();
    await placeStore.refreshCountries({ language: applicationStore.currentLocale.language, currentCountry: applicationStore.currentCountry.code });
  }

  @Mutation
  async setMerchant(merchantIdentity: MerchantIdentity) {
    this.merchantId = merchantIdentity.merchantId;
    await featureStore.initialize();
    await placeStore.refreshCountries({ language: applicationStore.currentLocale.language, currentCountry: applicationStore.currentCountry.code });
  }

  @Mutation
  private setExpirationUnix(expUnixMs: number) {
    this.expirationUnix = expUnixMs;
  }
  
  @Mutation
  private setTimerIds(timerIds: { warningTimerId: number, expirationTimerId: number }) {
    this.warningTimerId = timerIds.warningTimerId;
    this.expirationTimerId = timerIds.expirationTimerId;
  }

  @Mutation
  toggleShowExpModal(bool: boolean) {
    this.showExpModal = bool;
  }

  /**
   * Clear Auth && Reset the Auth Store
   */
  @Mutation
  @LogMethod
  clearAuthenticated() {
    // Clear timers
    window.clearTimeout(this.expirationTimerId);
    window.clearTimeout(this.warningTimerId);
    // Reset defaults
    this.accessToken = DefaultAccessTokenState;
    this.authenticated = DefaultAuthenticatedState;
    this.expirationUnix = DefaultExpirationUnix;
    this.expirationTimerId = DefaultTimerId;
    this.merchantId = DefaultMerchantId;
    this.identitiesByTerritory = DefaultIdentitiesByTerritory;
    this.roles = DefaultRoles;
    this.userInfo = DefaultUserInfo;
    this.warningTimerId = DefaultTimerId;

    this.authClient = undefined;
    this.currentIdentity = DefaultIdentity;
    this.showExpModal = DefaultShowExpModal;
  }
}