import { Document as RichText } from '@contentful/rich-text-types'
import {
  MerchantIndustry,
  MerchantPlatform,
  MerchantTier,
} from '../merchant/merchant-models';

type Region = 'US' | 'UK' | 'CA' | 'MX' | 'SG';
type Route = '*' | 'dashboard' | 'orders' | 'transactions' | 'payments' | 'disputes';
type Language = 'en' | 'es' | 'fr';

type NotificationLocation = "Top bar" | "Side bar";

export interface TargetSearchOptions {
  region: Region | string;
  language: string;
  merchantId: string;
  lastLogin: Date;
  tier?: MerchantTier;
  platform?: MerchantPlatform;
  industry?: MerchantIndustry;
}

export interface IsFilterable {
  region: Region;
  lastLogin?: Date;

  merchantTargetGroup?: {
    sys: {
      id: string;
    }
  }

  excludeMerchantTargetGroup?: {
    sys: {
      id: string;
    }
  }
}

export interface ContentfulNotification extends IsFilterable {
  routeToDisplay: Route;
  location: NotificationLocation;

  primaryLanguage: Language;
  primaryLanguageTitle: string;
  primaryLanguageBody: RichText;

  secondaryLanguage?: Language;
  secondaryLanguageTitle: string;
  secondaryLanguageBody: RichText;

  trackingEventName: string;
}

export interface Notification extends ContentfulNotification {
  title: string;
  body: string;
}

export interface WhatsNewItem extends IsFilterable {
  
  primaryLanguage: Language;
  primaryLanguageTitle: string;
  primaryLanguageBody: RichText;

  secondaryLanguage?: Language;
  secondaryLanguageTitle: string;
  secondaryLanguageBody: RichText;

  trackingEventName: string;
}

export const MerchantTargetGroupContentTypeId = "merchantTargetGroup";

export interface MerchantTargetGroup {
  id: string;
  merchantIds: string[];
  merchantTier?: MerchantTier;
  platform?: MerchantPlatform;
  industry?: MerchantIndustry;
}