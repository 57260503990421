













import DisputeCsvDialog from '@/components/disputes/DisputeCsvDialog.vue';
import Disputes from '@/components/disputes/Disputes.vue';
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import { DisputeSearchOptions } from '@/store/dispute/dispute-models';
import { disputeStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'basic-layout': BasicLayout,
    'disputes': Disputes,
    'dispute-csv-dialog': DisputeCsvDialog
  }
})
export default class DisputesPage extends Vue
{
  get lastSearchOptions(): DisputeSearchOptions {
    return disputeStore.lastSearchOptions;
  }
}
