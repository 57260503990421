












import ConfirmationBox from '@/components/common/ConfirmationBox.vue';
import DisputeDetails from '@/components/disputes/DisputeDetails.vue';
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import SimpleView from '@/components/layouts/SimpleView.vue';
import { disputeStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'basic-layout': BasicLayout,
    'dispute-details': DisputeDetails,
    'simple-view': SimpleView,
    'confirmation-box': ConfirmationBox
  }
})
export default class DisputeDetailsPage extends Vue {
  get loading(): boolean {
    return disputeStore.loadingDisputeDetails;
  }

  navigateToDisputes() {
    history.back();
  }

  async created() {
    // Make sure data are loaded before displaying the page
    if (this.$route.params.disputeId && typeof this.$route.params.disputeId === "string") {
      await disputeStore.loadDisputeDetails(this.$route.params.disputeId);
    }
  }

  beforeRouteLeave(to, from, next) {
    // Relay the call to the child component to determine what to do
    if (this.$refs.details && !(this.$refs.details as DisputeDetails).onBeforeRouteLeave(to, from, next)) {
      return false;
    }
    else {
      next();
    }
  }
}
