




import { Component, Emit, Model, Prop, Vue, Watch } from 'vue-property-decorator';
/**
 * Component is used to center an image with a fixed width
 * in a flex container.
 */
@Component
export default class CheckBox extends Vue {
    @Model('change', { required: false, default: undefined })
    checked?: any;

    @Prop({ required: false, default: undefined })
    label?: any;

    @Prop({ required: false, default: false})
    disabled!: boolean;

    state?: any = false;

    @Emit('change')
    @Watch('state')
    private onStateChanged(newState: any) {
        return newState;
    }

    async mounted() {
        this.state = this.checked;
    }
}
