import en_GB from './en-GB.json'
import fr_CA from './fr-CA.json'
import en_US from './en-US.json'
import es_MX from './es-MX.json'
import en_SG from './en-SG.json'

export default {
    "fr-CA": fr_CA,
    "en-GB": en_GB,
    "en-US": en_US,
    "es-MX": es_MX,
    "en-SG": en_SG
}