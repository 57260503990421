




import { DisputeReasonEnum } from '@/store/dispute/dispute-models';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class DisputeReason extends Vue {
  @Prop({ required: true })
  reason: DisputeReasonEnum;

  get displayText() {
    switch (this.reason) {
      case DisputeReasonEnum.bankCannotProcess:
        return this.$t('disputeReason.reason-bank-cannot-process');
      case DisputeReasonEnum.checkReturned:
        return this.$t('disputeReason.reason-check-returned');
      case DisputeReasonEnum.creditNotProcessed:
        return this.$t('disputeReason.reason-credit-not-processed');
      case DisputeReasonEnum.customerInitiated:
        return this.$t('disputeReason.reason-customer-initiated');
      case DisputeReasonEnum.debitNotAuthorized:
        return this.$t('disputeReason.reason-debit-not-authorized');
      case DisputeReasonEnum.general:
        return this.$t('disputeReason.reason-general');
      case DisputeReasonEnum.incorrectAccountDetails:
        return this.$t('disputeReason.reason-incorrect-account-details');
      case DisputeReasonEnum.insufficientFunds:
        return this.$t('disputeReason.reason-insufficient-funds');
      case DisputeReasonEnum.productNotReceived:
        return this.$t('disputeReason.reason-product-not-received');
      case DisputeReasonEnum.productUnacceptable:
        return this.$t('disputeReason.reason-product-unacceptable');
      case DisputeReasonEnum.subscriptionCanceled:
        return this.$t('disputeReason.reason-subscription-canceled');
      case DisputeReasonEnum.unrecognized:
        return this.$t('disputeReason.reason-unrecognized');
      default:
        return this.reason;
    }
  }
}
