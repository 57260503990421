











import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * Component is used to center an image with a fixed width
 * in a flex container.
 */
@Component
export default class CenteredImage extends Vue {
  @Prop({ required: true })
  imagePath?: string;

  @Prop({ required: false, default: undefined })
  image2xPath?: string;

  @Prop({ required: false, default: undefined })
  image3xPath?: string;

  @Prop({ required: true, default: 0 })
  imageWidth!: number;

  @Prop({ required: false, default: undefined })
  alt?: string;

  imageSrcSet = `${(this as any).imagePath} ${this.imageWidth}w`;

  /**
   * Update the image srcset if higher-res images are available
   */
  created() {
    if (this.image2xPath) {
      this.imageSrcSet = `${this.image2xPath} ${2 * this.imageWidth}w, ${this.imageSrcSet}`;
    }

    if (this.image3xPath) {
      this.imageSrcSet = `${this.image3xPath} ${3 * this.imageWidth}w, ${this.imageSrcSet}`;
    }
  }
}
