import configuration from '@/configuration';
import application from '@/store/application/application-store';
import authentication from '@/store/authentication/authentication-store';
import merchant from '@/store/merchant/merchant-store';
import order from '@/store/order/order-store';
import payment from '@/store/payment/payment-store';
import insights from '@/store/insights/insights-store';
import stripe from '@/store/stripe/stripe-store';
import transaction from '@/store/transaction/transaction-store';
import dispute from '@/store/dispute/dispute-store';
import statement from '@/store/statement/statement-store';
import content from '@/store/content/content-store';
import feature from '@/store/feature/feature-store';
import place from '@/store/place/place-store';
import Vue from 'vue';
import Vuex from 'vuex';
import { getModule } from 'vuex-module-decorators';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

export const VuexPeristenceLocalStorageKey = 'vuex';

const strictMode = configuration.debug.vuexStrict;

// Vuex persistence saves the state to local storage
const vuexPersistence = new VuexPersistence({
  strictMode,
});

export const store = new Vuex.Store({
  strict: strictMode,
  mutations: {
    RESTORE_MUTATION: vuexPersistence.RESTORE_MUTATION,
  },
  modules: {
    // Module names must match name parameter value in Module decorator
    authentication,
    application,
    merchant,
    order,
    payment,
    insights,
    stripe,
    transaction,
    dispute,
    statement,
    content,
    feature,
    place,
  },
  plugins: [vuexPersistence.plugin],
});

export const applicationStore = getModule(application, store);
export const authenticationStore = getModule(authentication, store);
export const merchantStore = getModule(merchant, store);
export const orderStore = getModule(order, store);
export const paymentStore = getModule(payment, store);
export const insightsStore = getModule(insights, store);
export const stripeStore = getModule(stripe, store);
export const transactionStore = getModule(transaction, store);
export const disputeStore = getModule(dispute, store);
export const statementStore = getModule(statement, store);
export const contentStore = getModule(content, store);
export const featureStore = getModule(feature, store);
export const placeStore = getModule(place, store);
export default store;
