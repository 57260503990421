






































import configuration from '@/configuration';
import { statementDetailsPageName } from '@/router';
import { InvoiceTransactionsByOrderResponse } from '@/store/statement/statement-models';
import { applicationStore, featureStore, statementStore } from '@/store/store';
import moment from 'moment';

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TransactionTable extends Vue {
  @Prop({ required: true })
  orderId: string;

  statementTransactions: InvoiceTransactionsByOrderResponse = { rows: [] };
  
  get showTax() {
    return featureStore.shouldDisplayTaxInfo;
  }

  get footerColSpan() {
    return this.showTax ? 6 : 4;
  }

  displayDate(date: Date): string {
    if (date) {
      return moment(date).utc().locale(applicationStore.currentLocale.locale).format(configuration.dateFormat);
    }
    return '';
  }

  navigateToInvoice(statementId?: string) {
    this.$router.push({ name: statementDetailsPageName, params: { statementId: statementId ?? 'unknown' } });
  }

  async mounted() {
    this.statementTransactions = await statementStore.getStatementTransactionsForOrder(this.orderId);
  }
}

