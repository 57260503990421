











import { ComparableAggregatedResult } from '@/store/insights/insights-models';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AggregatedResultChart from './AggregatedResultChart.vue';

@Component({
  components: {
    'aggregated-result-chart': AggregatedResultChart
  }
})
export default class OrderValueChart extends Vue {
  @Prop({ required: true })
  data: ComparableAggregatedResult;

  @Prop({ required: false })
  loading: boolean;

  getValue(entry: any): number {
    return entry?.totalValue ?? 0;
  }

  formatValue(val: number) : string {
    return this.$n(val, 'currency');
  }
}
