

















import moment from 'moment-timezone';
import { Component, Prop, Vue } from 'vue-property-decorator';
import configuration from '@/configuration';
import { applicationStore, merchantStore } from '@/store/store';
import StatementSummary from './StatementSummary.vue';
import DownloadCsvDialog from '../common/DownloadCsvDialog.vue';
import { Invoice, InvoiceDetail } from '@/store/statement/statement-models';
import statementStore from '@/store/statement/statement-store';

@Component({
  components: {
    'statement-summary': StatementSummary,
    'download-csv-dialog': DownloadCsvDialog
  }
})
export default class StatementCsvDialog extends Vue {
  @Prop({ required: false })
  startDate: string;
  @Prop({ required: false })
  endDate: string;
  @Prop({ required: false })
  invoiceDetail: InvoiceDetail | Invoice;
  @Prop({ required: false })
  mode: undefined | 'normal' | 'compact' | 'embedded';

  csvV3Epoch = '2022-03-01';

  get companyName(): string {
    return configuration.company.name;
  }

  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  get dataType(): string {
    return 'statement transactions as CSV';
  }

  get invoiceId() {
    const invoice: any = this.invoiceDetail;
    return invoice.id ?? invoice.invoiceId;
  }

  get filename() {
    const dateFormat = 'YYYY-MM-DD';
    let prefix = `${merchantStore.merchant.legalName} ${this.$t('statementCsvDialog.csv-export')} `;
    if (this.invoiceDetail) {
      return `${prefix} ${this.invoiceDetail.invoiceNumber ?? this.invoiceId}.csv`;
    }
    return `${prefix} ${moment.utc(this.startDate).format(dateFormat).toString()} to ${moment.utc(this.endDate).format(dateFormat).toString()}.csv`
  }

  getCsvDownloadUrl(query: any) {
    return this.invoiceDetail ? statementStore.getStatementTransactionsCsvUrl(query, this.invoiceId) : statementStore.getStatementTransactionsCsvByDateRangeUrl(query);
  }
}
