



































import { toLocalDate } from '@/datetime';
import { DisputeDetail, DisputeReconciledStatusEnum } from '@/store/dispute/dispute-models';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import DisputeIssuerEvidence from '../components/DisputeIssuerEvidence.vue';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';

@Component({
  components: {
    'dispute-details-section': DisputeDetailsSection,
    'dispute-issuer-evidence': DisputeIssuerEvidence
  },
})
export default class DisputeDetailsSummaryStepsSection extends Vue {
  @Prop({ required: true })
  dispute: DisputeDetail;

  get displaySteps() {
    switch (this.dispute.status) {
      case DisputeReconciledStatusEnum.needsResponse:
        return true;
    }
    return false;
  }

  get customerFollowUpEmailUrl() {
    const subject = encodeURIComponent(this.$t('disputeDetailsSummary.customer-email-subject', { orderDate: this.orderDate }).toString());
    const body = encodeURIComponent(this.$t('disputeDetailsSummary.customer-email-body', { customerName: this.customerName, disputeDate: this.disputeDate, amount: this.amount, orderDate: this.orderDate }).toString());
    return `mailto:${encodeURIComponent(this.dispute.customerEmail as string)}?body=${body}&subject=${subject}`
  }

  get customerName() {
    return `${this.dispute.customerFirstName} ${this.dispute.customerLastName}`;
  }

  get amount() {
    return this.$n(this.dispute.disputedAmount, 'currency');
  }

  get disputeDate() {
    return toLocalDate(this.dispute?.disputedOn);
  }

  get disputeDueDate() {
    return toLocalDate(this.dispute?.evidenceDueOn);
  }

  get orderDate() {
    return toLocalDate(this.dispute?.orderDate);
  }

  @Emit('acceptDispute')
  onAcceptDispute(evt) {
    return evt;
  }
}
