import moment from 'moment';
import configuration from './configuration';
import { applicationStore } from './store/store';

export function toLocalDate(date?: Date) {
  if (!date) {
    return '';
  }
  return moment(date).utcOffset(applicationStore.currentTimezone.utcOffset).locale(applicationStore.currentLocale.locale).format(configuration.dateFormat);
}

export function toLocalDateTime(date?: Date): string {
  if (!date) {
    return '';
  }
  return moment(date).utcOffset(applicationStore.currentTimezone.utcOffset).locale(applicationStore.currentLocale.locale).format(configuration.dateTimeFormat);
}