// Sidebar icons
import DashboardIcon from '@/assets/images/icons/sidebar/DashboardIcon.svg?inline';
import HamburgerMenuIcon from '@/assets/images/icons/sidebar/HamburgerMenuIcon.svg?inline';
import HelpIcon from '@/assets/images/icons/sidebar/HelpIcon.svg?inline';
import MenuExitIcon from '@/assets/images/icons/sidebar/MenuExitIcon.svg?inline';
import OrdersIcon from '@/assets/images/icons/sidebar/OrdersIcon.svg?inline';
import PaymentsIcon from '@/assets/images/icons/sidebar/PaymentsIcon.svg?inline';
import SettingsIcon from '@/assets/images/icons/sidebar/SettingsIcon.svg?inline';
import TransactionsIcon from '@/assets/images/icons/sidebar/TransactionsIcon.svg?inline';
import DisputesIcon from '@/assets/images/icons/sidebar/DisputesIcon.svg?inline';
import InvoicesIcon from '@/assets/images/icons/sidebar/InvoicesIcon.svg?inline';
import ProfileSelectIcon from '@/assets/images/icons/sidebar/ProfileSelectIcon.svg?inline';
import InsightsIcon from '@/assets/images/icons/sidebar/InsightsIcon.svg?inline';
// Zip icons
import DownloadIcon from '@/assets/images/icons/DownloadSimple.svg?inline';
import CheckboxChecked from '@/assets/images/icons/controls/checkbox-checked.svg?inline';
import CheckboxUnchecked from '@/assets/images/icons/controls/checkbox-unchecked.svg?inline';
import PanelCollapse from '@/assets/images/icons/controls/panel-collapse.svg?inline';
import PanelExpand from '@/assets/images/icons/controls/panel-expand.svg?inline';
import CloseCross from '@/assets/images/icons/controls/close-cross.svg?inline';
import Attachment from '@/assets/images/icons/controls/attachment.svg?inline';
import InfoIcon from '@/assets/images/icons/infoIcon.svg?inline';
import MediumCheckIcon from '@/assets/images/icons/checkIconMedium.svg?inline';
import MediumErrorIcon from '@/assets/images/icons/errorIconMedium.svg?inline';
import WarningIcon from '@/assets/images/icons/warningIcon.svg?inline';


import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#6542BE',
        secondary: '#AA8FFF',
        tertiary: '#DFE3E9',
        accent: '#EDE6FF',
        error: '#B00020',
        info: '#1A0826',
        success: '#1F7A39',
        warning: '#FFBF5C',
        background: '#FFFFFF', // --zip-control
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      dashboard: {
        component: DashboardIcon,
      },
      'hamburger-menu': {
        component: HamburgerMenuIcon,
      },
      help: {
        component: HelpIcon,
      },
      'menu-exit': {
        component: MenuExitIcon,
      },
      orders: {
        component: OrdersIcon,
      },
      payments: {
        component: PaymentsIcon,
      },
      settings: {
        component: SettingsIcon,
      },
      transactions: {
        component: TransactionsIcon,
      },
      disputes: {
        component: DisputesIcon
      },
      invoices: {
        component: InvoicesIcon,
      },
      insights: {
        component: InsightsIcon,
      },
      'profile-select': {
        component: ProfileSelectIcon,
      },
      download: {
        component: DownloadIcon,
      },
      'checkbox-checked': {
        component: CheckboxChecked,
      },
      'checkbox-unchecked': {
        component: CheckboxUnchecked,
      },
      'panel-collapse': {
        component: PanelCollapse,
      },
      'panel-expand': {
        component: PanelExpand,
      },
      'close-cross': {
        component: CloseCross,
      },
      'attachment': {
        component: Attachment,
      },
      'info-icon': {
        component: InfoIcon,
      },
      'warning-icon': {
        component: WarningIcon,
      },
      'medium-check-icon': {
        component: MediumCheckIcon,
      },
      'medium-error-icon': {
        component: MediumErrorIcon,
      }
    }
  }
});
