import configuration from '@/configuration';
import * as FullStory from '@fullstory/browser';
import AwaitInit from '@/decorators/event-handler-await-event-decorator';
import eventBus from '@/event-bus';
import logger from '@/logger';
import { authenticationStore } from '@/store/store';
import { isFeatureEnabled } from '@/services/feature-flags';

const startUpStatus = {
  initialized: false,
  enabled: true,
}

async function initialize() {
  // Check Optimizely for enabled status
  if (configuration.featureFlags.optimizelyEnabled) {
    startUpStatus.enabled = await isFeatureEnabled('full_story_mss');
  }
  if (startUpStatus.enabled) {
    FullStory.init({
      orgId: 'XYHRC',
      namespace: 'FS',
      debug: configuration.debug.fullstory,
      devMode: configuration.environmentName !== 'Production',
    });
  }

  // initialized regardless of enabled status
  startUpStatus.initialized = true;
  // Part 2: FS configured (and ready to publish events)
  logger.info('Full Story Part 2 successfully loaded!');
}

function handleEmailVerification(verified: boolean, merchantId: string, reason: string) {
  if (verified) {
    FullStory.event('MP User Email Verified Success', { merchantId });
  } else {
    FullStory.event('MP User Email Verified Failed', { merchantId, reason });
  }
}

function handleError(error: Error, handled: boolean, id?: string) {
  const type = (error as any).constructor.name ? (error as any).constructor.name : 'Error';
  FullStory.event('Error', {
    qpErrorId: id,
    errorDescription: error.toString(),
    authToken: authenticationStore.accessToken,
    handled,
    type,
  });
}

function handleExistingUser(exists: boolean) {
  if (exists) {
    FullStory.identify('Existing User Received', { email: authenticationStore.userInfo.email, merchantId: authenticationStore.merchantId });
  } else {
    FullStory.identify('New User Received', { email: authenticationStore.userInfo.email });
  }
}

function handleFormSubmission(formName: string, valid: boolean, inputs: any[], ...errorMessages: any[]) {
  if (valid) {
    FullStory.event('Form Submission', {
      success: true,
      formName,
      inputs,
    });
  } else {
    FullStory.event('Form Submission', {
      success: false,
      formName,
      inputs,
      errorMessages,
    });
  }
}

function handleIntegrationTestResults(merchantId: string, success: boolean, widgetVerified: boolean, integrationVerified: boolean) {
    FullStory.event('MSS Integration Test Results', {
    success,
    merchantId,
    widgetVerified,
    integrationVerified,
  });
}

function handleWebsiteUpdated() {
  FullStory.event('MSS Website Url Updated', {
    merchantId: authenticationStore.merchantId,
  });
}

function handleMssDeclined(declineReason: string) {
    FullStory.event('MSS Declined', {
    declineReason,
    merchantId: authenticationStore.merchantId,
    email: authenticationStore.userInfo.email,
  });
}

function handleMssStageCompleted(stage: string, attempts: number) {
  // Attempts for return from Stripe
  // if attempts = 0 there was no counting
  // else (attempts * 10) = number of seconds it took to complete
  FullStory.event('MSS Stage Completed',
    { merchantId: authenticationStore.merchantId, stage, attempts });
}

function handleTrackAction(actionName: string, actionInfo?: Record<string, any>) {
  if (actionInfo) FullStory.event(`Action ${actionName}`, { ...actionInfo });
  else FullStory.event(`Action ${actionName}`, {});
}
function handleTrackClick(itemClicked: string, itemInfo?: Record<string, any>) {
  if (itemInfo) {
    FullStory.event(`Click ${itemClicked}`, { ...itemInfo });
  }
  else FullStory.event(`Click ${itemClicked}`, {});
}

function handleVerificationEmailResent() {
  FullStory.event('Verification Email Resent', { merchantId: authenticationStore.merchantId });
}

export default {
  /**
   * Load fullstory and register all of the event consumers.
   */
  load() {
    // Prevent double loading
    if (startUpStatus.initialized){
      return;
    }

    initialize();

    eventBus.subscribeEmailVerificationEvent(AwaitInit('handleEmailVerification', startUpStatus, handleEmailVerification));
    eventBus.subscribeErrorEvent(AwaitInit('handleError', startUpStatus, handleError));
    eventBus.subscribeExistingUserEvent(AwaitInit('handleExistingUser', startUpStatus, handleExistingUser));
    eventBus.subscribeFormSubmissionEvent(AwaitInit('handleFormSubmission', startUpStatus, handleFormSubmission));
    eventBus.subscribeIntegrationTestResultsEvent(AwaitInit('handleIntegrationTestResults', startUpStatus, handleIntegrationTestResults));
    eventBus.subscribeWebsiteUpdatedEvent(AwaitInit('handleWebsiteUpdated', startUpStatus, handleWebsiteUpdated));
    eventBus.subscribeMssDeclinedEvent(AwaitInit('handleMssDeclined', startUpStatus, handleMssDeclined));
    eventBus.subscribeMssStageCompletedEvent(AwaitInit('handleMssStageCompleted', startUpStatus, handleMssStageCompleted));
    eventBus.subscribeTrackActionEvent(AwaitInit('handleTrackAction', startUpStatus, handleTrackAction));
    eventBus.subscribeTrackClickEvent(AwaitInit('handleTrackClick', startUpStatus, handleTrackClick));
    eventBus.subscribeVerificationEmailResentEvent(AwaitInit('handleVerificationEmailResent', startUpStatus, handleVerificationEmailResent));
    logger.info('Successfully loaded FullStory!');
  }
};
