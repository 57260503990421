







import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import LineChart from '../../controls/charts/LineChart.vue';
import ScalarChart from '../../controls/charts/ScalarChart.vue';
import { AggregatedCustomer, ComparableAggregatedResult, CustomerDailyMetricsTypeEnum, CustomerDataTypeEnum } from '@/store/insights/insights-models';
import InsightsStore from '@/store/insights/insights-store';

const MainDatasetColor = '#A17ECF';
const SecondaryDatasetColor = '#A17ECF66';

@Component({
  components: {
    'scalar-chart': ScalarChart,
    'line-chart': LineChart,
  }
})
export default class CustomerCountChart extends Vue {
  @Prop({ required: true })
  data: ComparableAggregatedResult;

  @Prop({ required: false })
  loading: boolean;

  returningRate = 0;
  datasets: Array<any> = [];
  labels: Array<string> = [];
  calculating = false;

  options = {
    responsive: false,
     plugins: {
      tooltip: {
        mode: 'index'
      }
    },
    scales: {
      y: {
        stacked: true, // so its easier to see total customers with out mentally adding the numbers
        beginAtZero:true
      },
      x:{
        beginAtZero:true
      },
    },
  }

  formatPercentage(value: number): string {
    return `${isNaN(value) ? 0 : value.toFixed(2)}%`;
  }

  formatDate(date: Date): string {
    // getMonth is 0 based
    return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
  }

  getValuesByCategory(categories: string[], values: number[][], category: CustomerDailyMetricsTypeEnum) {
    const index = categories.indexOf(CustomerDailyMetricsTypeEnum[category]);
    if (index === -1) return [];
    return values[index];
  }

  @Watch('data')
  async onDataChange(newVal: ComparableAggregatedResult) {
    this.calculating = true;
    const result = newVal?.current;

    if (!result || !result.data) return;
    const { keys, categories, values } = await InsightsStore.createKeyValueArraysByCategory(result.data as AggregatedCustomer[], CustomerDataTypeEnum.DailyMetrics, result.startDate, result.endDate);
    const newCustData = this.getValuesByCategory(categories, values, CustomerDailyMetricsTypeEnum.NewCustomerCount);
    const returnCustData = this.getValuesByCategory(categories, values, CustomerDailyMetricsTypeEnum.ReturningCustomerCount);
    const totalCustData = new Array<number>();
    const returnCustRateData = new Array<number>();
    for (let i = 0; i < newCustData.length; i++) {
      totalCustData[i] = newCustData[i] + returnCustData[i];
      if (totalCustData[i] !== 0) {
        returnCustRateData.push(returnCustData[i] / totalCustData[i]);
      }
    }

    this.returningRate = returnCustRateData.length === 0 ? 0 : returnCustRateData.reduce((acc, cur) => acc + cur) * 100 / returnCustRateData.length;

    const mainLabels = ['New Customers', 'Returning Customers'];
    const tooltipCallback = (context) => {
      return `${mainLabels[context.datasetIndex]}: ${context.formattedValue} (${this.displayPercentage(context.raw, totalCustData, context.dataIndex)})`;
    }

    const mainDataset = {
      label: mainLabels[0],
      data: newCustData,
      backgroundColor: SecondaryDatasetColor,
      borderColor: SecondaryDatasetColor,
      tooltip: {
        callbacks: {
          label: tooltipCallback
        }
      }
    };

    const comparisonDataSet = {
      label: mainLabels[1],
      data: returnCustData,
      backgroundColor: MainDatasetColor,
      borderColor: MainDatasetColor,
      tooltip: {
        callbacks: {
          label: tooltipCallback
        }
      }
    };

    this.datasets = [mainDataset, comparisonDataSet];
    this.labels = keys.map(l => this.formatDate(l));
    this.calculating = false;
  }

  private displayPercentage(value, totals: number[], index: number) {
    return this.formatPercentage(value * 100 / Math.max(1, totals[index]));
  }
}
