










import { DisputeEvidence } from '@/store/dispute/dispute-models';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import DisputeEvidenceFiles from '../components/DisputeEvidenceFiles.vue';
import DisputeSupportingEvidenceGuide from './DisputeSupportingEvidenceGuide.vue';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';

@Component({
    components: {
        'dispute-supporting-evidence-guide': DisputeSupportingEvidenceGuide,
        'dispute-evidence-files': DisputeEvidenceFiles,
        'dispute-details-section': DisputeDetailsSection
    },
})
export default class DisputeSupportingEvidenceFilesSection extends Vue {
    @Prop({ required: true })
    evidence: DisputeEvidence;

    @Prop({ required: true })
    editable: boolean;

    @Emit('remoteFileUploaded')
    onRemoteFileUploaded(evt) {
        return evt;
    }

    @Emit('remoteFileDeleted')
    onRemoteFileDeleted(evt) {
        return evt;
    }
}
