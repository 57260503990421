






import { Component, Vue } from 'vue-property-decorator';

/**
 * Routing page for Orders
 */
@Component
export default class OrdersMainPage extends Vue {}
