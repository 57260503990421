























































































































import AddressDisplay from '@/components/orders/AddressDisplay.vue';
import LineItemsTable from '@/components/orders/LineItemsTable.vue';
import RefundDialog from '@/components/orders/RefundDialog.vue';
import RefundsTable from '@/components/orders/RefundsTable.vue';
import { Customer, Order } from '@/store/order/order-models';
import { applicationStore, featureStore, merchantStore, orderStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import TransactionTable from './TransactionTable.vue';
import SimpleView from '../layouts/SimpleView.vue';
import { Territory } from '@/store/application/application-models';
import { helpPageName } from '@/router';
import { toLocalDateTime } from '@/datetime';
import configuration from '@/configuration';

@Component({
  components: {
    'simple-view': SimpleView,
    'address-display': AddressDisplay,
    'line-items-table': LineItemsTable,
    'refund-dialog': RefundDialog,
    'refunds-table': RefundsTable,
    'transaction-table': TransactionTable
  }
})
export default class OrderDetails extends Vue {
  get showTax() {
    return featureStore.shouldDisplayTaxInfo;
  }

  get canAccessStatements() {
    return featureStore.shouldAllowStatementPagesAccess;
  }

  get order(): Order {
    return orderStore.order;
  }

  get customer(): Customer {
    return orderStore.order?.customer || {};
  }

  get customerName(): string | undefined {
    if (this.customer.firstName && this.customer.lastName) {
      return `${this.customer.firstName} ${this.customer.lastName}`;
    }
  }

  get helpPageRouteName(): string {
    return helpPageName;
  }

  get canRefund(): boolean {
    return merchantStore.merchant?.portalEnabledRefund || false;
  }

  get refundDisabled(): boolean {
    return this.canRefund
      && featureStore.shouldAllowDisputePagesAccess
      && this.isUsTerritory()
      && !!this.order.hasDisputedPayment;
  }

  get hasLineItems(): boolean {
    return !!orderStore?.order?.lineItems?.length;
  }

  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  get disputesAndRefundsLink() {
    return configuration.links.disputesAndRefundsLink;
  }

  /** Display the date, in Utc using the configuration.dateFormat */
  displayDate(date: any) {
    return toLocalDateTime(date);
  }

  isUsTerritory(): boolean {
    const territories = applicationStore.currentCountry?.territories || [];
    return territories.some(t => t === Territory.US);
  }
}
