import configuration from '@/configuration';
import LogMethod from '@/decorators/logger-decorator';
import { handleResponseErrors } from '@/errors';
import logger from '@/logger';
import {
  Transaction,
  TransactionReportInfo,
  TransactionSearchOptions,
  TransactionSearchResult,
} from '@/store/transaction/transaction-models';
import store, { applicationStore, authenticationStore, merchantStore } from '@/store/store';
import { AuthorizationError } from '@/store/store-models';
import {
  config,
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import moment from 'moment-timezone';

// Set rawError for all Actions in module to true
config.rawError = true;

// QP Merchant API Url
const merchantApiUrl = `${configuration.links.apiDomain}merchants/`;

const DefaultTransactionArray = [];
const DefaultTransactionCount = 0;
const DefaultTransactionSearchOptions = {
  text: '',
  pageSize: 0,
  page: 0,
  minimumCreateDateTime: '',
  maximumCreateDateTime: '',
};

/**
 * The transaction store is responsible for managing merchant transaction data and business logic
 */
@Module({
  name: 'transaction',
  namespaced: true,
  store,
})
export default class TransactionStore extends VuexModule {
  transactionArray: Transaction[] = DefaultTransactionArray;
  transactionCount: number = DefaultTransactionCount;
  lastSearchOptions: TransactionSearchOptions = DefaultTransactionSearchOptions;

  /**
   * Get Merchant Transactions by merchantId
   */
  @Action
  async searchTransactions(searchOptions: TransactionSearchOptions): Promise<any> {
    const { text, page, pageSize, orderBy, orderStatuses, minimumCreateDateTime, maximumCreateDateTime } = searchOptions;
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const { isShopify } = merchantStore;
    const convertedMinTime = moment.utc(minimumCreateDateTime).utcOffset(applicationStore.currentTimezone.utcOffset, true).startOf('day').format();
    const convertedMaxTime = moment.utc(maximumCreateDateTime).utcOffset(applicationStore.currentTimezone.utcOffset, true).endOf('day').format();
    let url = `${merchantApiUrl}${merchantId}/transactions`
    url += `?Page=${page}`;
    url += `&PageSize=${pageSize}`;
    url += `&OrderBy=${orderBy}`;
    url += `&OrderStatuses=${orderStatuses}`;
    url += `&IsShopify=${isShopify}`;
    url += `&MinimumCreateDateTime=${encodeURIComponent(moment(convertedMinTime).isValid() ? convertedMinTime : '')}`;
    url += `&MaximumCreateDateTime=${encodeURIComponent(moment(convertedMaxTime).isValid() ? convertedMaxTime : '')}`;
    url += `&Text=${text}`;
    url += `&UtcOffset=${applicationStore.currentTimezone.utcOffset}`;

    // record last search options
    this.setLastSearchOptions(searchOptions);
    
    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      logger.debug("Search response", response);
      if (response.status > 226) {
        // Trigger error modal
        await handleResponseErrors(response, 'transactions', true);
        return;
      } else {
        const body = await response.json();
        this.setTransactionSearchResult(body);
      }
    } catch (e) {
      // If 401, throw
      if (e instanceof AuthorizationError) throw e;
      // Else log error and trigger error modal
      logger.error(`Undefined error fetching merchant ${merchantId} transactions`, e);
    }
  }

  @Action
  async getTransactionsInfo(searchOptions: TransactionSearchOptions): Promise<TransactionReportInfo> {
    const { minimumCreateDateTime, maximumCreateDateTime } = searchOptions;
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    let url = `${merchantApiUrl}${merchantId}/transactions-report-info`;
    const convertedMinTime = moment.utc(minimumCreateDateTime).utcOffset(applicationStore.currentTimezone.utcOffset, true).startOf('day').format();
    const convertedMaxTime = moment.utc(maximumCreateDateTime).utcOffset(applicationStore.currentTimezone.utcOffset, true).endOf('day').format();
    url += `?MinimumCreateDateTime=${encodeURIComponent(moment(convertedMinTime).isValid() ? convertedMinTime : '')}`;
    url += `&MaximumCreateDateTime=${encodeURIComponent(moment(convertedMaxTime).isValid() ? convertedMaxTime : '')}`;
    url += `&includeVat=${configuration.isVatEnabled}`;
    url += `&ReportVersion=${searchOptions.csvVersion}`;
    url += `&UtcOffset=${applicationStore.currentTimezone.utcOffset}`

    // record last search options
    this.setLastSearchOptions(searchOptions);

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      logger.debug("Transaction report metadata response", response);
      if (response.status > 226) {
        return { pageLimit: 0, total: 0 };
      } else {
        const body = await response.json();
        return body as TransactionReportInfo;
      }
    } catch (e) {
      // If 401, throw
      if (e instanceof AuthorizationError) throw e;
      // Else log error and return undefined
      logger.error(`Undefined error retrieving merchant ${merchantId} transactions report metadata`, e);
      return { pageLimit: 0, total: 0 };
    }
  }

  @Action
  async exportTransactionsCsv(searchOptions: TransactionSearchOptions): Promise<string | void> {
    const { minimumCreateDateTime, maximumCreateDateTime, orderBy } = searchOptions;
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    let url = `${merchantApiUrl}${merchantId}/transactions-report`;
    const convertedMinTime = moment.utc(minimumCreateDateTime).utcOffset(applicationStore.currentTimezone.utcOffset, true).startOf('day').format();
    const convertedMaxTime = moment.utc(maximumCreateDateTime).utcOffset(applicationStore.currentTimezone.utcOffset, true).endOf('day').format();
    url += `?OrderBy=${orderBy}`;
    url += `&MinimumCreateDateTime=${encodeURIComponent(moment(convertedMinTime).isValid() ? convertedMinTime : '')}`;
    url += `&MaximumCreateDateTime=${encodeURIComponent(moment(convertedMaxTime).isValid() ? convertedMaxTime : '')}`;
    url += `&includeVat=${configuration.isVatEnabled}`;
    url += `&ReportVersion=${searchOptions.csvVersion}`;
    url += `&UtcOffset=${applicationStore.currentTimezone.utcOffset}`

    // record last search options
    this.setLastSearchOptions(searchOptions);

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      logger.debug("Transaction csv response", response);
      if (response.status > 226) {
        // Trigger error modal
        await handleResponseErrors(response, 'transactions csv', true);
        return;
      } else {
        // Convert response to blob
        const blob = await response.blob();

        // Create and return download url
        const downloadUrl = window.URL.createObjectURL(blob);
        return downloadUrl;
      }
    } catch (e) {
      // If 401, throw
      if (e instanceof AuthorizationError) throw e;
      // Else log error and return undefined
      logger.error(`Undefined error exporting merchant ${merchantId} transactions to csv`, e);
      return undefined;
    }
  }

  @Mutation
  setTransactionSearchResult(searchResult: TransactionSearchResult) {
    const { value, total } = searchResult;
    // generate unique id for each row
    this.transactionArray = value.map((trans, index) => {return {...trans, rowId: index}});
    this.transactionCount = total as number;
  }

  @Mutation
  setLastSearchOptions(searchOptions: TransactionSearchOptions) {
    this.lastSearchOptions = searchOptions;
  }

  @Mutation
  @LogMethod
  reset() {
    this.transactionArray = DefaultTransactionArray;
    this.transactionCount = DefaultTransactionCount;
  }
}
