





















































import { applicationStore } from '@/store/store';
import configuration from '@/configuration';
import { Invoice, InvoiceDetail } from '@/store/statement/statement-models';
import moment from 'moment';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
export default class StatementSummary extends Vue {
    @Prop({ required: true })
    details: InvoiceDetail | Invoice;
    @Prop({ required: false })
    compact: boolean;

    get isMobile() {
        return this.$vuetify.breakpoint.xs;
    }

    get currentLocale(): string {
        return applicationStore.currentLocale.locale;
    }
    
    get isStatementTooLarge() {
        const invoiceDetails = this.details as InvoiceDetail;
        return invoiceDetails?.items?.find(item => !!item.hiddenRowCount);
    }

    displayDate(date: Date | null): string {
        if (date) {
            return moment(date).utc().locale(this.currentLocale).format(configuration.dateFormat);
        }
        return '';
    }

    getInvoiceNumber(invoice: any) {
        return invoice.invoiceNumber ?? invoice.invoiceId;
    }
}
