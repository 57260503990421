
































import CheckImage from '@/assets/images/check.svg?inline';
import DialogBox from '@/components/common/DialogBox.vue';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import logger from '@/logger';
import { authenticationStore } from '@/store/store';
import { TranslateResult } from 'vue-i18n';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'check-image': CheckImage,
    'dialog-box': DialogBox,
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
  },
})
export default class CheckEmail extends Vue {
  showDialog = false;
  loading = true;

  closeDialogBox() {
    this.showDialog = false;
  }
  dialogClass = "";
  dialogTitle: TranslateResult = "";
  dialogMessage: TranslateResult = "";

  get email(): string {
    return authenticationStore.userInfo?.email || "";
  }

  async resendVerificationEmail() {
    try {
      // Requires BE endpoint so for now will always succeed
      await authenticationStore.resendVerificationEmail();
      this.dialogClass = 'qp-success';
      this.dialogTitle = this.$t('checkEmail.resend-success-title');
      this.dialogMessage = this.$t('checkEmail.resend-success-message', { email: this.email });
    } catch(e) {
      logger.error(`Error resending verification email: ${e.message}`);
      this.dialogClass = 'qp-error';
      this.dialogTitle = this.$t('checkEmail.resend-failure-title');
      this.dialogMessage = this.$t('checkEmail.resend-failure-message');
    } finally {
      this.showDialog = true;
    }
  }
}
