


















import { DisputeDateFilterEnum } from '@/store/dispute/dispute-models';
import { TranslateResult } from 'vue-i18n';
import { Component, Vue, Model, Watch, Emit } from 'vue-property-decorator';

@Component({})
export default class DisputeDateFilter extends Vue {
  @Model('change', { required: true })
  value: DisputeDateFilterEnum;

  selectedDateFilter = '';

  get disputeDateFilterItems() {
    const values: string[] = [];
    for (var n in DisputeDateFilterEnum) {
        if (typeof n === 'string') {
          values.push(n);
        }
    }
    return values.map(value => {
      return { value: DisputeDateFilterEnum[value], text: this.dateFilterMessage(DisputeDateFilterEnum[value]) }
    });
  }

  get dateFilterText(): TranslateResult {
    return this.$t('disputeDateFilter.date-filter');
  }

  dateFilterMessage(dateFilter?: DisputeDateFilterEnum): TranslateResult | string {
    switch (dateFilter) {
      case DisputeDateFilterEnum.disputeDate:
        return this.$t('disputeDateFilter.date-filter-dispute-date');
      case DisputeDateFilterEnum.respondByDate:
        return this.$t('disputeDateFilter.date-filter-respond-by-date');
      case DisputeDateFilterEnum.settledDate:
        return this.$t('disputeDateFilter.date-filter-settled-date');
      default:
        return '';
    }
  }

  @Watch('value')
  onDateFilterPropChanged(newValue: DisputeDateFilterEnum) {
    this.selectedDateFilter = this.dateFilterMessage(newValue).toString();
  }

  @Emit('change')
  onDateFilterChanged(dateFilter: { value: DisputeDateFilterEnum }) {
    return dateFilter.value;
  }

  async mounted() {
    this.selectedDateFilter = this.dateFilterMessage(this.value).toString();
  }
}
