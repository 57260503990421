import configuration from '@/configuration';
import LogMethod from '@/decorators/logger-decorator';
import { handleResponseErrors } from '@/errors';
import logger from '@/logger';
import {
  Payment,
  PaymentSearchOptions,
  PaymentSearchResult,
} from '@/store/payment/payment-models';
import store, { applicationStore, authenticationStore } from '@/store/store';
import { AuthorizationError } from '@/store/store-models';
import {
  config,
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import moment from 'moment-timezone';

// Set rawError for all Actions in module to true
config.rawError = true;

// QP Merchant API Url
const merchantApiUrl = `${configuration.links.apiDomain}merchants/`;

const DefaultPaymentArray = [];
const DefaultPaymentCount = 0;

/**
 * The payment store is responsible for managing merchant payment data and business logic
 */
@Module({
  name: 'payment',
  namespaced: true,
  store,
})
export default class PaymentStore extends VuexModule {
  paymentArray: Payment[] = DefaultPaymentArray;
  paymentCount: number = DefaultPaymentCount;

  /**
   * Get Merchant Payments by merchantId
   */
  @Action
  async searchPayments(searchOptions: PaymentSearchOptions): Promise<any> {
    const { text, page, pageSize, minimumCreateDateTime, maximumCreateDateTime, orderBy } = searchOptions;
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const convertedMinTime = moment.utc(minimumCreateDateTime).utcOffset(applicationStore.currentTimezone.utcOffset, true).startOf('day').format();
    const convertedMaxTime = moment.utc(maximumCreateDateTime).utcOffset(applicationStore.currentTimezone.utcOffset, true).endOf('day').format();

    let url = `${merchantApiUrl}${merchantId}/payments`
    url += `?Page=${page}`;
    url += `&PageSize=${pageSize}`;
    url += `&MinimumCreateDateTime=${encodeURIComponent(moment(convertedMinTime).isValid() ? convertedMinTime : '')}`;
    url += `&MaximumCreateDateTime=${encodeURIComponent(moment(convertedMaxTime).isValid() ? convertedMaxTime : '')}`;
    url += `&OrderBy=${orderBy}`;
    url += `&Text=${text}`;
    url += `&UtcOffset=${applicationStore.currentTimezone.utcOffset}`;
  
    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      logger.debug("Search response", response);
      if (response.status > 226) {
        // Trigger error modal
        await handleResponseErrors(response, 'payments', true);
        return;
      } else {
        const body = await response.json();
        this.setPaymentSearchResult(body);
      }
    } catch (e) {
      // If 401, throw
      if (e instanceof AuthorizationError) throw e;
      // Else log error and trigger error modal
      logger.error(`Undefined error fetching merchant ${merchantId} payments`, e);
    }
  }

  @Action
  async exportPaymentsCsv(searchOptions: PaymentSearchOptions): Promise<string | void> {
    const { minimumCreateDateTime, maximumCreateDateTime, orderBy } = searchOptions;
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    const convertedMinTime = moment.utc(minimumCreateDateTime).utcOffset(applicationStore.currentTimezone.utcOffset, true).startOf('day').format();
    const convertedMaxTime = moment.utc(maximumCreateDateTime).utcOffset(applicationStore.currentTimezone.utcOffset, true).endOf('day').format();
    let url = `${merchantApiUrl}${merchantId}/payments-csv`
    url += `?MinimumCreateDateTime=${encodeURIComponent(moment(convertedMinTime).isValid() ? convertedMinTime : '')}`;
    url += `&MaximumCreateDateTime=${encodeURIComponent(moment(convertedMaxTime).isValid() ? convertedMaxTime : '')}`;
    url += `&OrderBy=${orderBy}`;
    url += `&UtcOffset=${applicationStore.currentTimezone.utcOffset}`;
  
    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      logger.debug("Payment csv response", response);
      if (response.status > 226) {
        // Trigger error modal
        await handleResponseErrors(response, 'transactions csv', true);
        return;
      } else {
        // Convert response to blob
        const blob = await response.blob();

        // Create and return download url
        const downloadUrl = window.URL.createObjectURL(blob);
        return downloadUrl;
      }
    } catch (e) {
      // If 401, throw
      if (e instanceof AuthorizationError) throw e;
      // Else log error and return undefined
      logger.error(`Undefined error exporting merchant ${merchantId} payments to csv`, e);
      return;
    }
  }

  @Mutation
  setPaymentSearchResult(searchResult: PaymentSearchResult) {
    const { value, total } = searchResult;
    this.paymentArray = value as Payment[];
    this.paymentCount = total as number;
  }

  @Mutation
  @LogMethod
  reset() {
    this.paymentArray = DefaultPaymentArray;
    this.paymentCount = DefaultPaymentCount;
  }
}
