









































import { InvoiceDetail } from '@/store/statement/statement-models';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import StatementCsvDialog from './StatementCsvDialog.vue';

@Component({
    components: {
        'statement-csv-dialog': StatementCsvDialog,
    }
})
export default class StatementTransactionTotals extends Vue {
    @Prop({ required: true})
    details: InvoiceDetail;
    @Prop({ required: true})
    showTax: boolean;
    @Prop({ required: false})
    header: DataTableHeader; // NOTE: this is required for non-mobile view!!

    get isMobile() {
        return this.$vuetify.breakpoint.xs;
    }
}
