


















import ActionButton from '@/components/controls/ActionButton.vue';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';

@Component({
  components: {
    'dispute-details-section': DisputeDetailsSection,
    'action-button': ActionButton,
  },
})
export default class DisputeDetailsSummaryActionsSection extends Vue {
  @Prop({ required: true })
  hasSubmittedEvidence: boolean;

  @Prop({ required: true })
  editable: boolean;

  @Emit('acceptDispute')
  onAcceptDispute(evt) {
    return evt;
  }

  @Emit('counterDispute')
  onCounterDispute(evt) {
    return evt;
  }
}
