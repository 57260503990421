import i18n from "@/i18n";
import { TranslateResult } from "vue-i18n";

function isNotEmptyString(str: string): boolean {
  return !!str && str.trim().length > 0;
}

function IsNotEmptyValue(value: any): boolean {
  if (typeof value === 'string') {
    return isNotEmptyString(value);
  }
  return value !== null && value !== undefined;
}

// This is used to initialize VUE component local state
export const DefaultValidatorArray = Array<(v: any) => TranslateResult | boolean>();

// Assign this during 'created()' call
export const RequiredFieldValidator = [(value: any) => IsNotEmptyValue(value) || i18n.t("validators.validation-required"),];
