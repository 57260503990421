var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('search-view',{scopedSlots:_vm._u([{key:"search-options",fn:function(){return [_c('div',{staticClass:"qp-flex-center-center"},[(!_vm.firstLoad)?_c('v-text-field',{attrs:{"id":"qp-search-input","label":_vm.searchText,"outlined":"","hide-details":"","type":"text"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch()}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('search-icon')]},proxy:true}],null,false,2006118827),model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}):_vm._e(),_c('v-form',{ref:"form"},[_c('double-date-picker',{ref:"dateRange",attrs:{"locale":"currentLocale","startDateFilterOff":_vm.startFilterOff,"endDateFilterOff":_vm.endFilterOff},on:{"startDateUpdate":function($event){return _vm.startDateUpdated($event)},"endDateUpdate":function($event){return _vm.endDateUpdated($event)}}})],1)],1)]},proxy:true}])},[_c('div',[_c('div',[_c('timezone-notice'),(_vm.firstLoad)?_c('v-skeleton-loader',{attrs:{"id":"qp-transactions-table-skeleton-loader","type":"table-tbody","loading":_vm.loading}}):_vm._e(),(!_vm.firstLoad)?_c('v-data-table',{attrs:{"id":"qp-transactions-table","headers":_vm.tableHeaders,"loading":_vm.loading,"items":_vm.transactionArray,"item-class":function (item) { return 'qp-transactions-table-row'; },"item-key":"rowId","options":_vm.tableOptions,"footer-props":_vm.footerOptions,"hide-default-footer":!_vm.transactionCount,"no-data-text":_vm.$t('transactions.no-transactions-a'),"server-items-length":_vm.transactionCount},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([(_vm.notificationFeatureFlag)?{key:"header.createdDateTime",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"color":"transparent","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',{staticClass:"transaction-tooltip"},[_c('span',{staticClass:"tooltip-pointer"}),_vm._v(" "+_vm._s(_vm.$t('transactions.date-tooltip'))+" ")])])]}}:null,(_vm.notificationFeatureFlag)?{key:"header.paymentDate",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"color":"transparent","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',{staticClass:"transaction-tooltip"},[_c('span',{staticClass:"tooltip-pointer"}),_vm._v(" "+_vm._s(_vm.$t('transactions.payment-date-tooltip'))+" ")])])]}}:null,{key:"item.createdDateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.displayDate(item.createdDateTime)))]}},{key:"item.paymentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.displayDateUtc(item.paymentDate)))]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'qp-primary-text': item.amount > 0 }},[_vm._v(_vm._s(_vm.$n(item.amount, 'currency')))])]}},{key:"item.vatAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("" + (item.vatAmount ? _vm.$n(item.vatAmount, 'currency') : '-'))))]}},{key:"item.grossAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(("" + (_vm.$n(item.grossAmount, 'currency')))))]}},{key:"footer.page-text",fn:function(props){return [_c('table-footer',{attrs:{"tableOptions":_vm.tableOptions,"itemsLength":props.itemsLength},on:{"paginate":function($event){return _vm.paginate($event)}}})]}}],null,true)},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"qp-no-data-container qp-flex-center-center qp-flex-col"},[_c('transactions-icon',{staticClass:"qp-no-data-icon"}),_c('h2',{staticClass:"minus qp-info-text"},[_vm._v(_vm._s(_vm.$t('transactions.no-transactions-a')))]),_c('h2',{staticClass:"minus qp-info-text"},[_vm._v(_vm._s(_vm.$t('transactions.no-transactions-b')))]),_c('h4',{staticClass:"minus qp-grey-quaternary-text"},[_vm._v(_vm._s(_vm.$t('transactions.no-transactions-description')))])],1)])],2):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }