






import BasicLayout from '@/components/layouts/BasicLayout.vue';
import Settings from '@/components/settings/Settings.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'basic-layout': BasicLayout,
    'settings': Settings,
  }
})
export default class SettingsPage extends Vue {}
