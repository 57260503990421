import optimizely, { enums } from '@optimizely/optimizely-sdk';
import { ErrorHandler, LogHandler } from '@optimizely/js-sdk-logging';
import configuration from '@/configuration';
import LogMethod from '@/decorators/logger-decorator';
import store, { authenticationStore, } from '@/store/store';

import {
  config,
  Action,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import { FeatureFlag } from './feature-models';
import logger from '@/logger';
import eventBus from '@/event-bus';
import { errorHandler } from '@/errors';

const OptimizelyDatafileLoadTimeoutInMilliseconds = 10000;
const DefaultOptimizelyEventBatchSize = 10;
const DefaultOptimizelyEventFlushInterval = 1000;
// Error message if datafile property is missing from Optimizely config
// Can be ignored because datafile is not necessary as long as sdkKey is specified
const OptimizelyDatafileMessagePrefix = 'No datafile specified';

// Set rawError for all Actions in module to true
config.rawError = true;

/**
 * The feature store is responsible for managing feature flags
 * NOTE: feature store is a standalone module, and it is not depending on 'feature-flag.ts'
 * 
 * To add new permission:
 *   1. add a new local variable in store named like 'should????'
 *   2. define an entry in the "permissions" const in "initialize()" using the new local variable name as key
 *   3. follow the pattern of other existing permissions
 *   4. define similar operation in "setFeatureFlags()" and "reset()"
 */
@Module({
  name: 'feature',
  namespaced: true,
  store,
})
export default class FeatureStore extends VuexModule {
  shouldAllowStatementPagesAccess = false;
  shouldDisplayTaxInfo = false;
  shouldAllowInsightPagesAccess = false;
  shouldAllowTransactionPagesAccess = false;
  shouldAllowDisputePagesAccess = false;

  private static isOptimizelyFeatureEnabled(client: optimizely.Client, featureKey: string, defaultValue = false): boolean {
    const merchantIdUpper = authenticationStore.merchantId.toUpperCase();
    try {
      const featureVariable = !!client.isFeatureEnabled(featureKey, merchantIdUpper, {
        MerchantId: merchantIdUpper,
        Territory: authenticationStore.currentTerritory.toUpperCase()
      });
      logger.debug(`Optimizely (feature-store) for merchant ${merchantIdUpper} with feature key ${featureKey} => ${featureVariable}`);
      return featureVariable;
    } catch (e) {
      eventBus.publishErrorEvent(e);
      logger.error(`(feature-store) Failed to get feature for key ${featureKey}. Returning default value of ${defaultValue}`);
      return defaultValue;
    }
  }

  @Action
  async initialize() {
    //
    // Define All Permissions
    //
    const permissions = {
      shouldDisplayTaxInfo: {
        key: configuration.statements.optimizely.displayTaxInfoKey,
        isEnabledFunc: (featureFlag: FeatureFlag) => {
          if (featureFlag?.enabled) {
            return true;
          } else {
            return configuration.statements.taxInfoEnabledTerritories.includes(authenticationStore.currentTerritory);
          }
        }
      },
      shouldAllowInsightPagesAccess: {
        key: configuration.insights.optimizely.showInsightPagesKey,
        isEnabledFunc: (featureFlag: FeatureFlag) => {
          if (featureFlag?.enabled) {
            return true;
          } else {
            return configuration.featureFlags.insights;
          }
        }
      },
      shouldAllowStatementPagesAccess: {
        key: configuration.statements.optimizely.showStatementPagesKey,
        isEnabledFunc: (featureFlag: FeatureFlag) => {
          if (featureFlag?.enabled) {
            return true;
          } else {
            return configuration.featureFlags.statements;
          }
        }
      },
      shouldAllowTransactionPagesAccess: {
        key: configuration.statements.optimizely.hideTransactionPagesKey,
        isEnabledFunc: (featureFlag: FeatureFlag) => {
          if (featureFlag?.enabled) {
            return false;
          } else {
            return configuration.featureFlags.transactions;
          }
        }
      },
      shouldAllowDisputePagesAccess: {
        key: configuration.disputes.optimizely.showDisputePagesKey,
        isEnabledFunc: (featureFlag: FeatureFlag) => {
          if (featureFlag?.enabled) {
            return true;
          } else {
            return configuration.featureFlags.disputes;
          }
        }
      }
    }

    // Holding results from Optimizely (if enabled)
    const optimizelyResults = {};

    // Clear all previous values
    this.reset();

    if (configuration.featureFlags.optimizelyEnabled) {
      if (configuration.debug.optimizely) {
        optimizely.setLogLevel('debug');
      }
      
      const customErrorHandler: ErrorHandler = {
        handleError: (exception: Error) => {
          errorHandler(exception, false);
        }
      }

      const customLogHandler: LogHandler = {
        log: (level, message) => {
          if (message.includes(OptimizelyDatafileMessagePrefix)) return;
          logger.warn(`OPTIMIZELY (feature-store) level: ${level}, message: ${message}`);
        }
      }

      const optimizelyClientInstance = optimizely.createInstance({
        sdkKey: configuration.optimizelySdkKey,
        errorHandler: customErrorHandler,
        logger: customLogHandler,
        // Log levels 3 (warning) and 4 (error) only
        logLevel: enums.LOG_LEVEL.WARNING,
        eventBatchSize: DefaultOptimizelyEventBatchSize,
        eventFlushInterval: DefaultOptimizelyEventFlushInterval,
      });
      
      try {
        const result = await optimizelyClientInstance.onReady({ timeout: OptimizelyDatafileLoadTimeoutInMilliseconds });
        
        if (result.success) {
          logger.info('Optimizely instance (feature-store) is ready!', result.success);
        } else if (result.reason) {
          eventBus.publishErrorEvent(new Error(result.reason || 'Unknown optimizely (feature-store) error'), true);
        }
      } catch (e) {
        logger.warn('Unable to initialize optimizely (feature-store)');
      }

      for (const key in permissions) {
        const enabled = FeatureStore.isOptimizelyFeatureEnabled(optimizelyClientInstance, permissions[key].key);
        optimizelyResults[key] = { key, enabled };
      }

      await optimizelyClientInstance.close();
    }

    // initialize all feature flags
    const featureflags = {};
    for (const key in permissions) {
      const featureFlag: FeatureFlag | undefined = optimizelyResults[key];
      featureflags[key] = permissions[key].isEnabledFunc(featureFlag, permissions[key].key);
    }

    this.setFeatureFlags(featureflags);
  }

  @Mutation
  @LogMethod
  setFeatureFlags(featureflags: any) {
    this.shouldAllowStatementPagesAccess = featureflags.shouldAllowStatementPagesAccess;
    this.shouldDisplayTaxInfo = featureflags.shouldDisplayTaxInfo;
    this.shouldAllowInsightPagesAccess = featureflags.shouldAllowInsightPagesAccess;
    this.shouldAllowTransactionPagesAccess = featureflags.shouldAllowTransactionPagesAccess;
    this.shouldAllowDisputePagesAccess = featureflags.shouldAllowDisputePagesAccess;
  }

  @Mutation
  @LogMethod
  reset() {
    this.shouldAllowStatementPagesAccess = false;
    this.shouldDisplayTaxInfo = false;
    this.shouldAllowInsightPagesAccess = false;
    this.shouldAllowTransactionPagesAccess = false;
    this.shouldAllowDisputePagesAccess = false;
  }
}
