






import Unauthorized from '@/components/error/Unauthorized.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'unauthorized': Unauthorized,
  },
})
export default class UnauthorizedPage extends Vue {}
