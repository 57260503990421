






import { Component, Vue, Emit } from 'vue-property-decorator';

const events = ['dragenter', 'dragover', 'dragleave', 'drop'];

@Component({
  components: {
  }
})
export default class DropZone extends Vue {
  active = false;
  setInactiveTimeout: NodeJS.Timeout;

  @Emit('files-dropped')
  onDrop(e) {
    return [...e.dataTransfer.files];
  }

  preventDefaults(e) {
    e.preventDefault();
  }

  setActive() {
    this.active = true;
    clearTimeout(this.setInactiveTimeout); // clear the timeout
  }

  setInactive() {
    this.setInactiveTimeout = setTimeout(() => {
      this.active = false;
    }, 50);
  }

  async mounted() {
    events.forEach((eventName) => {
        document.body.addEventListener(eventName, this.preventDefaults);
    });
  }

  async unmounted() {
    events.forEach((eventName) => {
        document.body.removeEventListener(eventName, this.preventDefaults);
    });
  }
}
