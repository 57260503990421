/* eslint-disable @typescript-eslint/no-explicit-any */
import configuration from '@/configuration';
import eventBus from '@/event-bus';
import logger from '@/logger';
import { authenticationStore, merchantStore, applicationStore } from '@/store/store';
import { Route } from 'vue-router';
import { AnalyticsBrowser } from '@segment/analytics-next'

/**
 * Ecommerce spec reference:
 * https://segment.com/docs/spec/ecommerce/v2/
 */

/**
 * Retrieve the segment instance from the window.
 */
let analytics: AnalyticsBrowser;

function handleUserAuthenticatedEvent(subClaim: string, merchantId?: string) {
  analytics.identify(merchantId as string, { merchantId, subClaim, platform: merchantStore.platform, tier: merchantStore.merchantTier, region:  applicationStore.currentCountry });
}

function handleExistingUser(exists: boolean) {
  if (exists) {
    analytics.track('Existing User Received', { email: authenticationStore.userInfo.email, merchantId: authenticationStore.merchantId });
  } else {
    analytics.track('New User Received', { email: authenticationStore.userInfo.email });
  }
}

function handleEmailVerification(verified: boolean, merchantId: string, reason: string) {
  if (verified) {
    analytics.track('Email Verified Success', { merchantId });
  } else {
    analytics.track('Email Verified Failed', { merchantId, reason });
  }
}

function handleFormSubmission(formName: string, valid: boolean, inputs: any[], ...errorMessages: any[]) {
  const validationErrors = Array<any>();
  if (inputs.length) {
    inputs.forEach((input: any) => {
      if (input.hasError && (input as any).errorBucket) {
        const items = (input as any).errorBucket;
        validationErrors.push(...items);
      }
    });
  }

  // errorMessages will default to [ [] ]
  errorMessages.forEach((message: any) => {
    validationErrors.push(...message);
  });

  analytics.track('Form Submitted', { formName, valid, validationErrors });
}

function handleIntegrationTestResults(merchantId: string, success: boolean, widgetVerified: boolean, integrationVerified: boolean) {
  if (success) {
    analytics.track('Integration Test Passed', { merchantId, widgetVerified, integrationVerified });
  } else {
    analytics.track('Integration Test Failed', { merchantId, widgetVerified, integrationVerified });
  }
}

function handleWebsiteUpdated() {
  analytics.track('MSS Website Url Updated', { merchantId: authenticationStore.merchantId })
}

function handleMssDeclined(declineReason: string) {
  const { merchantId } = authenticationStore;
  const { email } = authenticationStore.userInfo;
  analytics.track('MSS Declined', { declineReason, merchantId, email });
}

function handleMssStageCompleted(stage: string, attempts: number) {
  // Attempts for return from Stripe
  // if attempts = 0 there was no counting
  // else (attempts * 10) = number of seconds it took to complete
  analytics.track('MSS Stage Completed', { merchantId: authenticationStore.merchantId, stage, attempts });
}

function handlePageView(route: Route) {
  let path = route.path;
  // To account for variability in guid-based pathnames, converge them here
  if (route.path?.includes('/orders')) {
    path = "/orders";
  }
  analytics.page(path);
}

function handleTrackAction(actionName: string, actionInfo?: Record<string, unknown>) {
  analytics.track(`Action Performed`, { actionName, actionInfo });
}

function handleTrackClick(itemClicked: string, itemInfo?: Record<string, unknown>) {
  analytics.track(`Element Clicked`, { itemClicked, itemInfo });
}

function handleError(error: Error, handled: boolean, id?: string) {
  if (handled) {
    analytics.track('Error Received', { error, handled, id });
  }
}

function handleVerificationEmailResent() {
  analytics.track('Verification Email Resent', { merchantId: authenticationStore.merchantId });
}

export default {
  // Retrieve the loaded segment instance
  // Mostly just for unit testing
  getSegment() { return analytics; },

  /**
   * Load the segment library and initiate all of the event subscribers
   */
  load() {
    analytics = AnalyticsBrowser.load({ writeKey: configuration.segmentKey });
    analytics.debug(configuration.debug.segment);

    eventBus.subscribeAuthenticatedEvent(handleUserAuthenticatedEvent);
    eventBus.subscribeEmailVerificationEvent(handleEmailVerification);
    eventBus.subscribeErrorEvent(handleError);
    eventBus.subscribeExistingUserEvent(handleExistingUser);
    eventBus.subscribeFormSubmissionEvent(handleFormSubmission);
    eventBus.subscribeIntegrationTestResultsEvent(handleIntegrationTestResults);
    eventBus.subscribeWebsiteUpdatedEvent(handleWebsiteUpdated);
    eventBus.subscribeMssDeclinedEvent(handleMssDeclined);
    eventBus.subscribeMssStageCompletedEvent(handleMssStageCompleted);
    eventBus.subscribePageViewEvent(handlePageView);
    eventBus.subscribeTrackActionEvent(handleTrackAction);
    eventBus.subscribeTrackClickEvent(handleTrackClick);
    eventBus.subscribeVerificationEmailResentEvent(handleVerificationEmailResent);
    
    logger.info('Successfully loaded Segment!');
  },
};
