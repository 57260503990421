























import { LineItem } from '@/store/order/order-models';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LineItemsTable extends Vue {
  @Prop({ required: true })
  lineItems: LineItem[];

  // OrderDetails.vue will determine whether or not this section is displayed
}
