






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BarChart from '../../controls/charts/BarChart.vue';
import { AggregatedCustomer, ComparableAggregatedResult, CustomerAgeGroupEnum, CustomerDataTypeEnum } from '@/store/insights/insights-models';
import InsightsStore from '@/store/insights/insights-store';

const MainDatasetColor = '#A17ECF';
const SecondaryDatasetColor = '#A17ECF66';

@Component({
  components: {
    'bar-chart': BarChart,
  }
})
export default class CustomerAgeGroupChart extends Vue {
  @Prop({ required: true })
  data: ComparableAggregatedResult;

  @Prop({ required: false })
  loading: boolean;

  datasets: Array<any> = [];
  labels: Array<string> = [];
  calculating = false;

  getLabels(): string[] {
    return [
      CustomerAgeGroupEnum[CustomerAgeGroupEnum.From16To24],
      CustomerAgeGroupEnum[CustomerAgeGroupEnum.From25To34],
      CustomerAgeGroupEnum[CustomerAgeGroupEnum.From35To44],
      CustomerAgeGroupEnum[CustomerAgeGroupEnum.From45To55],
      CustomerAgeGroupEnum[CustomerAgeGroupEnum.Above55],
      CustomerAgeGroupEnum[CustomerAgeGroupEnum.Unknown],
    ];
  }

  displayAgeGroup(label: string) {
    const ageGroup = CustomerAgeGroupEnum[label];
    switch (ageGroup) {
      case CustomerAgeGroupEnum.From16To24:
        return '16-24';
      case CustomerAgeGroupEnum.From25To34:
        return '25-34';
      case CustomerAgeGroupEnum.From35To44:
        return '35-44';
      case CustomerAgeGroupEnum.From45To55:
        return '45-55';
      case CustomerAgeGroupEnum.Above55:
        return '55+';
      default:
        return 'Other';
    }
  }

  @Watch('data')
  async onDataChange(newVal: ComparableAggregatedResult) {
    this.calculating = true;

    const result = newVal?.current;
    const pyresult = newVal?.previous;

    if (!result || !result.data) return;

    const labels = this.getLabels();
    const { values } = await InsightsStore.aggregateByCategory(result.data as AggregatedCustomer[], CustomerDataTypeEnum.AgeGroup, labels);
    let pyArr = { values: new Array<number>() };
    if (pyresult) {
      pyArr = await InsightsStore.aggregateByCategory(pyresult.data as AggregatedCustomer[], CustomerDataTypeEnum.AgeGroup, labels);
    }

    const totals = [values.reduce((acc, cur) => acc + cur, 0), pyArr.values.reduce((acc, cur) => acc + cur, 0)];

    const mainLabels = ['This Year', 'Last Year'];
    const tooltipCallback = (context) => {
      return `${mainLabels[context.datasetIndex]}: ${context.formattedValue} (${this.displayPercentage(context.raw, totals, context.datasetIndex)})`;
    }

    const mainDataset = {
      label: mainLabels[0],
      data: values,
      backgroundColor: MainDatasetColor,
      borderColor: MainDatasetColor,
      indexAxis: 'y',
      tooltip: {
        callbacks: {
          label: tooltipCallback
        }
      }
    };

    const comparisonDataset = !pyresult ? null : {
      label: mainLabels[1],
      data: pyArr.values,
      backgroundColor: SecondaryDatasetColor,
      borderColor: SecondaryDatasetColor,
      indexAxis: 'y',
      tooltip: {
        callbacks: {
          label: tooltipCallback
        }
      }
    };

    this.datasets = !comparisonDataset ? [mainDataset] : [mainDataset, comparisonDataset];
    this.labels = labels.map(this.displayAgeGroup);
    this.calculating = false;
  }

  private displayPercentage(value, totals: number[], index: number) {
    return this.formatPercentage(value * 100 / Math.max(1, totals[index]));
  }

  formatPercentage(value: number): string {
    return `${isNaN(value) ? 0 : value.toFixed(2)}%`;
  }
}
