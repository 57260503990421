import logger from "@/logger";
import LogMethod from '@/decorators/logger-decorator';
import {
  Notification, WhatsNewItem, MerchantTargetGroup, TargetSearchOptions, IsFilterable,
} from "@/store/content/content-models";
import store, { applicationStore, authenticationStore, merchantStore } from '@/store/store';
import {
  config,
  Action,
  Module,
  VuexModule,
  Mutation,
} from 'vuex-module-decorators';

import contentfulService from '@/services/contentful';

import { BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

// Set rawError for all Actions in module to true
config.rawError = true;

const DefaultTopNotificationArray = [];
const DefaultSideNotificationArray = [];
const DefaultWhatsNewItemsArray = [];


/**
 * The Content store is responsible for loading and storing content strings from contentful
 */
@Module({
  name: 'content',
  namespaced: true,
  store
})
export default class ContentStore extends VuexModule {
  topBarContentNotifications: Notification[] = DefaultTopNotificationArray;
  sideBarContentNotifications: Notification[] = DefaultSideNotificationArray;
  whatsNewItems: WhatsNewItem[] = DefaultWhatsNewItemsArray;

  /**
   * Refreshes all content after the merchant switcher has been changed
   */
  @Action
  async refresh(): Promise<void> {
    await this.refreshNotifications();
    await this.refreshWhatsNewItems();
  }

  @Action
  async refreshNotifications(): Promise<void> {
    await this.getNotifications({
      language: applicationStore.currentLocale.language,

      region: authenticationStore.currentTerritory,
      merchantId: authenticationStore.merchantId,
      lastLogin: authenticationStore.lastLogin,

      tier: merchantStore.merchantTier,
      platform: merchantStore.platform,
      industry: merchantStore.industry
    });
  }

  /**
   * Get Notifications by region
   */
  @Action
  @LogMethod
  async getNotifications(searchOptions: TargetSearchOptions): Promise<any> {
    try {
      const { items, merchantTargetGroups } = await contentfulService.getNotifications();

      const notifications = items
        // Filter region
        .filter(item => item.region === searchOptions.region)
        // Filter last login date
        .filter(item => ContentStore.lastLoginOccurredBeforeSpecificDate(searchOptions.lastLogin, item.lastLogin))
        // Filter merchant group (empty or in included group)
        .filter(item => ContentStore.includeByTargetGroup(item, searchOptions, merchantTargetGroups))
        .filter(item => !ContentStore.excludeByTargetGroup(item, searchOptions, merchantTargetGroups))
        .map(item => ({
          ...item,
          title: searchOptions.language === item.secondaryLanguage ? item.secondaryLanguageTitle : item.primaryLanguageTitle,
          body: documentToHtmlString(searchOptions.language === item.secondaryLanguage ? item.secondaryLanguageBody : item.primaryLanguageBody)
        } as Notification))
        // Oldest notification on top
        .reverse();

      const topBarNotifications = notifications.filter(e => e.location === 'Top bar');
      this.setTopBarContentNotifications(topBarNotifications);

      const sideBarNotifications = notifications.filter(e => e.location === 'Side bar');
      this.setSideBarContentNotifications(sideBarNotifications);
    }
    catch (error) {
      logger.error(`Exception retriving notification content for region ${searchOptions.region}`, error);
      return [];
    }
  }

  @Mutation
  setTopBarContentNotifications(notifications: Notification[]) {
    this.topBarContentNotifications = notifications;
  }

  @Mutation
  setSideBarContentNotifications(notifications: Notification[]) {
    this.sideBarContentNotifications = notifications;
  }

  @Mutation
  clearWhatsNewItems() {
    this.whatsNewItems = DefaultWhatsNewItemsArray;
  }

  @Action
  async refreshWhatsNewItems(): Promise<void> {
    await this.getWhatsNewItems({
      language: applicationStore.currentLocale.language,

      region: authenticationStore.currentTerritory,
      merchantId: authenticationStore.merchantId,
      lastLogin: authenticationStore.lastLogin,

      tier: merchantStore.merchantTier,
      platform: merchantStore.platform,
      industry: merchantStore.industry
    });
  }

  @Action
  @LogMethod
  async getWhatsNewItems(searchOptions: TargetSearchOptions): Promise<any> {
    const { items, merchantTargetGroups } = await contentfulService.getWhatsNewItems();

    try {
      const filteredItems = items
        // Filter region
        .filter(item => item.region === searchOptions.region)
        // Filter last login date
        .filter(item => ContentStore.lastLoginOccurredBeforeSpecificDate(searchOptions.lastLogin, item.lastLogin))
        // Filter merchant group (empty or in included group)
        .filter(item => ContentStore.includeByTargetGroup(item, searchOptions, merchantTargetGroups))
        // Filter merchant group (empty or in excluded group)
        .filter(item => !ContentStore.excludeByTargetGroup(item, searchOptions, merchantTargetGroups))
        .map(item => ({
          ...item,
          title: searchOptions.language === item.secondaryLanguage ? item.secondaryLanguageTitle : item.primaryLanguageTitle,
          body: documentToHtmlString(searchOptions.language === item.secondaryLanguage ? item.secondaryLanguageBody : item.primaryLanguageBody, {
            renderNode: {
              [BLOCKS.EMBEDDED_ASSET]: ({ data: { target: { fields }}}) =>
                `<img src="${fields.file.url}" alt="${fields.description || fields.title}"/>`
            }
          })
        }))

      this.setWhatsNewItems(filteredItems);
    }
    catch (error) {
      logger.error(`Exception retriving whats new content for region ${searchOptions.region}`, error);
      return [];
    }
  }

  @Mutation
  setWhatsNewItems(whatsNewItems: WhatsNewItem[]) {
    this.whatsNewItems = whatsNewItems;
  }

  @Mutation
  @LogMethod
  reset() {
    this.topBarContentNotifications = DefaultTopNotificationArray;
    this.sideBarContentNotifications = DefaultSideNotificationArray;
    this.whatsNewItems = DefaultWhatsNewItemsArray;
  }

  static includeByTargetGroup(item: IsFilterable, searchOptions: TargetSearchOptions, targetGroupEntries: MerchantTargetGroup[]): boolean {
    // No merchant group target specified, show to everyone
    if (!item?.merchantTargetGroup)
      return true;

    const merchantTargetGroup = ContentStore.getTargetGroupInfo(item?.merchantTargetGroup?.sys?.id, targetGroupEntries);
    if(!merchantTargetGroup) {
      // Unable to find target merchant details, hide notification (incorrectly set up)
      return false;
    }
    
    if (!ContentStore.matchesMerchantIdsArray(searchOptions.merchantId, merchantTargetGroup.merchantIds, true)) {
      // Don't show, as merchant id doesn't match this notification's setup
      return false;
    }

    if(!ContentStore.matchesMerchantTier(searchOptions.tier, merchantTargetGroup.merchantTier)) {
      return false;
    }

    if(!ContentStore.matchesMerchantPlatform(searchOptions.platform, merchantTargetGroup.platform)) {
      return false;
    }
    
    if(!ContentStore.matchesMerchantIndustry(searchOptions.industry, merchantTargetGroup.industry)) {
      return false;
    }
    
    return true;
  }
  
  static excludeByTargetGroup(item: IsFilterable, searchOptions: TargetSearchOptions, targetGroupEntries: MerchantTargetGroup[]): boolean {
    // No merchant group target specified, show to everyone
    if (!item?.excludeMerchantTargetGroup)
      return false;

    const merchantTargetGroup = ContentStore.getTargetGroupInfo(item?.excludeMerchantTargetGroup?.sys?.id, targetGroupEntries);
    if(!merchantTargetGroup) {
      // Unable to find target merchant details, don't exclude notification (incorrectly set up)
      return false;
    }
    
    if (ContentStore.matchesMerchantIdsArray(searchOptions.merchantId, merchantTargetGroup.merchantIds, true)) {
      // Don't show, as merchant id doesn't match this notification's setup
      return true;
    }

    if(ContentStore.matchesMerchantTier(searchOptions.tier, merchantTargetGroup.merchantTier)) {
      return true;
    }

    if(ContentStore.matchesMerchantPlatform(searchOptions.platform, merchantTargetGroup.platform)) {
      return true;
    }
    
    if(ContentStore.matchesMerchantIndustry(searchOptions.industry, merchantTargetGroup.industry)) {
      return true;
    }
    
    return false;
  }

  private static getTargetGroupInfo(merchantTargetGroupId: string, targetGroupEntries: MerchantTargetGroup[]): Nullable<MerchantTargetGroup> {
    if(!merchantTargetGroupId) {
      return null;
    }

    const matchingMerchantTargetGroups = targetGroupEntries?.filter(mtg => mtg.id == merchantTargetGroupId);
    if (!matchingMerchantTargetGroups || !matchingMerchantTargetGroups.length)
      return null; // doesn't match any target group
    
    return matchingMerchantTargetGroups[0] as MerchantTargetGroup;
  }

  private static matchesMerchantIdsArray(merchantId: string, merchantIds: string[], defaultResponse: boolean): boolean {
    if(!merchantIds || !merchantIds.length) {
      // No merchant ID specified, show to everyone
      return defaultResponse;
    }

    const lowerMerchantId = merchantId.toLowerCase();
    return merchantIds
      .map(id => id?.toLowerCase())
      .some(id => id === lowerMerchantId);
  }

  static lastLoginOccurredBeforeSpecificDate(actualLastLogin: Date, targetLastLogin: Date | undefined) {
    if(!actualLastLogin || !targetLastLogin)
      return true;

    return actualLastLogin < (new Date(targetLastLogin));
  }

  private static matchesMerchantTier(actualTier: string | undefined, expectedTier: string | undefined, defaultResponse = true) {
    if(!expectedTier)
      return defaultResponse;
    
    return actualTier?.toLowerCase()?.replace("tier", "")?.trim() === expectedTier.toLowerCase().replace("tier", "").trim();
  }
  
  private static matchesMerchantPlatform(actualPlatform: string | undefined, expectedPlatform: string | undefined, defaultResponse = true) {
    if(!expectedPlatform)
      return defaultResponse;
    
    return actualPlatform?.toLowerCase()?.trim() === expectedPlatform?.toLowerCase()?.trim();
  }

  private static matchesMerchantIndustry(actualIndustry: string | undefined, expectedIndustry: string | undefined, defaultResponse = true) {
    if(!expectedIndustry)
      return defaultResponse;

    return actualIndustry?.toLowerCase()?.trim() === expectedIndustry?.toLowerCase()?.trim();
  }
}