



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import LineChart from '../../controls/charts/LineChart.vue';
import ScalarChart from '../../controls/charts/ScalarChart.vue';
import InsightsStore from '@/store/insights/insights-store';
import { AggregatedResult, ComparableAggregatedResult } from '@/store/insights/insights-models';

const MainDatasetColor = '#A17ECF';
const SecondaryDatasetColor = '#A17ECF66';

@Component({
  components: {
    'scalar-chart': ScalarChart,
    'line-chart': LineChart,
  }
})
export default class AggregatedResultChart extends Vue {
  @Prop({ required: true })
  data: ComparableAggregatedResult;

  @Prop({ required: false, default: false })
  calculateAverage: boolean;

  @Prop({ required: true })
  scalarTitle: string;

  @Prop({ required: true })
  chartTitle: string;

  @Prop({ required: false })
  loading: boolean;

  @Prop({ required: true })
  getValueFunc: (entry?: any) => number;

  @Prop({ required: false })
  getAverageValueFunc: (values: number[], data: AggregatedResult) => number;

  @Prop({ required: true })
  formatValueFunc: (val?: number) => string;

  total = 0;
  datasets: Array<any> = [];
  labels: Array<string> = [];
  calculating = false;

  comaprisonPercentage = 0;
  comparisonValue = 0;
  comaprisonPeriod = 'previous year';

  get computedComparisonValue() {
    return this.comparisonValue == 0 ? '' : this.formatValueFunc(this.comparisonValue);
  }

  formatDate(date: Date): string {
    // getMonth is 0 based
    return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
  }

  getAverageValue(values: number[], data: AggregatedResult) {
    if (this.getAverageValueFunc) {
      return this.getAverageValueFunc(values, data);
    }
    return values.reduce((acc, cur) => acc += cur, 0) / (this.calculateAverage ? Math.max(values.length, 1) : 1);
  }

  @Watch('data')
  async onDataChange(newVal: ComparableAggregatedResult) {
    this.calculating = true;

    const result = newVal?.current;
    const pyresult = newVal?.previous;
    if (!result || !result.data) return;

    const { keys, values } = await InsightsStore.createKeyValueArrays(result.data, result.startDate, result.endDate, this.getValueFunc);
    let pyArr = { values: new Array<number>() };
    if (pyresult) {
      pyArr = await InsightsStore.createKeyValueArrays(pyresult.data, pyresult.startDate, pyresult.endDate, this.getValueFunc);
    }

    this.total = this.getAverageValue(values, result);
    this.comparisonValue = (pyArr?.values?.reduce((acc, cur) => acc += cur, 0) || 0) / (this.calculateAverage ? Math.max(pyArr?.values?.length ?? 1, 1) : 1);
    this.comaprisonPercentage = ((this.total - this.comparisonValue) / this.comparisonValue) * 100;
    
    const mainDataset = {
      label: 'Current',
      borderColor: MainDatasetColor,
      backgroundColor: MainDatasetColor,
      data: values
    };

    const comparisonDataSet = !pyresult ? null : {
      label: 'Previous Year',
      borderColor: SecondaryDatasetColor,
      backgroundColor: SecondaryDatasetColor,
      data: pyArr.values
    };

    this.datasets = !comparisonDataSet ? [mainDataset] : [mainDataset, comparisonDataSet];
    this.labels = keys.map(l => this.formatDate(l));
    this.calculating = false;
  }
}
