










import { Component, Prop, Vue } from 'vue-property-decorator';
import { Line } from 'vue-chartjs/legacy';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LineElement, CategoryScale, PointElement, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, LineElement, CategoryScale, LinearScale, PointElement);

@Component({
  components: {
    'Line1': Line // using <Line> triggers errors since its used in native canvas?
  }
})
export default class LineChart extends Vue {

  /*
  Based on chartjs and its vue wrapper vue-chartjs
  for documentation about chartjs see https://www.chartjs.org/
  for documentation about vue-chartjs see https://vue-chartjs.org/
  */
 
  @Prop({ required: true })
  title: string;

  @Prop({ required: true })
  labels: Array<string>;

  @Prop({ required: true })
  datasets: Array<{ label: string, backgroundColor: string, data: Array<number> }>;

  @Prop({ required: false })
  loading: boolean

  @Prop ({required: false})
  options: any

  chartId = 'line-chart';
  datasetIdKey = 'label';
  width = 600;
  height = 400;
  cssClasses = '';
  defaultOptions = {
    responsive: false,
    scales:{
      y:{
        beginAtZero:true
      },
      x:{
        beginAtZero:true
      }
    }
  }
}

