













































































import moment from 'moment-timezone';
import configuration from '@/configuration';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { applicationStore } from '@/store/store';
import CalendarIcon from '@/assets/images/calendar-icon.svg?inline';
import 'moment/locale/es';
import 'moment/locale/fr-ca';

@Component({
  components: {
    'calendar-icon': CalendarIcon
  }
})
export default class DoubleDatePicker extends Vue {
  @Prop({ required: true })
  locale: string;
  @Prop({ required: false, default: moment.utc().startOf('month').toISOString() })
  startDate: string;
  @Prop({ required: false, default: moment.utc().endOf('month').toISOString() })
  endDate: string;
  @Prop({ required: false, default: false})
  startDateFilterOff: boolean;
  @Prop({ required: false, default: false})
  endDateFilterOff: boolean;

  startDateState = "";
  endDateState = "";

  // v-date-picker accepts ISO 8601 date strings (YYYY-MM-DD)
  defaultStartDate: string = moment.utc().startOf('month').toISOString();
  defaultEndDate: string = moment.utc().endOf('month').toISOString();
  minimumStartDate: string = moment.utc().subtract(11, 'years').toISOString();
  maximumEndDate: string = moment.utc().endOf('month').toISOString();
  startDateMenuOpened = false;
  endDateMenuOpened = false;

  get maximumStartDate(): string {
    return moment.utc(this.endDateState).toISOString();
  }

  get startDateText(): string {
    return this.startDateFilterOff ? this.$t('doubleDatePicker.start-date').toString() : moment.utc(this.startDateState).locale(this.currentLocale).format(configuration.dateFormat);
  }

  get minimumEndDate(): string {
    return moment.utc(this.startDateState).toISOString();
  }

  get endDateText(): string {
    return this.endDateFilterOff ? this.$t('doubleDatePicker.end-date').toString() : moment.utc(this.endDateState).locale(this.currentLocale).format(configuration.dateFormat);
  }

  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  @Watch('startDate')
  onStartDatePropChanged(newValue) {
    this.startDateState = newValue;
  }

  @Watch('endDate')
  onEndDatePropChanged(newValue) {
    this.endDateState = newValue;
  }

  @Emit("startDateUpdate")
  onStartDateUpdated(newValue) {
    this.startDateFilterOff = false;
    this.startDateMenuOpened = false;
    return newValue;
  }

  @Emit("endDateUpdate")
  onEndDateUpdated(newValue) {
    this.endDateFilterOff = false;
    this.endDateMenuOpened = false;
    return newValue;
  }

  async mounted() {
    this.startDateState = this.startDate;
    this.endDateState = this.endDate;
  }

  static getInclusiveEndDate(endDate: string): string {
    return moment.utc(endDate).startOf('day').add(1, 'day').toISOString();
  }
}
