






import SessionExpired from '@/components/error/SessionExpired.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'session-expired': SessionExpired,
  },
})
export default class SessionExpiredPage extends Vue {}
