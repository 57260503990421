













import { AggregatedOrder, AggregatedResult, ComparableAggregatedResult } from '@/store/insights/insights-models';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AggregatedResultChart from './AggregatedResultChart.vue';

@Component({
  components: {
    'aggregated-result-chart': AggregatedResultChart
  }
})
export default class OrderAverageValueChart extends Vue {
  @Prop({ required: true })
  data: ComparableAggregatedResult;

  @Prop({ required: false })
  loading: boolean;

  getValue(entry: any): number {
    return entry?.averageValue ?? 0;
  }

  formatValue(val: number) : string {
    return this.$n(val, 'currency');
  }

  getAverageValue(values: number[], data: AggregatedResult) {
    const mapped = data.data.map(r => (r as AggregatedOrder));
    const total = mapped.map(m => m.totalValue).reduce((acc, cur) => acc += cur, 0);
    const count = mapped.length == 0 ? 1 : mapped.map(m => m.entityCount).reduce((acc, cur) => acc += cur);
    return total / count;
  }
}
