/**
 * This export is used to define the configuration value types to remove string parsing everywhere.
 */
export interface SupportedCountry {
  code: string;
  displayName: string;
  currencyCode: string;
  currencySymbol: "$" | "£";
  // Min/max transactions used for label purposes only
  defaultMinTransaction: string;
  defaultMaxTransaction: string;
  minPhoneNumberLength: number;
  maxPhoneNumberLength: number;
  supportedLocales: SupportedLocale[];
  supportedTimezones: Timezone[];
  territories: Territory[];
}
export interface SupportedLocale {
  locale: string;
  language: string;
  displayName: string;
}

export enum Territory {
  US = 'US',
  CA = 'CA',
  GB = 'GB',
  MX = 'MX',
  SG = 'SG'
}

export interface Timezone {
  display: string;
  code: string;
  utcOffset: number;
}


