









import configuration from '@/configuration';
import { toLocalDate } from '@/datetime';
import { DisputeDetail, DisputeReconciledStatusEnum, DisputeReasonEnum } from '@/store/dispute/dispute-models';
import { Component, Vue, Prop } from 'vue-property-decorator';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';

@Component({
  components: {
    'dispute-details-section': DisputeDetailsSection
  },
})
export default class DisputeDetailsSummaryHeaderSection extends Vue {
  @Prop({ required: true })
  dispute: DisputeDetail;

  @Prop({ required: true })
  hasSubmittedEvidence: boolean;

  get headline() {
    switch (this.dispute.status) {
      case DisputeReconciledStatusEnum.needsResponse:
        return this.$t('disputeDetailsSummary.headline-needs-response');
      case DisputeReconciledStatusEnum.underReview:
        return this.$t('disputeDetailsSummary.headline-under-review');
      case DisputeReconciledStatusEnum.won:
        return this.$t('disputeDetailsSummary.headline-won');
      case DisputeReconciledStatusEnum.wonPartially:
        return this.$t('disputeDetailsSummary.headline-won-partially');
      case DisputeReconciledStatusEnum.closed:
          return this.$t('disputeDetailsSummary.headline-lost-accepted');
      case DisputeReconciledStatusEnum.lost:
        return this.$t('disputeDetailsSummary.headline-lost');
      default:
        return '';
    }
  }

  get subheadline() {
    if(this.dispute.status !== DisputeReconciledStatusEnum.needsResponse) {
      return '';
    }

    switch (this.dispute.reason) {
      case DisputeReasonEnum.subscriptionCanceled:
        return this.$t('disputeDetailsSummary.subheadline-subscription-cancelled');
      case DisputeReasonEnum.productUnacceptable:
        return this.$t('disputeDetailsSummary.subheadline-product-unacceptable');
      case DisputeReasonEnum.productNotReceived:
        return this.$t('disputeDetailsSummary.subheadline-product-not-recieved');
      case DisputeReasonEnum.creditNotProcessed:
        return this.$t('disputeDetailsSummary.subheadline-credit-not-processed');
      case DisputeReasonEnum.general:
        return this.$t('disputeDetailsSummary.subheadline-general');
    }
    
    return '';
  }

  get description() {
    switch (this.dispute.status) {
      case DisputeReconciledStatusEnum.underReview:
        return this.$t('disputeDetailsSummary.description-under-review', {
          submittedOn: this.submittedOn ? this.$t('disputeDetailsSummary.submitted-on', {
            submissionDate: this.submittedOn
          }) : ''
        });
      case DisputeReconciledStatusEnum.wonPartially:
        return this.$t('disputeDetailsSummary.description-won-partially', {
          amount: this.amount,
          remainingAmount: this.remainingAmount,
          returnedAmount: this.returnedAmount,
          disputeFee: this.disputeFee
        });
      case DisputeReconciledStatusEnum.won:
        return this.$t('disputeDetailsSummary.description-won', { settledOn: this.settledOn, amount: this.amount, disputeFee: this.disputeFee });
      case DisputeReconciledStatusEnum.closed:
          return this.$t('disputeDetailsSummary.description-lost-accepted');
      case DisputeReconciledStatusEnum.lost:
        if (!this.hasSubmittedEvidence) {
          return this.$t('disputeDetailsSummary.description-lost-no-evidence', { amount: this.amount, disputeFee: this.disputeFee });
        }
        return this.$t('disputeDetailsSummary.description-lost-with-evidence', { amount: this.amount, disputeFee: this.disputeFee });
      case DisputeReconciledStatusEnum.needsResponse:
        switch (this.dispute.reason) {
          case DisputeReasonEnum.subscriptionCanceled:
            return this.$t('disputeDetailsSummary.description-needs-response-subscription-cancelled');
          case DisputeReasonEnum.productUnacceptable:
            return this.$t('disputeDetailsSummary.description-needs-response-product-unacceptable');
          case DisputeReasonEnum.productNotReceived:
            return this.$t('disputeDetailsSummary.description-needs-response-product-not-recieved');
          case DisputeReasonEnum.creditNotProcessed:
            return this.$t('disputeDetailsSummary.description-needs-response-credit-not-processed');
          default:
            return '';
        }
    }
  }

  get descriptionLink() {
    switch (this.dispute.status) {
      case DisputeReconciledStatusEnum.needsResponse:
        return {
          text: this.$t("disputeDetailsSummary.link-needs-response"),
          link: configuration.links.respondingToDisputeLink,
        };

      case DisputeReconciledStatusEnum.underReview:
        return {
          text: this.$t("disputeDetailsSummary.link-under-review"),
          link: configuration.links.respondingToDisputeLink,
        };
    }

    return {
      text: this.$t("disputeDetailsSummary.link-other"),
      link: configuration.links.preventingDisputesLink,
    };
  }

  get amount() {
    return this.$n(this.dispute.disputedAmount, "currency");
  }

  get returnedAmount() {
    return this.$n(this.dispute.reinstatedAmount ?? 0, "currency");
  }

  get remainingAmount() {
    return this.$n(this.dispute.disputedAmount - (this.dispute.reinstatedAmount ?? 0), "currency");
  }

  get disputeFee() {
    return this.$n(this.dispute?.disputeFeeAmount ?? 0, "currency");
  }

  get settledOn() {
    return toLocalDate(this.dispute?.settledOn);
  }

  get submittedOn() {
    return toLocalDate(this.dispute?.evidenceSubmittedOn);
  }
}
