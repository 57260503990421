































import { mdiArrowLeft } from '@mdi/js';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import LoadingSpinner from '../common/LoadingSpinner.vue';

@Component({
  components: {
    'loading-spinner': LoadingSpinner,
  }
})
export default class BasicLayout extends Vue {
  @Prop({ required: false })
  pageHeading: string;
  @Prop({ required: false })
  navBackLinkText: string;
  @Prop({ required: false })
  isLoading: boolean;
  @Prop({ required: false })
  disableMainStyle: boolean;
  @Prop({ required: false })
  fullWidth: boolean;

  backArrowIcon: string = mdiArrowLeft;

  @Emit('navigateBack')
  navBackLinkClicked(event: any) {
    return event;
  }
}
