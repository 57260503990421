// Datadog Real User Monitoring
import configuration from '@/configuration';
import { datadogRum, RumUserConfiguration } from '@datadog/browser-rum';
import eventBus from '@/event-bus';
import logger from '@/logger';
import { version } from '@/../package.json';
import { authenticationStore, merchantStore } from '@/store/store';

function initialize() {
  const allowList = configuration.serviceDomains.map((element) => new RegExp(`https://((?!${configuration.auth.domain}).*).${element}`));

  const ddRumConfig: RumUserConfiguration = {
    applicationId: configuration.datadog.applicationId,
    clientToken: configuration.datadog.clientToken,
    datacenter: 'us',
    env: configuration.environmentName?.toLowerCase(),
    allowedTracingOrigins: allowList,
    version,
    sampleRate: 100,
    service: 'merchant-portal',
    silentMultipleInit: true,
  };
  datadogRum.init(ddRumConfig);
}

function handleAuthenticated(subClaim: string, merchantId?: string) {
  datadogRum.addUserAction('User Authenticated', { subClaim, merchantId, platform: merchantStore.platform, tier: merchantStore.merchantTier });
}

function handleEmailVerification(verified: boolean, merchantId: string, reason: string) {
  if (verified) {
    datadogRum.addUserAction('MP User Email Verified Success', { merchantId });
  } else {
    datadogRum.addUserAction('MP User Email Verified Failed', { merchantId, reason });
  }
}

function handleError(error: Error, handled: boolean, id?: string) {
  const type = (error as any).constructor?.name ? (error as any).constructor.name : 'Error';
  datadogRum.addError(error, {
    qpErrorId: id,
    errorDescription: error.toString(),
    handled,
    type,
    qpMerchantId: authenticationStore.merchantId,
  });
}

function handleExistingUser(exists: boolean) {
  if (exists) {
    datadogRum.addUserAction('Existing User Received', { email: authenticationStore.userInfo.email, merchantId: authenticationStore.merchantId });
  } else {
    datadogRum.addUserAction('New User Received', { email: authenticationStore.userInfo.email });
  }
  datadogRum.addRumGlobalContext('Portal_Context', { email: authenticationStore.userInfo.email, merchantId: authenticationStore.merchantId })
}

function handleFormSubmission(formName: string, valid: boolean, inputs: any[], ...errorMessages: any[]) {
  if (valid) {
    datadogRum.addUserAction('Form Submission', {
      success: true,
      formName,
      inputs,
    });
  } else {
    datadogRum.addUserAction('Form Submission', {
      success: false,
      formName,
      inputs,
      errorMessages,
    });
  }
}

function handleIntegrationTestResults(merchantId: string, success: boolean, widgetVerified: boolean, integrationVerified: boolean) {
  datadogRum.addUserAction('MSS Integration Test Results', {
    success,
    merchantId,
    widgetVerified,
    integrationVerified,
  });
}

function handleWebsiteUpdated() {
  datadogRum.addUserAction('MSS Website Url Updated', {
    merchantId: authenticationStore.merchantId,
  });
}

function handleMssDeclined(declineReason: string) {
  datadogRum.addUserAction('MSS Declined', {
    declineReason,
    merchantId: authenticationStore.merchantId,
    email: authenticationStore.userInfo.email,
  });
}

function handleMssStageCompleted(stage: string, attempts: number) {
  // Attempts for return from Stripe
  // if attempts = 0 there was no counting
  // else (attempts * 10) = number of seconds it took to complete
  datadogRum.addUserAction('MSS Stage Completed',
    { merchantId: authenticationStore.merchantId, stage, attempts });
}

function handleTrackAction(actionName: string, actionInfo?: Record<string, any>) {
  if (actionInfo) datadogRum.addUserAction(`Action ${actionName}`, { ...actionInfo });
  else datadogRum.addUserAction(`Action ${actionName}`, {});
}
function handleTrackClick(itemClicked: string, itemInfo?: Record<string, any>) {
  if (itemInfo) {
    datadogRum.addUserAction(`Click ${itemClicked}`, { ...itemInfo });
  }
  else datadogRum.addUserAction(`Click ${itemClicked}`, {});
}

function handleVerificationEmailResent() {
  datadogRum.addUserAction('Verification Email Resent', { merchantId: authenticationStore.merchantId });
}

export default {
  /**
   * Load datadog RUM and register all of the event consumers.
   */
  load() {
    initialize();

    eventBus.subscribeAuthenticatedEvent(handleAuthenticated);
    eventBus.subscribeEmailVerificationEvent(handleEmailVerification);
    eventBus.subscribeErrorEvent(handleError);
    eventBus.subscribeExistingUserEvent(handleExistingUser);
    eventBus.subscribeFormSubmissionEvent(handleFormSubmission);
    eventBus.subscribeIntegrationTestResultsEvent(handleIntegrationTestResults);
    eventBus.subscribeWebsiteUpdatedEvent(handleWebsiteUpdated);
    eventBus.subscribeMssDeclinedEvent(handleMssDeclined);
    eventBus.subscribeMssStageCompletedEvent(handleMssStageCompleted);
    eventBus.subscribeTrackActionEvent(handleTrackAction);
    eventBus.subscribeTrackClickEvent(handleTrackClick);
    eventBus.subscribeVerificationEmailResentEvent(handleVerificationEmailResent);

    logger.info('Successfully loaded Datadog!');
  }
};
