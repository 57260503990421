










import eventBus from '@/event-bus';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SimpleView from '../layouts/SimpleView.vue';

@Component({
  components: {
    'simple-view': SimpleView,
  }
})
export default class WhatsNew extends Vue {
  @Prop({ required: true })
  title: string;
  @Prop({ required: true })
  body: string;
  
  @Prop({ required: false })
  trackingEventName: string;
  
  mounted() {
    if (this.$refs['updateBody']) {
      const ref = this.$refs['updateBody'] as HTMLElement;
      ref.getElementsByTagName('a').item(0)?.addEventListener('click', () => {
        eventBus.publishTrackClickEvent('Updates Page', {
          title: this.trackingEventName,
          updateTitle: this.title
        });
      });
    }
  }
}
