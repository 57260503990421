





























import { Component, Prop, Vue } from 'vue-property-decorator';
import ActionButton from '../controls/ActionButton.vue';

@Component({
  components: {
    'action-button': ActionButton,
  }
})
export default class ExportMenu extends Vue {
  @Prop({ required: true })
  menuList: { title: string, exportFunction: any }[];
  @Prop({ required: true })
  csvLoading: boolean;

  get onlyOneExportOption(): boolean {
    return this.menuList.length === 1;
  }
}
