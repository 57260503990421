export interface OrderSearchOptions {
  page: number;
  pageSize: number;
  orderBy: 'dateAsc' | 'dateDesc';
  text?: string;
  minimumCreateDateTime: Date | string;
  maximumCreateDateTime: Date | string;
}

export interface OrderSearchResult {
  value: OrderSearchObject[];
  total: number;
}

export interface OrderSearchObject {
  id: string;
  amount: string;
  createdDateTime: Date;
  customerFirstName: string;
  customerLastName: string;
  orderNumber: string;
  merchantId: string;
  merchantReference: string;
  merchantPlatformId: string;
}

export interface Order {
  id?: string;
  merchantId?: string;
  customerId?: string;
  customer?: Customer;
  amount?: 0;
  currencyCode?: string;
  billingAddress?: Address;
  createdDateTime?: Date;
  lineItems?: LineItem[];
  merchantReference?: string;
  merchantPlatformId?: string;
  orderNumber?: string;
  orderStatus?: OrderStatusEnum;
  refunds?: Refund[];
  shippingAddress?: Address;
  shippingAmount?: number;
  taxAmount?: number;
  totalCaptured?: number;
  totalRefunded?: number;
  totalVoided?: number;
  hasDisputedPayment?: boolean;
}

export interface Customer {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string
}

export interface Address {
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  postCode?: string;
  country?: string
}

export interface LineItem {
  id?: string;
  name?: string;
  price?: 0;
  quantity?: 0;
  sku?: string
}

export enum OrderStatusEnum {
  Created = 'created',
  Approved = 'approved',
  Declined = 'declined',
  Abandoned = 'abandoned',
}

export interface Refund {
  amount: 0;
  merchantRefundReference: string;
  refundDateTime: Date;
}

export interface RefundCommand {
  orderId: string;
  merchantReference: string;
  amount: number;
  currencyCode: string;
}