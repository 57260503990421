var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('search-view',{staticClass:"statements-page",scopedSlots:_vm._u([{key:"search-options",fn:function(){return [_c('div',{staticClass:"qp-flex-center-center search-options"},[(!_vm.firstLoad)?_c('v-combobox',{staticClass:"filter",attrs:{"id":"qp-status-filter","clearable":"","outlined":"","hide-details":"","text":"","items":_vm.paymentStatusItems,"label":_vm.statusFilterText,"item-text":"text","item-value":"value"},on:{"change":_vm.onStatusFilterChanged},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('search-icon')]},proxy:true}],null,false,2006118827),model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}):_vm._e(),_c('div',{staticClass:"date-picker"},[_c('v-form',{ref:"form"},[_c('double-date-picker',{ref:"dateRange",attrs:{"locale":_vm.currentLocale},on:{"startDateUpdate":function($event){return _vm.startDateUpdated($event)},"endDateUpdate":function($event){return _vm.endDateUpdated($event)}}})],1)],1)],1)]},proxy:true}])},[_c('div',[(_vm.firstLoad)?_c('v-skeleton-loader',{attrs:{"id":"qp-statements-table-skeleton-loader","type":"table-tbody","loading":_vm.loading}}):_vm._e(),(!_vm.firstLoad)?_c('v-data-table',{attrs:{"id":"qp-statements-table","headers":_vm.tableHeaders,"loading":_vm.loading,"items":_vm.statementArray,"item-key":"rowId","options":_vm.tableOptions,"footer-props":_vm.footerOptions,"hide-default-footer":!_vm.statementCount,"no-data-text":_vm.$t('statements.no-statements-a'),"server-items-length":_vm.statementCount},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.navigateStatement},scopedSlots:_vm._u([(_vm.notificationFeatureFlag)?{key:"header.openDate",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"color":"transparent","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',{staticClass:"transaction-tooltip"},[_c('span',{staticClass:"tooltip-pointer"}),_vm._v(" "+_vm._s(_vm.$t('statements.open-date-tooltip'))+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t('statements.utc-tooltip'))+" ")])])]}}:null,(_vm.notificationFeatureFlag)?{key:"header.closeDate",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"color":"transparent","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',{staticClass:"transaction-tooltip"},[_c('span',{staticClass:"tooltip-pointer"}),_vm._v(" "+_vm._s(_vm.$t('statements.close-date-tooltip'))+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t('statements.utc-tooltip'))+" ")])])]}}:null,(_vm.notificationFeatureFlag)?{key:"header.payoutDate",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"color":"transparent","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',{staticClass:"transaction-tooltip"},[_c('span',{staticClass:"tooltip-pointer"}),_vm._v(" "+_vm._s(_vm.$t('statements.payment-date-tooltip'))+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.$t('statements.utc-tooltip'))+" ")])])]}}:null,{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.navigateStatement(item)}}},[_vm._v(_vm._s(_vm.displayInvoiceNumber(item)))])]}},{key:"item.openDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.displayDateUtc(item.openDate)))]}},{key:"item.closeDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.displayDateUtc(item.closeDate)))]}},{key:"item.payment",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.displayPaymentStatusString(item.payment)))]}},{key:"item.payoutDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.displayDateUtc(item.payoutDate)))]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'qp-primary-text': item.amount > 0 }},[_vm._v(_vm._s(_vm.$n(item.amount, 'currency')))])]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"link"},[_c('statement-csv-dialog',{attrs:{"invoiceDetail":item,"mode":'embedded'}})],1)]}},{key:"footer.page-text",fn:function(props){return [_c('table-footer',{attrs:{"tableOptions":_vm.tableOptions,"itemsLength":props.itemsLength},on:{"paginate":function($event){return _vm.paginate($event)}}})]}}],null,true)},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"qp-no-data-container qp-flex-center-center qp-flex-col"},[_c('invoices-icon',{staticClass:"qp-no-data-icon"}),_c('h2',{staticClass:"minus qp-info-text"},[_vm._v(_vm._s(_vm.$t('statements.no-statements-a')))]),_c('h2',{staticClass:"minus qp-info-text"},[_vm._v(_vm._s(_vm.$t('statements.no-statements-b')))]),_c('h4',{staticClass:"minus qp-grey-quaternary-text"},[_vm._v(_vm._s(_vm.$t('statements.no-statements-description')))])],1)])],2):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }