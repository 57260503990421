






import BasicLayout from '@/components/layouts/BasicLayout.vue';
import Payments from '@/components/payments/Payments.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'basic-layout': BasicLayout,
    'payments': Payments,
  }
})
export default class PaymentsPage extends Vue {}
