































































































import SearchIcon from '@/assets/images/search.svg?inline';
import DoubleDatePicker from '@/components/controls/DoubleDatePicker.vue';
import TransactionsIcon from '@/assets/images/icons/sidebar/TransactionsIconLarge.svg?inline';
import configuration from '@/configuration';
import moment from 'moment-timezone';
import { TranslateResult } from 'vue-i18n';
import { OrderStatusEnum } from '@/store/order/order-models';
import { Transaction } from '@/store/transaction/transaction-models';
import { applicationStore, authenticationStore, transactionStore } from '@/store/store';
import { DataOptions, DataTableHeader } from 'vuetify';
import { Component, Watch, Vue } from 'vue-property-decorator';
import TableFooter from '../controls/TableFooter.vue';
import TimezoneNotice from '../common/TimezoneNotice.vue';
import SearchView from '../layouts/SearchView.vue';
import { toLocalDateTime } from '@/datetime';

@Component({
  components: {
    'search-view': SearchView,
    'double-date-picker': DoubleDatePicker,
    'transactions-icon': TransactionsIcon,
    'search-icon': SearchIcon,
    'table-footer': TableFooter,
    'timezone-notice': TimezoneNotice
  }
})
export default class Transactions extends Vue {
  $refs!: {
    form: HTMLFormElement;
    dateRange: DoubleDatePicker;
  };
  // Allow skeleton loader to appear before table finishes loading (the first load only)
  firstLoad = true;
  loading = true;
  csvLoading = false;
  searchInput = '';
  tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['createdDateTime'],
    sortDesc: [true], // default date desc
    groupBy: [],
    groupDesc: [],
    multiSort: false, // ability to sort by more than one column at once
    mustSort: true, // table must be sorted by at least one column
  };
  startDate = moment.utc().startOf('month').toISOString();
  endDate = moment.utc().endOf('month').toISOString();
  queryStartDate?: string = undefined;
  startFilterOff = false;
  endFilterOff = false;
  defaultDateFilter = true;

  get companyName(): string {
    return configuration.company.name;
  }

  get notificationFeatureFlag(): boolean {
    return configuration.featureFlags.transactionNotification;
  }

  get footerOptions(): any {
    return TableFooter.getDefaultOptions(key => this.$t(key));
  }

  get transactionArray(): Transaction[] {
    return transactionStore.transactionArray;
  }

  get transactionCount(): number {
    return transactionStore.transactionCount;
  }

  get tableHeaders(): DataTableHeader[] {
    const vatColumns: DataTableHeader[] = [
      {
        text: this.$t('transactions.vat-amount').toString(),
        value: 'vatAmount',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('transactions.gross-amount').toString(),
        value: 'grossAmount',
        sortable: false,
        align: 'center',
      },
    ];

    return [
      {
        text: this.$t('transactions.date').toString(),
        value: 'createdDateTime',
      },
      {
        text: this.$t('transactions.reference').toString(),
        value: 'reference',
        sortable: false,
      },
      {
        text: this.$t('transactions.orderNumber').toString(),
        value: 'orderNumber',
        sortable: false,
      },
      {
        text: this.$t('transactions.transaction-type').toString(),
        value: 'merchantTransactionTypeDisplay',
        sortable: false,
      },
      {
        text: this.$t('transactions.net-amount').toString(),
        value: 'amount',
        sortable: false,
        align: 'center',
      },
      ...(configuration.isVatEnabled ? vatColumns : []),
      {
        text: this.$t('transactions.payment-date').toString(),
        value: 'paymentDate',
        sortable: false,
      },
    ];
  }

  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  get currentIdentity() {
    return authenticationStore.currentIdentity;
  }
  
  get searchText(): TranslateResult {
    return this.$t('transactions.search');
  }

  displayDate(date: Date): string {
    return toLocalDateTime(date);
  }

  displayDateUtc(date: Date): string {
    if(date){
      return moment(date).locale(this.currentLocale).format(configuration.dateFormat);
    }
    return '';
  }

  @Watch('currentIdentity')
  async updateSearch() {
    // Update transactions list as identity changes
    await this.searchTransactions();
  }

  @Watch('tableOptions')
  async updateTable() {
    await this.searchTransactions();
  }

  async paginate(newPage) {
    this.tableOptions.page = newPage;
    await this.searchTransactions();
  }

  onSearch() {
    if (this.defaultDateFilter) {
      this.startFilterOff = true;
      this.endFilterOff = true;
    }

    this.searchTransactions(1);
  }

  async searchTransactions(pageNumber: number|null = null) {
    this.loading = true;
    this.tableOptions.page = pageNumber || this.tableOptions.page;
    const { page, itemsPerPage, sortDesc } = this.tableOptions;

    await transactionStore.searchTransactions({
      text: this.searchInput,
      page,
      pageSize: itemsPerPage,
      orderBy: sortDesc[0] ? 'dateDesc' : 'dateAsc',
      orderStatuses: [
        OrderStatusEnum.Created,
        OrderStatusEnum.Approved,
        OrderStatusEnum.Declined,
        OrderStatusEnum.Abandoned,
      ],
      minimumCreateDateTime: this.startFilterOff ? '' : this.startDate,
      maximumCreateDateTime: this.endFilterOff ? '' : this.endDate,
    });
    this.loading = false;
    // Must use history push to ensure the page is not reloaded
    history.pushState({}, '', `?page=${this.tableOptions.page}&pageSize=${this.tableOptions.itemsPerPage}&desc=${this.tableOptions.sortDesc[0]}`);
  }

  async startDateUpdated(newDate: string) {
    this.startFilterOff = false;
    this.defaultDateFilter = false;
    this.startDate = moment.utc(newDate, "YYYY-MM-DD").startOf("day").toISOString();
    await this.searchTransactions(1);
  }

  async endDateUpdated(newDate: string) {
    this.endFilterOff = false;
    this.defaultDateFilter = false;
    this.endDate = moment.utc(newDate, "YYYY-MM-DD").endOf("day").toISOString();
    await this.searchTransactions(1);
  }

  async mounted() {
    // Check query params
    if (this.$route.query.page && !isNaN(parseInt(this.$route.query.page as string, 10))) this.tableOptions.page = parseInt(this.$route.query.page as string, 10);
    if (this.$route.query.pageSize && !isNaN(parseInt(this.$route.query.pageSize as string, 10))) this.tableOptions.itemsPerPage = parseInt(this.$route.query.pageSize as string, 10);
    if (this.$route.query.desc) this.tableOptions.sortDesc[0] = this.$route.query.desc === 'true';

    // Disable skeleton loader
    this.firstLoad = false;
  }
}
