var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.shouldShowSection)?_c('dispute-details-section',{attrs:{"id":"shipping-details"}},[_c('h4',[_vm._v(" "+_vm._s(_vm.$t("disputeFormShippingSection.shipping-details"))+" ")]),_c('div',{staticClass:"root-container"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('disputeFormShippingSection.shipping-address'))+" "),(_vm.editable)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("$info-icon")])]}}],null,false,3297115475)},[_c('span',[_vm._v(_vm._s(_vm.$t('disputeFormShippingSection.shipping-address-tooltip')))])]):_vm._e()],1),_c('dispute-evidence-address',{attrs:{"disabled":!_vm.editable,"copyFromAddressFunc":function () { return _vm.evidence.billingAddress; },"copyFromAddressType":_vm.billingAddressType},model:{value:(_vm.shippingAddress),callback:function ($$v) {_vm.shippingAddress=$$v},expression:"shippingAddress"}}),_c('v-row',{staticStyle:{"margin":"1rem -12px"}},[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('label',{staticClass:"field-label"},[_vm._v(" "+_vm._s(_vm.$t("disputeFormShippingSection.tracking-number-header"))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("$info-icon")])]}}],null,false,3297115475)},[_c('span',[_vm._v(_vm._s(_vm.$t("disputeFormShippingSection.tracking-number-header-tooltip")))])])],1),_c('v-text-field',{attrs:{"disabled":!_vm.editable,"hide-details":true,"outlined":""},model:{value:(_vm.shippingTrackingNumber),callback:function ($$v) {_vm.shippingTrackingNumber=$$v},expression:"shippingTrackingNumber"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('label',{staticClass:"field-label with-tooltip"},[_vm._v(" "+_vm._s(_vm.$t("disputeFormShippingSection.tracking-carrier"))+" ")]),_c('v-text-field',{attrs:{"disabled":!_vm.editable,"hide-details":true,"outlined":""},model:{value:(_vm.shippingCarrier),callback:function ($$v) {_vm.shippingCarrier=$$v},expression:"shippingCarrier"}})],1)],1),_c('label',[_vm._v(" "+_vm._s(_vm.$t('disputeFormShippingSection.shipping-date'))+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("$info-icon")])]}}],null,false,3297115475)},[_c('span',[_vm._v(_vm._s(_vm.$t("disputeFormShippingSection.shipping-date-tooltip")))])])],1),_c('v-text-field',{attrs:{"disabled":!_vm.editable,"hide-details":true,"outlined":""},model:{value:(_vm.shippingDate),callback:function ($$v) {_vm.shippingDate=$$v},expression:"shippingDate"}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }