var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"outerTable",attrs:{"id":"qp-statements-table","expanded":_vm.expandedRows,"headers":_vm.tableHeaders,"items":_vm.details.items,"item-key":"invoiceTransactionType","options":_vm.tableOptions,"hide-default-footer":true,"no-data-text":_vm.$t('statementDetails.no-transactions-a'),"server-items-length":_vm.details.items.length,"hide-default-header":!_vm.isMobile},on:{"update:expanded":function($event){_vm.expandedRows=$event},"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.onRowClicked},scopedSlots:_vm._u([(!_vm.isMobile)?{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value,class:[header.align === 'end' ? 'text-end' : 'text-start', header.class],style:(("width:" + (header.width)))},[(header.value === 'toggle')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"qp-embedded-btn collapse-btn",attrs:{"icon":"","text":"","dark":""},on:{"click":function($event){_vm.allRowsCollapsed() ? _vm.expandRows() : _vm.collapseRows()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.allRowsCollapsed() ? _vm.expandAllIcon : _vm.collapseAllIcon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.allRowsCollapsed() ? _vm.$t('statementDetails.expand-all') : _vm.$t('statementDetails.collapse-all')))])]):_vm._e(),(header.value !== 'toggle')?_c('span',{staticClass:"zip-confidence-text"},[_vm._v(_vm._s(header.text))]):_vm._e()],1)}),0)])]}}:null,{key:"item.toggle",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"toggle-icon"},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.isOpen(item) ? _vm.collapseIcon : _vm.expandIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isOpen(item) ? _vm.$t('statementDetails.collapse') : _vm.$t('statementDetails.expand')))])])]}},{key:"item.invoiceTransactionType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"qp-bold-text nowrap description"},[_vm._v(" "+_vm._s(_vm.displayTransactionTypeDescription(item.invoiceTransactionType))+" ")])]}},{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$t('statementDetails.ids', { count: _vm.getLineItemOrderCount(item) }))+" ")])]}},(_vm.showTax)?{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[_vm.isTransactionTypeRelevant(item.invoiceTransactionType) ? 'qp-primary-text' : 'zip-grey-text']},[_vm._v(" "+_vm._s(_vm.$n(item.amount, 'currency'))+" ")])]}}:null,(_vm.showTax)?{key:"item.taxAmount",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[_vm.isTransactionTypeRelevant(item.invoiceTransactionType) ? 'qp-primary-text' : 'zip-grey-text']},[_vm._v(" "+_vm._s(("" + (item.taxAmount ? _vm.$n(item.taxAmount, 'currency') : '-')))+" ")])]}}:null,{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',{class:[_vm.isTransactionTypeRelevant(item.invoiceTransactionType) ? 'qp-primary-text' : 'zip-grey-text', 'qp-bold-text']},[_vm._v(" "+_vm._s(_vm.$n(item.amount + (item.taxAmount ? item.taxAmount : 0), 'currency'))+" ")])]}},(!_vm.isMobile)?{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return _vm._l((headers),function(header,parent_index){return _c('td',{key:parent_index},[_c('statement-transaction-orders',{attrs:{"details":_vm.details,"header":header,"item":item,"showTax":_vm.showTax,"isTransactionTypeRelevant":_vm.isTransactionTypeRelevant}})],1)})}}:{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('statement-transaction-orders',{attrs:{"details":_vm.details,"item":item,"showTax":_vm.showTax,"isTransactionTypeRelevant":_vm.isTransactionTypeRelevant}})]}},(!_vm.isMobile)?{key:"body.append",fn:function(ref){
var headers = ref.headers;
return _vm._l((headers),function(header,parent_index){return _c('td',{key:'footer-column-' + parent_index},[_c('statement-transaction-totals',{attrs:{"details":_vm.details,"header":header,"showTax":_vm.showTax}})],1)})}}:{key:"body.append",fn:function(ref){return [_c('statement-transaction-totals',{attrs:{"details":_vm.details,"showTax":_vm.showTax}})]}}],null,true)},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"qp-no-data-container qp-flex-center-center qp-flex-col"},[_c('invoices-icon',{staticClass:"qp-no-data-icon"}),_c('h2',{staticClass:"minus qp-info-text"},[_vm._v(_vm._s(_vm.$t('statementDetails.no-transactions-a')))]),_c('h2',{staticClass:"minus qp-info-text"},[_vm._v(_vm._s(_vm.$t('statementDetails.no-transactions-b')))])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }