










import eventBus from '@/event-bus';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SidebarNotification extends Vue {
  @Prop({ required: true })
  routeToDisplay: string;
  @Prop({ required: true })
  title: string;
  @Prop({ required: true })
  body: string;
  @Prop({ required: false })
  trackingEventName: string;

  mounted() {
    if (this.$refs['notificationBody']) {
      const ref = this.$refs['notificationBody'] as HTMLElement;
      ref.getElementsByTagName('a').item(0)?.addEventListener('click', () => {
        eventBus.publishTrackClickEvent('Sidebar Notification', { title: this.trackingEventName });
      });
    }

  }
}
