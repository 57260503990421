




import i18n from '@/i18n';
import { DisputeReconciledStatusEnum } from '@/store/dispute/dispute-models';
import { TranslateResult } from 'vue-i18n';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class DisputeStatus extends Vue {
  @Prop({ required: true })
  status: DisputeReconciledStatusEnum;

  get displayText() {
    return DisputeStatus.getStatusString(this.status);
  }

  static getStatusString(status?: DisputeReconciledStatusEnum): TranslateResult | string {
    switch (status) {
      case DisputeReconciledStatusEnum.lost:
        return i18n.t('disputeStatus.status-lost');
      case DisputeReconciledStatusEnum.closed:
        return i18n.t('disputeStatus.status-lost-accepted');
      case DisputeReconciledStatusEnum.needsResponse:
        return i18n.t('disputeStatus.status-needs-response');
      case DisputeReconciledStatusEnum.underReview:
        return i18n.t('disputeStatus.status-under-review');
      case DisputeReconciledStatusEnum.won:
        return i18n.t('disputeStatus.status-won');
      case DisputeReconciledStatusEnum.wonPartially:
        return i18n.t('disputeStatus.status-won-partially');
      default:
        return '';
    }
  }
}
