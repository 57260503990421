
















import { Component, Vue, Prop } from 'vue-property-decorator';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';

@Component({
    components: {
        'dispute-details-section': DisputeDetailsSection
    },
})
export default class DisputeCollectEvidenceHeaderSection extends Vue {
    @Prop({ required: true })
    editable: boolean;
}
