










import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';

@Component
export default class TableFooter extends Vue {
  @Prop({ required: true })
  tableOptions: DataOptions;
  @Prop({ required: true })
  itemsLength: number;

  onPageNumberChange(newPage) {
    if (isNaN(newPage) || newPage === null || newPage === undefined || newPage === '') {
      this.$emit('paginate', 1);
      return;
    }
    newPage = parseInt(newPage, 10);

    const maxPages = Math.ceil(this.itemsLength/this.tableOptions.itemsPerPage);

    if (newPage <= 0) {
      newPage = 1;
    } else if(newPage > maxPages) {
      newPage = maxPages;
    }

    this.$emit('paginate', newPage);
  }

  static getDefaultOptions(translate: (key: string) => any): any {
    // Data type for footer props is not available
    // See https://vuetifyjs.com/en/components/data-iterators/#api (must choose v-data-footer)
    return {
      'items-per-page-options': [10, 25, 50, 100],
      'items-per-page-text': translate('tableFooter.items-per-page'),
    };
  }
}
