











































































import { disputeStore } from "@/store/store";
import { Component, Vue } from "vue-property-decorator";
import ConfirmationBox from "../common/ConfirmationBox.vue";
import DisputeSummaryPanel from "./details/components/DisputeSummaryPanel.vue";
import DisputeDetailsSummaryHeaderSection from "./details/summary/DisputeDetailsSummaryHeaderSection.vue";
import DisputeDetailsSummaryStepsSection from "./details/summary/DisputeDetailsSummaryStepsSection.vue";
import DisputeDetailsSummaryActionsSection from "./details/summary/DisputeDetailsSummaryActionsSection.vue";
import DisputeDetailsSummaryTitle from "./details/summary/DisputeDetailsSummaryTitle.vue";
import DisputeDetailsSectionContainer from "./details/DisputeDetailsSectionContainer.vue";
import DisputeProductServiceSection from "./details/form/DisputeProductServiceSection.vue";
import DisputeStartResponseHeaderSection from "./details/form/DisputeStartResponseHeaderSection.vue";
import DisputeCollectEvidenceHeaderSection from "./details/form/DisputeCollectEvidenceHeaderSection.vue";
import DisputeSupportingEvidenceFilesSection from "./details/form/DisputeSupportingEvidenceFilesSection.vue";
import DisputeCustomerDetailsSection from "./details/form/DisputeCustomerDetailsSection.vue";
import DisputeShippingDetailsSection from "./details/form/DisputeShippingDetailsSection.vue";
import DisputeRefundSection from "./details/form/DisputeRefundSection.vue";
import DisputeCancellationSection from "./details/form/DisputeCancellationSection.vue";
import DisputeServiceDetailsSection from "./details/form/DisputeServiceDetailsSection.vue";
import DisputeDigitalServiceDetailsSection from "./details/form/DisputeDigitalServiceDetailsSection.vue";
import DisputeSubmissionSection from "./details/form/DisputeSubmissionSection.vue";
import DisputeEvidenceFormStageHeader from "./details/form/DisputeEvidenceFormStageHeader.vue";
import DisputeServiceUnavailableWarning from "./details/components/DisputeServiceUnavailableWarning.vue";
import {
    DisputeEvidenceEvent,
    DisputeEvidenceFormStageEnum,
} from "./details/models";
import {
    DisputeEvidence,
    DisputeProductServiceTypeEnum,
    DisputeReconciledStatusEnum,
} from "@/store/dispute/dispute-models";
import { getCategoryStrings } from "@/store/dispute/dispute-store";

@Component({
    components: {
        "dispute-service-unavailable-warning": DisputeServiceUnavailableWarning,
        "dispute-details-summary-header-section": DisputeDetailsSummaryHeaderSection,
        "dispute-details-summary-steps-section": DisputeDetailsSummaryStepsSection,
        "dispute-details-summary-actions-section": DisputeDetailsSummaryActionsSection,
        "dispute-details-summary-title": DisputeDetailsSummaryTitle,
        "dispute-details-section-container": DisputeDetailsSectionContainer,
        "confirmation-box": ConfirmationBox,
        "dispute-summary-panel": DisputeSummaryPanel,
        "dispute-product-service-section": DisputeProductServiceSection,
        "dispute-start-response-header-section": DisputeStartResponseHeaderSection,
        "dispute-collect-evidence-header-section": DisputeCollectEvidenceHeaderSection,
        "dispute-supporting-evidence-files-section": DisputeSupportingEvidenceFilesSection,
        "dispute-customer-details-section": DisputeCustomerDetailsSection,
        "dispute-shipping-details-section": DisputeShippingDetailsSection,
        "dispute-refund-section": DisputeRefundSection,
        "dispute-cancellation-section": DisputeCancellationSection,
        "dispute-service-details-section": DisputeServiceDetailsSection,
        "dispute-digital-service-details-section": DisputeDigitalServiceDetailsSection,
        "dispute-submission-section": DisputeSubmissionSection,
        "dispute-evidence-form-stage-header": DisputeEvidenceFormStageHeader,
    },
})
export default class DisputeDetails extends Vue {
    // local states
    acceptDialogVisible = false;
    unsavedDialogVisible = false;
    showForm = false;
    currentStage = DisputeEvidenceFormStageEnum.startResponse;
    valid = true;
    evidence: DisputeEvidence = {};
    nextFunc = () => false;

    // Properties
    get dispute() {
        return disputeStore.disputeDetails;
    }

    get hasSubmittedEvidence() {
        return this.dispute.evidenceSubmittedOn || this.hasEvidenceFiles;
    }

    // Note: it is unfortunately necessary to check for the existence of uploaded evidence files in the case that a dispute was resolved via the stripe portal
    get hasEvidenceFiles() {
        for (const catStr of getCategoryStrings()) {
            if (this.dispute.evidence && this.dispute.evidence[catStr]) {
                return true;
            }
        }
        return false;
    }

    get editable() {
        return this.dispute && this.dispute.status === DisputeReconciledStatusEnum.needsResponse && !this.dispute.paymentProcessorUnavailable;
    }

    get stageEnum() {
        return DisputeEvidenceFormStageEnum;
    }

    get loading() {
        return disputeStore.loadingDisputeDetails || disputeStore.loadingDisputeEvidence;
    }

    get shouldShowCollectEvidenceForm() {
        return this.currentStage === DisputeEvidenceFormStageEnum.collectEvidence || !this.editable;
    }

    // Functions
    showAcceptDialog() {
        this.acceptDialogVisible = true;
    }

    hideAcceptDialog() {
        this.acceptDialogVisible = false;
    }

    showCollectEvidenceForm({ description, productServiceType, }: { description: string; productServiceType: DisputeProductServiceTypeEnum; }) {
        this.validate();
        if (this.valid) {
            this.evidence.uncategorizedText = description;
            this.evidence.productServiceType = productServiceType;
            this.currentStage = DisputeEvidenceFormStageEnum.collectEvidence;
            window.scrollTo(0, 0);
        }
    }

    validate() {
        this.valid = (this.$refs.form as HTMLFormElement).validate();
        return this.valid;
    }

    syncEvidence(filesOnly: boolean) {
        const de = disputeStore.disputeDetails.evidence;
        if (de) {
            if (filesOnly) {
                // Sync files only, keep all other fields
                const current = { ...this.evidence };
                for (const category of getCategoryStrings()) {
                    current[category] = de[category];
                }
                this.evidence = current;
            } else {
                // Replace the entire object with the one from store
                this.evidence = de;
            }
        } else {
            // Hmmmm.... reset?
            this.evidence = {};
        }
    }

    // Events
    onBeforeRouteLeave(to, from, next) {
        if (!this.showForm || !this.editable) {
            return true;
        }
        this.nextFunc = next;
        this.unsavedDialogVisible = true;
        return false;
    }

    onStay() {
        this.unsavedDialogVisible = false;
    }

    onDiscard() {
        this.unsavedDialogVisible = false;
        this.nextFunc(); // continue navigation
    }

    onCounterButtonClick() {
        this.showForm = true;
    }

    async onSubmit() {
        if (!this.editable) {
            return;
        }

        this.validate();

        if (this.valid) {
            await disputeStore.submitDisputeEvidence({
                disputeId: this.dispute.id,
                evidence: this.evidence,
            });

            // Reload the evidence object
            this.syncEvidence(false);

            this.currentStage = DisputeEvidenceFormStageEnum.startResponse;
            this.showForm = false;
        }
    }

    async onCloseDispute() {
        await disputeStore.closeDispute(this.dispute.id);
        this.hideAcceptDialog();
    }

    async onSave() {
        if (!this.editable) {
            return;
        }

        await disputeStore.provideDisputeEvidence({
            disputeId: this.dispute.id,
            evidence: this.evidence,
        });

        // Reload the evidence object
        this.syncEvidence(false);
    }

    onRemotefilesChanged() {
        this.syncEvidence(true);
    }

    onEvidencePropertyChanged(evt: DisputeEvidenceEvent) {
        this.evidence[evt.propertyName] = evt.propertyValue;
        this.validate();
    }

    // Vue Setup
    async mounted() {
        if (this.dispute.evidence) {
            this.evidence = this.dispute.evidence;
        }

        if (this.evidence?.productServiceType) {
            this.currentStage = DisputeEvidenceFormStageEnum.collectEvidence;
        }

        // When loaded, we need to sync the object from store to the one in the form
        this.syncEvidence(false);
    }
}
