export interface DisputeSearchOptions {
  status?: DisputeStatusEnum;
  dateType?: DisputeDateFilterEnum,
  page: number;
  pageSize: number;
  orderBy?: string;
  startDate: Date | string;
  endDate: Date | string;
}

export interface DisputeSearchResult {
  value: Dispute[];
  total: number;
}

export interface Dispute {
  id: string;
  customerFirstName: string;
  customerLastName: string;
  paymentPlanId: string;
  orderNumber?: string;
  disputeId: string;
  reason: DisputeReasonEnum;
  status: DisputeReconciledStatusEnum;
  disputedOn: Date;
  evidenceDueOn?: Date;
  settledOn?: Date;
  disputedAmount: number;
  currencyCode: string;
  orderAmount: number;
}

export interface DisputeDetailResponse {
  disputeDetail?: DisputeDetail;
}

// Since the API at the backend is separate from the search one,
// let's separate the DTO so modifying one won't impact the other.
export interface DisputeDetail {
  id: string;
  customerFirstName: string;
  customerLastName: string;
  paymentPlanId: string;
  disputeId: string;
  reason: DisputeReasonEnum;
  status: DisputeReconciledStatusEnum;
  disputedOn: Date;
  evidenceDueOn?: Date;
  settledOn?: Date;
  acceptedOn?: Date;
  evidenceSubmittedOn?: Date;
  disputedAmount: number;
  reinstatedAmount?: number;
  currencyCode: string;
  orderAmount: number;
  isChargeRefundable: boolean;
  orderId?: string;
  orderNumber?: string;
  orderDate?: Date;
  evidence?: DisputeEvidence;
  issuerEvidence?: DisputeIssuerEvidence[];
  customerEmail?: string;
  disputeFeeAmount?: number;
  paymentProcessorUnavailable?: boolean;
}

export interface DisputeIssuerEvidence {
  /// Type of issuer evidence supplied.
  evidenceType: DisputeIssuerEvidenceTypeEnum;

  /// Free-form, text-based issuer evidence.
  textEvidence?: string;

  /// List of up to 5 (ID of a file upload) File-based issuer evidence.
  fileEvidence?: DisputeEvidenceFile[];
}

// Sorry Dan, happy hours ended and this has gone back to normal...  :D
export enum DisputeProductServiceTypeEnum {
  unspecified = 0,
  physicalProduct,
  digitalProductOrService,
  offlineService,
  eventBookingOrReservation,
  other,
}

export interface DisputeEvidence {
  // Summary:
  //     Any receipt or message sent to the customer
  //     notifying them of the charge.
  receipt?: DisputeEvidenceFile;

  // Summary:
  //     Your refund policy, as shown to the customer.
  refundPolicy?: DisputeEvidenceFile;

  // Summary:
  //     Documentation demonstrating that the customer was shown your refund policy prior
  //     to purchase.
  refundPolicyDisclosure?: string;

  // Summary:
  //     A justification for why the customer is not entitled to a refund.
  refundRefusalExplanation?: string;

  // Summary:
  //     The date on which the customer received or began receiving the purchased service,
  //     in a clear human-readable format.
  serviceDate?: string;

  // Summary:
  //     Documentation showing proof that a service was
  //     provided to the customer. This could include a copy of a signed contract, work
  //     order, or other form of written agreement. For more information, see the expand
  //     documentation.
  serviceDocumentation?: DisputeEvidenceFile;

  // Summary:
  //     The address to which a physical product was shipped. You should try to include
  //     as complete address information as possible.
  shippingAddress?: string;

  // Summary:
  //     The delivery service that shipped a physical product, such as Fedex, UPS, USPS,
  //     etc. If multiple carriers were used for this purchase, please separate them with
  //     commas.
  shippingCarrier?: string;

  // Summary:
  //     The date on which a physical product began its route to the shipping address,
  //     in a clear human-readable format.
  shippingDate?: string;

  // Summary:
  //     Documentation showing proof that a product was
  //     shipped to the customer at the same address the customer provided to you. This
  //     could include a copy of the shipment receipt, shipping label, etc. It should
  //     show the customer's full shipping address, if possible. For more information,
  //     see the expand documentation.
  shippingDocumentation?: DisputeEvidenceFile;

  // Summary:
  //     The tracking number for a physical product, obtained from the delivery service.
  //     If multiple tracking numbers were generated for this purchase, please separate
  //     them with commas.
  shippingTrackingNumber?: string;

  // Summary:
  //     Any additional evidence or statements.
  uncategorizedFile?: DisputeEvidenceFile;

  // Summary:
  //     A description of the product or service that was sold.
  productDescription?: string;

  // Summary:
  //     An explanation of the difference between the disputed charge versus the prior
  //     charge that appears to be a duplicate.
  duplicateChargeExplanation?: string;

  // Summary:
  //     Any server or activity logs showing proof that the customer accessed or downloaded
  //     the purchased digital product. This information should include IP addresses,
  //     corresponding timestamps, and any detailed recorded activity.
  accessActivityLog?: string;

  // Summary:
  //     The billing address provided by the customer.
  billingAddress?: string;

  // Summary:
  //     Your subscription cancellation policy, as shown
  //     to the customer.
  cancellationPolicy?: DisputeEvidenceFile;

  // Summary:
  //     An explanation of how and when the customer was shown your refund policy prior
  //     to purchase.
  cancellationPolicyDisclosure?: string;

  // Summary:
  //     A justification for why the customer's subscription was not canceled.
  cancellationRebuttal?: string;

  // Summary:
  //     Any communication with the customer that you
  //     feel is relevant to your case. Examples include emails proving that the customer
  //     received the product or service, or demonstrating their use of or satisfaction
  //     with the product or service.
  customerCommunication?: DisputeEvidenceFile;

  // Summary:
  //     The email address of the customer.
  customerEmailAddress?: string;

  // Summary:
  //     The name of the customer.
  customerName?: string;

  // Summary:
  //     The IP address that the customer used when making the purchase.
  customerPurchaseIp?: string;

  // Summary:
  //     A relevant document or contract showing the
  //     customer's signature.
  customerSignature?: DisputeEvidenceFile;

  // Summary:
  //     Documentation for the prior charge that can
  //     uniquely identify the charge, such as a receipt, shipping label, work order,
  //     etc. This document should be paired with a similar document from the disputed
  //     payment that proves the two payments are separate.
  duplicateChargeDocumentation?: DisputeEvidenceFile;

  // Summary:
  //     The payment ID for the prior charge which appears to be a duplicate of the disputed
  //     charge.
  duplicateChargeId?: string;

  // Summary:
  //     Any additional evidence or statements.
  uncategorizedText?: string;

  // *
  // * Metadata Fields
  // *
  // Saved in metadata. User selected product / service type from UI flow.
  productServiceType?: DisputeProductServiceTypeEnum;
}

export interface DisputeEvidenceFile {
  // Summary:
  //     Unique identifier for the object.
  id: string;

  // Summary:
  //     Time at which the object was created. Measured in seconds since the Unix epoch.
  created: Date;

  // Summary:
  //     The time at which the file expires and is no longer available in epoch seconds.
  expiresAt?: Date;

  // Summary:
  //     A filename for the file, suitable for saving to a filesystem.
  filename?: string;

  // File category for dispute evidence
  // NOTE: this can be undefined if the file is for issuer evidence
  category?: DisputeEvidenceFileCategoryEnum;

  // Summary:
  //     The size in bytes of the file object.
  size?: number;

  // Summary:
  //     A user friendly title for the document.
  title?: string;

  // Summary:
  //     The type of the file returned (e.g., csv, pdf, jpg, or png).
  type?: string;
}

export enum DisputeDateFilterEnum {
  disputeDate = 'disputeDate',
  respondByDate = 'respondByDate',
  settledDate = 'settledDate',
}

export enum DisputeReasonEnum {
  bankCannotProcess = 'bankCannotProcess',
  checkReturned = 'checkReturned',
  creditNotProcessed = 'creditNotProcessed',
  customerInitiated = 'customerInitiated',
  debitNotAuthorized = 'debitNotAuthorized',

  // We don't want to show the following to the end users
  // Duplicate = 'duplicate',
  // Fraudulent = 'fraudulent',
  general = 'general',
  incorrectAccountDetails = 'incorrectAccountDetails',
  insufficientFunds = 'insufficientFunds',
  productNotReceived = 'productNotReceived',
  productUnacceptable = 'productUnacceptable',
  subscriptionCanceled = 'subscriptionCanceled',
  unrecognized = 'unrecognized',
}

export enum DisputeStatusEnum {
  // NOTE: filer some internal status values
  // warningNeedsResponse = 'warningNeedsResponse',
  // warningUnderReview = 'warningUnderReview',
  // warningClosed = 'warningClosed',
  needsResponse = 'needsResponse',
  underReview = 'underReview',
  // chargeRefunded = 'chargeRefunded',
  won = 'won',
  lost = 'lost',
}

export enum DisputeReconciledStatusEnum {
  needsResponse = 'needsResponse',
  lost = 'lost',
  // waived = 'waived',
  won = 'won',
  underReview = 'underReview',
  closed = 'closed',
  wonPartially = 'wonPartially',
  // internal = 'internal',
  // belowThreshold = 'belowThreshold'
}

export enum DisputeEvidenceFileCategoryEnum {
  receipt,
  refundPolicy,
  serviceDocumentation,
  shippingDocumentation,
  cancellationPolicy,
  customerCommunication,
  customerSignature,
  duplicateChargeDocumentation,
  uncategorizedFile,
}

export enum DisputeIssuerEvidenceTypeEnum {
  retrieval = 'retrieval',
  chargeback = 'chargeback',
  response = 'response',
}

export interface DisputeQueryOptions {
  merchantId: string;
  rowCount?: number;
  startDate?: Date | string;
  endDate?: Date | string;
}
