















import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import { merchantStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'loading-spinner': LoadingSpinner,
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
  }
})
export default class VerifyEmail extends Vue {
  // Pull query params from route
  get message(): string {
    return (this.$route.query.message || '') as string;
  }
  get success(): boolean {
    return this.$route.query.success && (this.$route.query.success as string).toLowerCase() === 'true' ? true : false;
  }
  
  mounted() {
    merchantStore.handleEmailVerification(this.success, this.message);
  }
}
