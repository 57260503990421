



































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import DoubleDatePicker from '@/components/controls/DoubleDatePicker.vue';
import moment from 'moment';
import { applicationStore, merchantStore, transactionStore } from '@/store/store';
import configuration from '@/configuration';
import { DataOptions } from 'vuetify';
import { mdiClose } from '@mdi/js';
import LoadingSpinner from './LoadingSpinner.vue';
import { mdiCheck } from '@mdi/js';
import { CsvVersion, TransactionReportInfo, TransactionSearchOptions } from '@/store/transaction/transaction-models';
import ActionButton from '../controls/ActionButton.vue';
import ErrorIcon from '@/assets/images/icons/errorIconMedium.svg?inline';
import WarningIcon from '@/assets/images/icons/warningIconMedium.svg?inline';
import { helpPageName } from '@/router';

@Component({
  components: {
    'action-button': ActionButton,
    'double-date-picker': DoubleDatePicker,
    'loading-spinner': LoadingSpinner,
    'error-icon': ErrorIcon,
    'warning-icon': WarningIcon,
  }
})
export default class CsvDialog extends Vue {
  @Prop({ required: true})
  tableOptions: DataOptions;
  @Prop({ required: true})
  startDate: string;
  @Prop({ required: true})
  endDate: string;
  $refs!: {
    form: HTMLFormElement;
    dateRange: DoubleDatePicker;
  };
  dialog = false;
  csvLoading = false;
  close = mdiClose;
  success = false;
  successIcon: string = mdiCheck;
  csvVersion = CsvVersion.V2;
  csvV2Cutoff = '2022-03-01';
  csvExportError = false;
  csvExportWarning = false;
  pagingInfo: TransactionReportInfo = { total: 0, pageLimit: 0 };

  get companyName(): string {
    return configuration.company.name;
  }

  get merchantId(): string {
    return merchantStore.merchant.id ? merchantStore.merchant.id.toUpperCase() : '';
  }

  get csvV2Enabled(): boolean {
    return configuration.featureFlags.csvV2Enabled && applicationStore.currentCountry.code.toUpperCase() === 'US';
  }

  get helpPageRouteName(): string {
    return helpPageName;
  }

  async startDateUpdated(newDate: string) {
    this.startDate = moment.utc(newDate, "YYYY-MM-DD").startOf("day").toISOString();
  }

  async endDateUpdated(newDate: string) {
    this.endDate = moment.utc(newDate, "YYYY-MM-DD").endOf("day").toISOString();
  }

  getCsvFileName() {
    const dateFormat = 'YYYY-MM-DD';
     return `${merchantStore.merchant.legalName} - ${this.companyName} ${this.$t('transactions.transactions')} `
     +`${moment.utc(this.startDate).format(dateFormat).toString()} to ${moment.utc(this.endDate).format(dateFormat).toString()}.csv`;
  }

  showDateAlert() {
    if (this.isV2Merchant() || !this.csvV2Enabled) {
      return false;
    }
    return this.csvVersion === CsvVersion.V2 && moment.utc(this.startDate) < moment.utc(this.csvV2Cutoff);
  }

  updateCsvVersion(version: string) {
    this.csvVersion = version === 'v1' ? CsvVersion.V1 : CsvVersion.V2;
  }

  isV2Merchant() {
    return configuration.featureFlags.csvV2NoDateLimitMerchants.includes(this.merchantId);
  }

  dismissErrorModal() {
    this.csvExportError = false;
  }

  dismissWarningModal() {
    this.csvExportWarning = false;
  }

  async exportToCsv() {
    // Do not allow the user to click export while already exporting
    if (this.csvLoading) return;
    this.csvLoading = true;
    this.success = false;
    
    // Arrange options
    const { page, itemsPerPage, sortDesc } = this.tableOptions;
    const searchOptions: TransactionSearchOptions = {
      text: '',
      page,
      pageSize: itemsPerPage,
      orderBy: sortDesc[0] ? 'dateDesc' : 'dateAsc',
      minimumCreateDateTime: this.startDate,
      maximumCreateDateTime: this.endDate,
      csvVersion: this.csvV2Enabled ? this.csvVersion : this.isV2Merchant() ? CsvVersion.V2 : CsvVersion.V1
    }

    // Get report paging info
    this.pagingInfo = await transactionStore.getTransactionsInfo(searchOptions);
    const truncationOccurred = this.pagingInfo.pageLimit < this.pagingInfo.total;

    // Get download url
    const csvDownloadUrl = !truncationOccurred
      ? await transactionStore.exportTransactionsCsv(searchOptions)
      : null;

    if (truncationOccurred) {
      // show a warning if the paging would result in truncated data
      this.csvExportWarning = truncationOccurred;
    } else if (!csvDownloadUrl) {
      // If the download url is undefined, csv request failed
      this.csvExportError = true;
    } else {
      // Create downloading anchor
      var downloadAnchor = document.createElement("a");
      downloadAnchor.href = csvDownloadUrl as string;
      downloadAnchor.download = this.getCsvFileName();

      // Add, use, and remove anchor
      document.body.appendChild(downloadAnchor);
      downloadAnchor.click();
      document.body.removeChild(downloadAnchor);
    }
    this.csvLoading = false;
    this.success = true;
    setTimeout(() => {
      this.dialog = false;
      this.success = false;
    }, 500);
  }
}
