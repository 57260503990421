






















import { Component, Vue } from 'vue-property-decorator';
import { contentStore, merchantStore } from '@/store/store';
import { WhatsNewItem } from '@/store/content/content-models';

import WhatsNew from './WhatsNew.vue';

@Component({
  components: {
    'whats-new-item': WhatsNew,
  }
})
export default class Updates extends Vue {
  loading = true;

  async mounted() {
    contentStore.clearWhatsNewItems();
    await merchantStore.getOnboardingMerchant();
    await contentStore.refreshWhatsNewItems();
    this.loading = false;
  }

  get hasItems(): boolean {
    return !!contentStore.whatsNewItems && contentStore.whatsNewItems.length > 0;
  }

  get whatsNewItems(): WhatsNewItem[] {
    return contentStore.whatsNewItems
  }
}
