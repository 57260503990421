



























































































import SemicolonIcon from '@/assets/images/icons/options/semicolon.svg?inline';
import CommaIcon from '@/assets/images/icons/options/comma.svg?inline';
import PipeIcon from '@/assets/images/icons/options/pipe.svg?inline';
import SingleQuoteIcon from '@/assets/images/icons/options/single-quote.svg?inline';
import DoubleQuoteIcon from '@/assets/images/icons/options/double-quote.svg?inline';
import DoubleDatePicker from '@/components/controls/DoubleDatePicker.vue';
import moment from 'moment-timezone';
import { Component,  Prop,  Vue, Watch } from 'vue-property-decorator';
import { mdiCheck } from '@mdi/js';
import configuration from '@/configuration';
import { applicationStore, authenticationStore } from '@/store/store';
import ActionButton from '../controls/ActionButton.vue';
import CheckBox from '../controls/CheckBox.vue';
import eventBus from '@/event-bus';
import { downloadFile, RequestUrl } from '@/store/store-requests';

const SegmentClickedEventName = 'CSV Export';
const SegmentAdvancedOptionsEventName = 'CSV Export Advanced Options';

@Component({
  components: {
    'checkbox': CheckBox,
    'action-button': ActionButton,
    'double-date-picker': DoubleDatePicker,
    'semicolon-icon': SemicolonIcon,
    'comma-icon': CommaIcon,
    'pipe-icon': PipeIcon,
    'single-quote-icon': SingleQuoteIcon,
    'double-quote-icon': DoubleQuoteIcon,
  }
})
export default class DownloadCsvDialog extends Vue {
  $refs!: {
    form: HTMLFormElement;
    dateRange: DoubleDatePicker;
  };

  @Prop({ required: true })
  getUrlFunc: (query: any) => RequestUrl | string;
  @Prop({ required: true })
  filename: string;
  @Prop({ required: true })
  title: string;
  @Prop({ required: true })
  dataType: string;
  @Prop({ required: false })
  epoc: Date | string;
  @Prop({ required: false })
  startDate: string;
  @Prop({ required: false })
  endDate: string;
  @Prop({ required: false })
  mode: undefined | 'normal' | 'compact' | 'embedded';

  delimiters = [
    {
      text: `${this.$t('downloadCsvDialog.delimiter-comma')}`,
      value: ',',
    },
    {
      text: `${this.$t('downloadCsvDialog.delimiter-semicolon')}`,
      value: ';',
    },
    {
      text: `${this.$t('downloadCsvDialog.delimiter-pipe')}`,
      value: '|',
    },
  ]

  quoteStyles = [
    {
      text: `${this.$t('downloadCsvDialog.quote-style-none')}`,
      desc: 'none',
      value: 0,
    },
    {
      text: `${this.$t('downloadCsvDialog.quote-style-single')}`,
      desc: 'single',
      value: 1,
    },
    {
      text: `${this.$t('downloadCsvDialog.quote-style-double')}`,
      desc: 'double',
      value: 2,
    },
  ]

  removeDateSeparators = [
    {
      text: 'YYYY-MM-DD',
      value: false,
    },
    {
      text: 'YYYYMMDD',
      value: true,
    },
  ]

  success = false;
  successIcon: string = mdiCheck;
  selectedDelimiterIndex = 0;
  selectedQuoteStyleIndex = 0;
  selectedRemoveDateSeparatorsIndex = 0;
  includeHeaders = true;
  csvLoading = false;
  showDialog = false;
  expandedState = [];
  startFilterOff = false;
  endFilterOff = false;
  defaultDateFilter = true;
  startDateState = '';
  endDateState = '';

  get companyName(): string {
    return configuration.company.name;
  }

  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  hasDetailsSlot() {
    return !!this.$slots.default;
  }

  async exportToCsv() {
    // Do not allow the user to click export while already exporting
    if (this.csvLoading) return;

    // Track event
    this.onSaveCsv();

    this.csvLoading = true;
    this.success = false;

    let query: any = {
      delimiter: this.delimiters[this.selectedDelimiterIndex].value,
      quoteStyle: this.quoteStyles[this.selectedQuoteStyleIndex].value,
      removeDateSeparators: this.removeDateSeparators[this.selectedRemoveDateSeparatorsIndex].value,
      includeHeadings: this.includeHeaders
    };

    if (!this.hasDetailsSlot()) {
      query = { ...query, from: this.startDateState, to: this.endDateState };
    }

    const success = await downloadFile(this.filename, {
      url: this.getUrlFunc(query),
      options: {
        dataType: this.dataType,
        territory: authenticationStore.currentTerritory
      }
    });
    if (!success) {
      applicationStore.triggerErrorModal(this.$t('downloadCsvDialog.export-csv-error', { companyName: this.companyName }).toString());
    }

    this.csvLoading = false;
    this.success = true;
    setTimeout(() => {
      this.showDialog = false;
      this.success = false;
    }, 500);
  }

  async startDateUpdated(newDate: string) {
    this.startDateState = moment.utc(newDate, "YYYY-MM-DD").startOf("day").toISOString();
  }

  async endDateUpdated(newDate: string) {
    this.endDateState = moment.utc(newDate, "YYYY-MM-DD").endOf("day").toISOString();
  }

  displayEpochDate(): string {
      if (this.epoc) {
          return moment(this.epoc).locale(this.currentLocale).format(configuration.dateFormat);
      }
      return '';
  }

  showDateAlert() {
    return moment.utc(this.startDateState) < moment.utc(this.epoc);
  }

  onCloseDialog() {
    this.showDialog = false;
  }

  async mounted() {
    // Update the local states. Don't mutate the props directly
    this.startDateState = this.startDate;
    this.endDateState = this.endDate;
  }

  @Watch('startDate')
  onStartDatePropChanged(newValue) {
    this.startDateState = newValue;
  }

  @Watch('endDate')
  onEndDatePropChanged(newValue) {
    this.endDateState = newValue;
  }

  // Segment Analytics
  onActivateDialog() {
    eventBus.publishTrackClickEvent(SegmentClickedEventName, { title: 'Activating Dialog' });
  }

  onSaveCsv() {
    eventBus.publishTrackClickEvent(SegmentClickedEventName, { title: 'Saving CSV' });
  }

  onToggleAdvancedOptions() {
    eventBus.publishTrackClickEvent(SegmentAdvancedOptionsEventName, { title: `${this.expandedState?.length ? 'Collapsing' : 'Expanding'} Panel` });
  }

  onToggleIncludeHeaders() {
    eventBus.publishTrackClickEvent(SegmentAdvancedOptionsEventName, { title: `Setting Include Headers to ${this.includeHeaders}` });
  }

  onChangeDelimiter(value) {
    eventBus.publishTrackClickEvent(SegmentAdvancedOptionsEventName, { title: `Setting Delimiter to ${value}` });
  }

  onChangeQuotation(value) {
    eventBus.publishTrackClickEvent(SegmentAdvancedOptionsEventName, { title: `Setting Quotation to ${value}` });
  }

  onChangeDateFormat(value) {
    eventBus.publishTrackClickEvent(SegmentAdvancedOptionsEventName, { title: `Setting Date Format to ${value}` });
  }
}
