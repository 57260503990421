






import VerifyEmail from '@/components/login/VerifyEmail.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'verify-email': VerifyEmail,
  }
})
export default class VerifyEmailPage extends Vue {}
