



















import moment from 'moment-timezone';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import configuration from '@/configuration';
import { applicationStore, merchantStore } from '@/store/store';
import DownloadCsvDialog from '../common/DownloadCsvDialog.vue';
import DisputeStore from '@/store/dispute/dispute-store';
import { DisputeDateFilterEnum } from '@/store/dispute/dispute-models';
import DisputeDateFilter from './DisputeDateFilter.vue';

@Component({
  components: {
    'download-csv-dialog': DownloadCsvDialog,
    'dispute-date-filter': DisputeDateFilter,
  }
})
export default class DisputeCsvDialog extends Vue {
  @Prop({ required: false })
  startDate: string;
  @Prop({ required: false })
  endDate: string;
  @Prop({ required: false })
  mode: undefined | 'normal' | 'compact' | 'embedded';
  @Prop({ required: true })
  dateType: DisputeDateFilterEnum;

  dateFilterLocalState = DisputeDateFilterEnum.disputeDate;

  csvV3Epoch = '2022-06-01';

  get companyName(): string {
    return configuration.company.name;
  }

  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  get dataType(): string {
    return 'disputes as CSV';
  }

  get filename() {
    const dateFormat = 'YYYY-MM-DD';
    let prefix = `${merchantStore.merchant.legalName} ${this.$t('disputeCsvDialog.csv-export')} `;
    return `${prefix} ${moment.utc(this.startDate).format(dateFormat).toString()} to ${moment.utc(this.endDate).format(dateFormat).toString()}.csv`
  }

  getCsvDownloadUrl(query: any) {
    const updatedQuery = {
      ...query,
      dateType: this.dateFilterLocalState,
    }
    return DisputeStore.getDisputesCsvByDateRangeUrl(updatedQuery);
  }

  @Watch('dateType')
  onDateFilterPropChanged(newValue: DisputeDateFilterEnum) {
    this.dateFilterLocalState = newValue;
  }

  onDateFilterChanged(value: DisputeDateFilterEnum) {
    this.dateFilterLocalState = value;
  }
}
