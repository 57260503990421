










import { Component, Prop, Vue } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs/legacy'; // legacy is used in vue 2.
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LineElement, CategoryScale, PointElement, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, LineElement, CategoryScale, LinearScale, PointElement);

@Component({
  components: {
    'Bar': Bar
  }
})
export default class BarChart extends Vue {

  /*
  Based on chartjs and its vue wrapper vue-chartjs
  for documentation about chartjs see https://www.chartjs.org/
  for documentation about vue-chartjs see https://vue-chartjs.org/
  */

  @Prop({ required: true })
  title: string;

  @Prop({ required: true })
  labels: Array<string>;

  @Prop({ required: true })
  datasets: Array<{ label: string, backgroundColor: string, data: Array<number> }>;
  
  @Prop({ required: false })
  loading: boolean
  
  chartId = 'bar-chart';
  datasetIdKey = 'label';
  width = 400;
  height = 200;
  cssClasses = '';
  chartOptions = {
    responsive: false
  }
}

