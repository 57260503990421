























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ActionButton from '../controls/ActionButton.vue';

@Component({
  components: {
    'action-button': ActionButton,
  }
})
export default class ConfirmationBox extends Vue {
  isRunningAction = false;
  displayConfirmation = false;

  @Prop({ required: true })
  show: boolean;

  @Prop({ required: false, default: null })
  dialogClass?: string | null;

  @Prop({ required: false, default: null })
  title?: string | null;

  @Prop({ required: false, default: null })
  message?: string | null;

  @Prop({ required: false, default: null })
  okText?: string | null;

  @Prop({ required: false, default: null })
  okButtonClasses?: string | null;

  @Prop({ required: false, default: null})
  cancelText?: string | null;

  @Prop({ required: true })
  confirmAction: () => Promise<any>;

  @Prop({ required: true })
  cancelAction: () => Promise<any>;

  get actualOkText() {
    return this.okText ?? this.$t('confirmationBox.ok');
  }

  get actualCancelText() {
    return this.cancelText ?? this.$t('confirmationBox.cancel');
  }

  @Watch('show')
  syncShowValue() {
    this.displayConfirmation = this.show;
  }

  mounted() {
    this.syncShowValue();
  }

  async runConfirmAction() {
    this.isRunningAction = true;
    await this.confirmAction();
    this.isRunningAction = false;
  }

  async runCancel() {
    this.isRunningAction = true;
    await this.cancelAction();
    this.isRunningAction = false;
  }

  @Watch('displayConfirmation')
  async function() {
    if (!this.displayConfirmation) {
      this.runCancel();
    }
  }
}
