



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { }
})
export default class ScalarChart extends Vue {

  @Prop({ required: true })
  title: string;

  @Prop({ required: true })
  value: string;

  @Prop({ required: false })
  comparisonPercentage: number;

  @Prop({ required: false })
  comparisonValue: string;

  @Prop({ required: false })
  comparisonPeriod: string;

  @Prop({ required: false })
  loading: boolean

  formatPercentage(percentage: number): string {
    return `${percentage >= 0 ? '+' : ''}${percentage.toFixed(2)}%`;
  }
}

