









import CsvDialog from '@/components/common/CsvDialog.vue';
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import Transactions from '@/components/transactions/Transactions.vue';
import { transactionStore } from '@/store/store';
import { TransactionSearchOptions } from '@/store/transaction/transaction-models';
import { Component, Vue } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';

@Component({
  components: {
    'basic-layout': BasicLayout,
    'csv-dialog': CsvDialog,
    'transactions': Transactions,
  }
})
export default class TransactionsPage extends Vue
{
  get lastSearchOptions(): TransactionSearchOptions {
    return transactionStore.lastSearchOptions;
  }

  get lastTableOptions(): DataOptions {
    // reconstruct table options from search options
    const { page, pageSize, orderBy } = this.lastSearchOptions;
    return {
      page,
      itemsPerPage: pageSize,
      sortDesc: [orderBy === 'dateDesc'],
      sortBy: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    }
  }
}
