



























import CheckImage from '@/assets/images/check.svg?inline';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import { defaultPageName } from '@/router';
import { authenticationStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'check-image': CheckImage,
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
  },
})
export default class Logout extends Vue {
  loading = true;

  async navigateLogin(event: Event) {
    event.preventDefault();

    // UI idempotency in case a user clicks more than once
    if (this.loading) return;

    // Since user was already logged out on mount, just reroute to default page
    this.$router.push({ name: defaultPageName });
  }

  async mounted() {
    await authenticationStore.logout(true);
    this.loading = false;
  }
}
