




















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
/**
 * Component is used to center an image with a fixed width
 * in a flex container.
 */
@Component
export default class ActionButton extends Vue {
    @Prop({ required: false, default: undefined })
    on?: any;

    @Prop({ required: false, default: undefined })
    attrs?: any;

    @Prop({ required: false, default: undefined })
    extraClasses?: string[];

    @Prop({ required: false, default: undefined })
    disabled?: boolean;

    @Prop({ required: false, default: undefined })
    loading?: boolean;

    @Prop({ required: false, default: true})
    capitalize: boolean;

    get hasIconSlot() {
        return !!this.$slots.icon;
    }

    @Emit('click')
    emitClick(event) {
        return event;
    }
}
