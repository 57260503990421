






import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import { authenticationStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'loading-spinner': LoadingSpinner,
  }
})
export default class AuthCallbackPage extends Vue {
  // async/await to properly catch errors
  async mounted() {
    await authenticationStore.handleAuthenticationCallback();
  }
}
