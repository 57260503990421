








import { Component, Prop, Vue } from 'vue-property-decorator';
import ECharts from 'vue-echarts'

/*
* To use this chart, make sure 'loadMapFeature()' of the corresponding map (e.g. USA) has been called.
*/
@Component({
  components: {
    'echarts': ECharts
  }
})
export default class MapChart extends Vue {
  /*
  Based on echarts and its vue wrapper vue-echarts
  for documentation about echarts see https://echarts.apache.org/en/index.html
  for documentation about vue-echarts see https://github.com/ecomfe/vue-echarts

  Geo US map chart is based on https://echarts.apache.org/examples/en/editor.html?c=map-usa
  */

  @Prop({ required: true })
  title: string;

  @Prop({ required: false })
  options: any

  @Prop({ required: false })
  loading: boolean
}
