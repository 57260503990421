




















import { DisputeEvidence } from '@/store/dispute/dispute-models';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import DisputeEvidenceAddress from '../components/DisputeEvidenceAddress.vue';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';

@Component({
    components: {
        'dispute-evidence-address': DisputeEvidenceAddress,
        'dispute-details-section': DisputeDetailsSection
    },
})
export default class DisputeCustomerDetailsSection extends Vue {
    @Prop({ required: true })
    evidence: DisputeEvidence;

    @Prop({ required: true })
    editable: boolean;

    // local states
    customerEmailAddress? = '';
    customerName? = '';
    billingAddress? = '';

    @Watch('customerEmailAddress')
    @Emit('evidencePropertyChanged')
    onCustomerEmailAddressChanged(newValue) {
        return { propertyName: 'customerEmailAddress', propertyValue: newValue };
    }

    @Watch('customerName')
    @Emit('evidencePropertyChanged')
    onCustomerNameChanged(newValue) {
        return { propertyName: 'customerName', propertyValue: newValue };
    }

    @Watch('billingAddress')
    @Emit('evidencePropertyChanged')
    onBillingAddressChanged(newValue) {
        return { propertyName: 'billingAddress', propertyValue: newValue };
    }

    async created() {
        this.customerEmailAddress = this.evidence.customerEmailAddress;
        this.customerName = this.evidence.customerName;
        this.billingAddress = this.evidence.billingAddress;
    }
}
