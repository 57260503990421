




































import { Component, Vue, Watch } from 'vue-property-decorator';
import DoubleDatePicker from '@/components/controls/DoubleDatePicker.vue';

import OrderValueChart from './charts/OrderValueChart.vue';
import OrderCountChart from './charts/OrderCountChart.vue'
import OrderAverageValueChart from './charts/OrderAverageValueChart.vue'
import CustomerAgeGroupChart from './charts/CustomerAgeGroupChart.vue';
import CustomerLocationChart from './charts/CustomerLocationChart.vue';
import CustomerCountChart from './charts/CustomerCountChart.vue';
import TransactionValueChart from './charts/TransactionValueChart.vue';

import moment from 'moment-timezone';
import SearchView from '../layouts/SearchView.vue';
import { applicationStore, authenticationStore, insightsStore } from '@/store/store';
import CheckBox from '../controls/CheckBox.vue';

@Component({
  components: {
    'search-view': SearchView,
    'double-date-picker': DoubleDatePicker,
    'checkbox': CheckBox,
    'order-value-chart': OrderValueChart,
    'order-count-chart': OrderCountChart,
    'order-average-value-chart': OrderAverageValueChart,
    'customer-count-chart': CustomerCountChart,
    'customer-age-group-chart': CustomerAgeGroupChart,
    'customer-location-chart': CustomerLocationChart,
    'transaction-value-chart': TransactionValueChart
  }
})
export default class Insights extends Vue {
  endDate = moment.utc().startOf('day').toISOString();
  startDate = moment.utc().startOf('day').subtract(1, 'week').toISOString(); // default showing 1-week data
  displayPreviousYearData = true;

  get loadingCustomers() {
    return insightsStore.loadingCustomers;
  }

  get loadingOrders() {
    return insightsStore.loadingOrders;
  }

  get loadingTransactions() {
    return insightsStore.loadingTransactions;
  }

  get aggregatedOrdersResult() {
    return insightsStore.aggregatedOrdersResult;
  }

  get aggregatedTransactionsResult() {
    return insightsStore.aggregatedTransactionsResult;
  }

  get aggregatedCustomersResult() {
    return insightsStore.aggregatedCustomersResult;
  }

  get currentIdentity() {
    return authenticationStore.currentIdentity;
  }
  
  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  async startDateUpdated(newDate: string) {
    this.startDate = moment.utc(newDate, "YYYY-MM-DD").startOf("day").toISOString();
    await this.searchInsights();
  }

  async endDateUpdated(newDate: string) {
    this.endDate = moment.utc(newDate, "YYYY-MM-DD").startOf("day").toISOString();
    await this.searchInsights();
  }

  @Watch('currentIdentity')
  async updateInsights() {
    await this.searchInsights();
  }

  async searchInsights() {
    const startDate = this.startDate;
    // We don't want data to include "future" dates
    let endDate = this.endDate;
    if (moment.utc(endDate) > moment.utc().startOf('day')) {
      endDate = moment.utc().startOf('day').toISOString();
    }
    endDate = DoubleDatePicker.getInclusiveEndDate(endDate); // SQL search is EXCLUSIVE so use next day as end date
    await Promise.all([
      insightsStore.loadAggregatedOrders({ startDate, endDate, loadPreviousYearData: this.displayPreviousYearData }),
      insightsStore.loadAggregatedTransactions({ startDate, endDate, loadPreviousYearData: this.displayPreviousYearData }),
      insightsStore.loadAggregatedCustomers({ startDate, endDate, loadPreviousYearData: this.displayPreviousYearData })
    ]);
  }

  async onToggleDisplayPreviousYearData(newVal) {
    if (this.displayPreviousYearData !== newVal) {
      this.displayPreviousYearData = newVal;
      await this.searchInsights();
    }
  }

  async mounted() {
    await this.searchInsights();
  }
}
