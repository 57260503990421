





















































































import SearchIcon from '@/assets/images/search.svg?inline';
import DoubleDatePicker from '@/components/controls/DoubleDatePicker.vue';
import configuration from '@/configuration';
import OrdersIcon from '@/assets/images/icons/sidebar/OrdersIconLarge.svg?inline';
import moment from 'moment-timezone';
import { OrderSearchObject } from '@/store/order/order-models';
import { orderPageName } from '@/router';
import { applicationStore, authenticationStore, orderStore } from '@/store/store';
import { TranslateResult } from 'vue-i18n';
import { DataOptions, DataTableHeader } from 'vuetify';
import { Component, Watch, Vue } from 'vue-property-decorator';
import TableFooter from '../controls/TableFooter.vue';
import TimezoneNotice from '../common/TimezoneNotice.vue';
import SearchView from '../layouts/SearchView.vue';
import { toLocalDateTime } from '@/datetime';

@Component({
  components: {
    'search-view': SearchView,
    'double-date-picker': DoubleDatePicker,
    'orders-icon': OrdersIcon,
    'search-icon': SearchIcon,
    'table-footer': TableFooter,
    'timezone-notice': TimezoneNotice
  }
})
export default class Orders extends Vue {
  $refs!: {
    form: HTMLFormElement;
    dateRange: DoubleDatePicker;
  };
  // Allow skeleton loader to appear before table finishes loading (the first load only)
  firstLoad = true;
  loading = true;
  startFilterOff = false;
  endFilterOff = false;
  defaultDateFilter = true;
  startDate = moment.utc().startOf('month').toISOString();
  endDate = moment.utc().endOf('month').toISOString();
  searchInput = '';
  tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['createdDateTime'],
    sortDesc: [true], // default date desc
    groupBy: [],
    groupDesc: [],
    multiSort: false, // ability to sort by more than one column at once
    mustSort: true, // table must be sorted by at least one column
  };

  get footerOptions(): any {
    return TableFooter.getDefaultOptions(key => this.$t(key));
  }

  get searchText(): TranslateResult {
    return this.$t('orders.search');
  }

  get orderArray() {
    return orderStore.orderArray;
  }
  get orderCount() {
    return orderStore.orderCount;
  }

  get tableHeaders(): DataTableHeader[] {
    return [
      {
        text: this.$t('orders.date').toString(),
        value: 'createdDateTime',
      },
      {
        text: this.$t('orders.merchant-reference').toString(),
        value: 'merchantReference',
        sortable: false,
      },
      {
        text: this.$t('orders.merchant-platform-id').toString(),
        value: 'merchantPlatformId',
        sortable: false,
      },
      {
        text: this.$t('orders.orderNumber').toString(),
        value: 'orderNumber',
        sortable: false,
      },
      {
        text: this.$t('orders.customer-name').toString(),
        value: 'customerFirstName',
        sortable: false,
      },
      {
        text: this.$t('orders.amount').toString(),
        value: 'amount',
        sortable: false,
      },
    ];
  }

  get currentIdentity() {
    return authenticationStore.currentIdentity;
  }
  
  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  get notificationFeatureFlag(): boolean {
    return configuration.featureFlags.transactionNotification;
  }

  /** Display the date, in Utc using the configuration.dateFormat */
  displayDate(date: Date): string {
    return toLocalDateTime(date);
  }

  displayCustomerName(orderItem: OrderSearchObject) {
    return `${orderItem.customerFirstName} ${orderItem.customerLastName}`
  }

  onPageNumberChange(newPage) {
    newPage = parseInt(newPage, 10);

    const maxPages = Math.ceil(this.orderCount/this.tableOptions.itemsPerPage);

    if (newPage == undefined || isNaN(newPage)) {
      newPage = 1;
    } else if(newPage > maxPages) {
      newPage = maxPages;
    }

    this.tableOptions.page = newPage;
  }

  @Watch('currentIdentity')
  async updateSearch() {
    // Update orders list as identity changes
    await this.searchOrders();
  }

  @Watch('tableOptions')
  async updateTable() {
    await this.searchOrders();
    // Must use history push to ensure the page is not reloaded
    history.pushState({}, '', `?page=${this.tableOptions.page}&pageSize=${this.tableOptions.itemsPerPage}&desc=${this.tableOptions.sortDesc[0]}`);
  }

  async navigateOrder(order: OrderSearchObject) {
    this.$router.push({ name: orderPageName, params: { orderId: order.id } });
  }

  onSearch() {
    if (this.defaultDateFilter) {
      this.startFilterOff = true;
      this.endFilterOff = true;
    }

    this.searchOrders(1);
  }

  async searchOrders(pageNumber: number|null = null) {
    this.loading = true;
    this.tableOptions.page = pageNumber || this.tableOptions.page;
    const { page, itemsPerPage, sortDesc } = this.tableOptions;
    const minimumCreateDateTime = this.startFilterOff ? '' : this.startDate;
    const maximumCreateDateTime = this.endFilterOff ? '' : this.endDate;
    await orderStore.searchOrders({
      page,
      pageSize: itemsPerPage,
      orderBy: sortDesc[0] ? 'dateDesc' : 'dateAsc',
      text: this.searchInput,
      minimumCreateDateTime,
      maximumCreateDateTime,
    });
    this.loading = false;
  }

  async startDateUpdated(newDate: string) {
    this.startFilterOff = false;
    this.defaultDateFilter = false;
    this.startDate = moment.utc(newDate, "YYYY-MM-DD").startOf("day").toISOString();
    await this.searchOrders(1);
  }

  async endDateUpdated(newDate: string) {
    this.endFilterOff = false;
    this.defaultDateFilter = false;
    this.endDate = moment.utc(newDate, "YYYY-MM-DD").endOf("day").toISOString();
    await this.searchOrders(1);
  }

  async mounted() {
    // Check query params
    if (this.$route.query.page && !isNaN(parseInt(this.$route.query.page as string, 10))) this.tableOptions.page = parseInt(this.$route.query.page as string, 10);
    if (this.$route.query.pageSize && !isNaN(parseInt(this.$route.query.pageSize as string, 10))) this.tableOptions.itemsPerPage = parseInt(this.$route.query.pageSize as string, 10);
    if (this.$route.query.desc) this.tableOptions.sortDesc[0] = this.$route.query.desc === 'true';
    
    // Disable skeleton loader
    this.firstLoad = false;
  }
}
