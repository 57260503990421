






import Dashboard from '@/components/dashboard/Dashboard.vue';
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'basic-layout': BasicLayout,
    'dashboard': Dashboard,
  }
})
export default class DashboardPage extends Vue {}
