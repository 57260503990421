
























































import { DisputeEvidence, DisputeProductServiceTypeEnum } from '@/store/dispute/dispute-models';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import DisputeEvidenceAddress from '../components/DisputeEvidenceAddress.vue';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';

@Component({
    components: {
        'dispute-details-section': DisputeDetailsSection,
        'dispute-evidence-address': DisputeEvidenceAddress,
    },
})
export default class DisputeShippingDetailsSection extends Vue {
    @Prop({ required: true })
    evidence: DisputeEvidence;

    @Prop({ required: true })
    editable: boolean;

    // local states
    shippingTrackingNumber? = '';
    shippingCarrier? = '';
    shippingDate? = '';
    shippingAddress? = '';

    get billingAddressType() {
        return this.$t('disputeFormShippingSection.billing');
    }

    get hasShippingEvidence() {
        return (
            this.evidence.shippingAddress ||
            this.evidence.shippingCarrier ||
            this.evidence.shippingDate ||
            this.evidence.shippingTrackingNumber
        );
    }

    get shouldShowSection() {
        return this.evidence.productServiceType === DisputeProductServiceTypeEnum.physicalProduct || (!this.editable && this.hasShippingEvidence);
    }

    @Watch('shippingTrackingNumber')
    @Emit('evidencePropertyChanged')
    onShippingTrackingNumberChanged(newValue) {
        return { propertyName: 'shippingTrackingNumber', propertyValue: newValue };
    }

    @Watch('shippingCarrier')
    @Emit('evidencePropertyChanged')
    onShippingCarrierChanged(newValue) {
        return { propertyName: 'shippingCarrier', propertyValue: newValue };
    }

    @Watch('shippingDate')
    @Emit('evidencePropertyChanged')
    onShippingDateChanged(newValue) {
        return { propertyName: 'shippingDate', propertyValue: newValue };
    }

    @Watch('shippingAddress')
    @Emit('evidencePropertyChanged')
    onShippingAddressChanged(newValue) {
        return { propertyName: 'shippingAddress', propertyValue: newValue };
    }

    async created() {
        this.shippingAddress = this.evidence.shippingAddress;
        this.shippingDate = this.evidence.shippingDate;
        this.shippingCarrier = this.evidence.shippingCarrier;
        this.shippingTrackingNumber = this.evidence.shippingTrackingNumber;
    }
}
