






















import configuration from '@/configuration';
import { authenticationStore } from '@/store/store';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SimpleLogoHeader extends Vue {
  @Prop({ required: false, default: false })
  hideLogoutButton?: boolean;

  get companyName(): string {
    return configuration.company.name;
  }

  async logout(event: Event) {
    event.preventDefault();
    try {
      // Logout and redirect to logout page
      await authenticationStore.logout(false);
    } catch(e) {
      throw new Error(`Error logging out: ${JSON.stringify(e)}`);
    }
  }
}
