
































import { DisputeEvidence } from '@/store/dispute/dispute-models';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';

@Component({
    components: {
        'dispute-details-section': DisputeDetailsSection
    },
})
export default class DisputeRefundSection extends Vue {
    @Prop({ required: true })
    evidence: DisputeEvidence;

    @Prop({ required: true })
    editable: boolean;

    // local states
    refundPolicyDisclosure? = '';
    refundRefusalExplanation? = '';

    get shouldShowSection() {
        return true;
    }

    @Watch('refundPolicyDisclosure')
    @Emit('evidencePropertyChanged')
    onRefundPolicyDisclosureChanged(newValue) {
        return { propertyName: 'refundPolicyDisclosure', propertyValue: newValue };
    }

    @Watch('refundRefusalExplanation')
    @Emit('evidencePropertyChanged')
    onRefundRefusalExplanationChanged(newValue) {
        return { propertyName: 'refundRefusalExplanation', propertyValue: newValue };
    }

    async mounted() {
        this.refundPolicyDisclosure = this.evidence.refundPolicyDisclosure;
        this.refundRefusalExplanation = this.evidence.refundRefusalExplanation;
    }
}
