















































































































































import {
  mdiLogout
} from '@mdi/js';
import { SupportedLocale } from '@/store/application/application-models';
import { applicationStore, authenticationStore, merchantStore, contentStore, featureStore } from '@/store/store';
import { Component, Prop, Vue } from 'vue-property-decorator';
import SidebarNotification from './SidebarNotification.vue'
import configuration from '@/configuration';
import { Notification } from '@/store/content/content-models';
import { MerchantIdentity } from '@/store/merchant/merchant-models';
import TickIcon from '@/assets/images/icons/tick.svg?inline';

const territoryMap = {
  US: 'USA',
  GB: 'UK'
};

@Component({
  components: {
    'sidebar-notification': SidebarNotification,
    'tick-icon': TickIcon
  }
})
export default class Sidebar extends Vue {
  @Prop({ required: false, default: false })
  isMobile: boolean;
  @Prop({ required: false, default: true })
  drawerOpen: boolean;
  @Prop({ required: true })
  toggleNavDrawerFn: () => void;

  get sidebarOpen(): boolean {
    return this.drawerOpen;
  }
  set sidebarOpen(value: boolean) {
    if (this.drawerOpen !== value) {
      this.toggleDrawer();
    }
  }

  // For some reason v-icon will not accept icons unless they're in a variable
  logoutIcon: string = mdiLogout;

  previewClickCounter = 0;

  get canAccessDisputes() {
    return featureStore.shouldAllowDisputePagesAccess;
  }

  get canAccessStatements() {
    return featureStore.shouldAllowStatementPagesAccess;
  }

  get canAccessTransactions() {
    return featureStore.shouldAllowTransactionPagesAccess;
  }

  get canAccessInsights() {
    return featureStore.shouldAllowInsightPagesAccess;
  }

  get companyName(): string {
    return configuration.company.name;
  }

  get userName(): string {
    return authenticationStore.userName;
  }

  get merchantId(): string {
    return authenticationStore.merchantId;
  }

  get canSwitchIdentities(): boolean {
    return this.hasMultipleMerchantIdentities || this.hasMultipleSupportedLocales;
  }

  get hasMultipleMerchantIdentities(): boolean {
    return !!merchantStore.availableMerchantIdentities &&
      merchantStore.availableMerchantIdentities.length > 1;
  }

  get merchantIdentitiesForUser(): MerchantIdentity[] {
    return merchantStore.availableMerchantIdentities;
  }

  get hasMultipleSupportedLocales(): boolean {
    return applicationStore.supportedLocales.length > 1;
  }
  
  get supportedLocales(): SupportedLocale[] {
    return applicationStore.supportedLocales;
  }

  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  get csvV2Enabled(): boolean {
    return configuration.featureFlags.csvV2Enabled && applicationStore.currentCountry.code.toUpperCase() === 'US';
  }

  get canDisplay(): boolean {
    // Defaults to false at beginning of page load when meta = {}
    return !!this.$route.meta?.showSidebar;
  }

  get currentPlatform(): string {
    return merchantStore.platform;
  }

  get contentNotifications(): Notification[] {
    return contentStore.sideBarContentNotifications;
  }

  // Must be reactive to coordinate with i18n properly
  get pages() {
    var pages = [
      {
        title: this.$t('sidebar.dashboard'),
        icon: '$dashboard',
        path: '/dashboard'
      },
      {
        title: this.$t('sidebar.orders'),
        icon: '$orders',
        path: '/orders'
      },
    ];

    if (this.canAccessTransactions) {
      pages.push({
        title: this.$t('sidebar.transactions'),
        icon: '$transactions',
        path: '/transactions'
      });
    }

    pages.push({
      title: this.$t('sidebar.payments'),
      icon: '$payments',
      path: '/payments'
    });

    if (this.canAccessDisputes) {
      pages.push({
        title: this.$t('sidebar.disputes'),
        icon: '$disputes',
        path: '/disputes'
      });
    }

    if (this.canAccessStatements) {
      pages.push({
        title: this.$t('sidebar.statements'),
        icon: '$invoices',
        path: '/statements'
      });
    }

    if (this.canAccessInsights) {
      pages.push({
        title: this.$t('sidebar.insights'),
        icon: '$insights',
        path: '/insights'
      });
    }

    return pages;
  }

  get otherPages() {
    return [
      {
        title: this.$t('sidebar.settings'),
        icon: '$settings',
        path: '/settings'
      },
      {
        title: this.$t('sidebar.help'),
        icon: '$help',
        path: '/help'
      },
    ]
  }

  getTerritoryName(territory: string) {
    return territoryMap[territory] || territory;
  }

  toggleDrawer() {
    this.toggleNavDrawerFn();
  }

  async switchMerchant(identity: MerchantIdentity) {
    await applicationStore.switchMerchant(identity);
    this.toggleDrawer();
  }

  async switchLanguage(locale: SupportedLocale) {
    await applicationStore.switchLanguage(locale);
    this.toggleDrawer();
  }

  async logout(event: Event) {
    event.preventDefault();

    // Toggle drawer closed (for mobile indication of "Logout" pressed)
    this.toggleDrawer();

    try {
      // Logout and redirect to logout page
      await authenticationStore.logout(false);
    } catch(e) {
      throw new Error(`Error logging out: ${JSON.stringify(e)}`);
    }
  }

  async togglePreview() {
    // For now it is using number of clicks on the zip logo to toggle preview mode
    // This feature is only available to Sandbox environment for now
    const BUTTON_CLICKS_TO_TOGGLE_PREVIEW_MODE = 6;
    const PREVIEW_ENABLED_KEY = 'PreviewEnabled';

    if (configuration.environmentName?.includes('Sandbox') || configuration.environmentName?.includes('Production')) {
      this.previewClickCounter++;
      if (this.previewClickCounter % BUTTON_CLICKS_TO_TOGGLE_PREVIEW_MODE === 0) {
        try {
          const previewEnabled = localStorage.getItem(PREVIEW_ENABLED_KEY);
          if (!previewEnabled || previewEnabled === 'false') {
            localStorage.setItem(PREVIEW_ENABLED_KEY, 'true');
          } else {
            localStorage.setItem(PREVIEW_ENABLED_KEY, 'false');
          }
          window.location.reload();
        } catch {
          // silently consumed the error as it is not critical. app can continue as usual.
        }
      }
    }
  }
}
