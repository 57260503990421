



























































import { DisputeDetail } from "@/store/dispute/dispute-models";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Key } from 'ts-key-enum';
import DisputeIssuerEvidenceFile from "./DisputeIssuerEvidenceFile.vue";
import ActionButton from "@/components/controls/ActionButton.vue";

@Component({
  components: {
    "action-button": ActionButton,
    "dispute-evidence-file-view": DisputeIssuerEvidenceFile,
  },
})
export default class DisputeIssuerEvidence extends Vue {
  @Prop({ required: true })
  dispute: DisputeDetail;

  displayEvidenceOverlay = false;

  @Watch("displayEvidenceOverlay")
  onVisibilityChanged() {
    if (this.displayEvidenceOverlay) {
      window.addEventListener("keyup", this.onKeyUp);
    } else {
      window.removeEventListener("keyup", this.onKeyUp);
    }
  }

  onKeyUp(event) {
    if (event.key === Key.Escape) {
      this.displayEvidenceOverlay = false;
    }
  }

  onShow() {
    this.displayEvidenceOverlay = true;
  }

  @Emit("accept-dispute")
  onAccept() {
    this.displayEvidenceOverlay = false;
  }

  onDismiss() {
    this.displayEvidenceOverlay = false;
  }
}
