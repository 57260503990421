

































import { Component, Prop, Vue } from 'vue-property-decorator';
import ActionButton from '../controls/ActionButton.vue';
/**
 * Component is used to center an image with a fixed width
 * in a flex container.
 */
@Component({
  components: {
    'action-button': ActionButton,
  }
})
export default class DialogBox extends Vue {
  @Prop({ required: true })
  show: boolean;
  
  @Prop({ required: false, default: "" })
  dialogClass?: string;

  @Prop({ required: false, default: "" })
  title?: string;

  @Prop({ required: false, default: "" })
  message?: string;

  @Prop({ required: false, default: "" })
  buttonText?: string;
  
  @Prop({ required: true })
  buttonAction: () => void;
}
