





















import { DisputeEvidenceFileCategoryEnum, DisputeProductServiceTypeEnum } from '@/store/dispute/dispute-models';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { },
})
export default class DisputeSupportingEvidenceGuide extends Vue {
  @Prop({ required: true, default: DisputeProductServiceTypeEnum.other })
  productServiceType: DisputeProductServiceTypeEnum;

  get suggestedEvidenceTypes() {
    const arr: string[] = [];
    for (var n in DisputeEvidenceFileCategoryEnum) {
      if (typeof DisputeEvidenceFileCategoryEnum[n] === 'number') {
        const value: any = DisputeEvidenceFileCategoryEnum[n];
        if (this.shouldIncludeCategory(value)) {
          arr.push(n);
        }
      }
    }
    return arr;
  }

  shouldIncludeCategory(category: DisputeEvidenceFileCategoryEnum) {
    switch (category) {
      case DisputeEvidenceFileCategoryEnum.cancellationPolicy:
        switch (this.productServiceType) {
          case DisputeProductServiceTypeEnum.digitalProductOrService:
          case DisputeProductServiceTypeEnum.offlineService:
          case DisputeProductServiceTypeEnum.eventBookingOrReservation:
            return true;
        }
        return false;
      case DisputeEvidenceFileCategoryEnum.customerCommunication:
      case DisputeEvidenceFileCategoryEnum.customerSignature:
      case DisputeEvidenceFileCategoryEnum.receipt:
      case DisputeEvidenceFileCategoryEnum.uncategorizedFile:
        return true;
      case DisputeEvidenceFileCategoryEnum.refundPolicy:
        switch (this.productServiceType) {
          case DisputeProductServiceTypeEnum.physicalProduct:
          case DisputeProductServiceTypeEnum.eventBookingOrReservation:
          case DisputeProductServiceTypeEnum.other:
            return true;
        }
        return false;
      case DisputeEvidenceFileCategoryEnum.serviceDocumentation:
        return this.productServiceType === DisputeProductServiceTypeEnum.offlineService;
      case DisputeEvidenceFileCategoryEnum.shippingDocumentation:
        return this.productServiceType === DisputeProductServiceTypeEnum.physicalProduct;
      default:
        return false;
    }
  }

}
