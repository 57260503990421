






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MapChart from '../../controls/charts/MapChart.vue';
import { AggregatedCustomer, ComparableAggregatedResult, CustomerDataTypeEnum } from '@/store/insights/insights-models';
import InsightsStore from '@/store/insights/insights-store';
import { loadMapFeature, resolveStateName } from '@/components/controls/charts/maps/usa';

const MapName = loadMapFeature();

@Component({
  components: {
    'map-chart': MapChart,
  }
})
export default class CustomerLocationChart extends Vue {
  @Prop({ required: true })
  data: ComparableAggregatedResult;

  @Prop({ required: false })
  loading: boolean;

  options: any = {};
  calculating = false;

  optionsWithData(data: Array<{ name: string, value: number }>, valueFormatter) {
    return {
      tooltip: {
        trigger: 'item',
        showDelay: 0,
        transitionDuration: 0.2,
        valueFormatter,
      },
      visualMap: {
        min: 0,
        max: Math.max(...data.map(e => e.value)),
        show: false,
        inRange: {
          // colors created by
          // taking zip fearless brand color #7A3DCE
          // entering to a color editor, I used paint.net, 
          // set saturation too 70%
          // then take color change in 10 steps 
          // between saturation 20% to 70%.
          //
          // 10 steps is arbitrary. Can be less or more steps.
          // echarts seems to put values in the matching bracket
          // in the range defined by min and max properties.
          //
          // if the above brand color is different, use
          // the right color, but make sure the saturation
          // range you use is visible at the bottom, 
          // (so the color is still visible against white, and 
          // differentiates against a data value of 0)
          // not over saturated at the top (so the high values are
          // not hurtful to see.)
          color: [
            '#AFA3CC', // lightest (lower values)
            '#A999CC',
            '#A28ECC',
            '#9B84CC',
            '#957ACC',
            '#9070CC',
            '#8B66CC',
            '#865BCC',
            '#8251CC',
            '#7E47CC',
            '#7A3DCE', // brightest (higher values)
          ]
        },
        calculable: false
      },
      toolbox: {
        show: false,
      },
      series: [{
        name: 'Unique Customers',
        type: 'map',
        roam: false,
        map: MapName,
        emphasis: {
          label: {
            show: false // label may not fit within the state boundaries
          },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        data: data
      }]
    };
  }

  @Watch('data')
  async onDataChange(newVal: ComparableAggregatedResult) {
    this.calculating = true;
    const result = newVal?.current;

    if (!result || !result.data) return;
    const { keys, values } = await InsightsStore.aggregateByCategory(result.data as AggregatedCustomer[], CustomerDataTypeEnum.Location);

    let data: Array<{ name: string, value: number }> = [];
    let total = 0;
    for (let index = 0; index < keys.length; index++) {
      // note that name in the response must match the names defined in the features collection.
      data.push({ name: resolveStateName(keys[index]), value: values[index] });
      await InsightsStore.sleep(); // Do not stall the UI!!
      total += values[index];
    }

    const valueFormatter = (value) => {
      if (isNaN(value)) {
        return '-';
      }
      return `${value.toFixed(0)} (${this.displayPercentage(value, total)})`;
    };

    this.options = this.optionsWithData(data, valueFormatter);
    this.calculating = false;
  }

  private displayPercentage(value, total: number) {
    return this.formatPercentage(value * 100 / Math.max(1, total));
  }

  formatPercentage(value: number): string {
    return `${isNaN(value) ? 0 : value.toFixed(2)}%`;
  }
}
