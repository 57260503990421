

























































import configuration from "@/configuration";
import eventBus from "@/event-bus";
import { Component, Vue } from "vue-property-decorator";
import SimpleView from "../layouts/SimpleView.vue";
import { applicationStore, merchantStore } from '@/store/store';
import { Territory } from '@/store/application/application-models';

@Component({
  components: {
    'simple-view': SimpleView
  }
})
export default class Help extends Vue {
  supportEmail: string = configuration.supportEmail;
  privacyLink: string = configuration.links.privacyLink;
  customerTermsLink: string = configuration.links.customerTermsLink;
  merchantTermsLink: string = configuration.links.merchantTermsLink;
  merchantHelpCenter: string = configuration.links.merchantHelpCenter;

  trackClick(item: string) {
    eventBus.publishTrackClickEvent(item);
  }

  get isMssRequired(): boolean {
    const territories = applicationStore.currentCountry?.territories || [];
    const isUSterritory = territories.some(t => t === Territory.US);
    const isMssMerchant = merchantStore.isMssMerchant;
    return isUSterritory && isMssMerchant;
  }
}
