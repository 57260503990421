






import CheckEmail from '@/components/login/CheckEmail.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'check-email': CheckEmail,
  }
})
export default class CheckEmailPage extends Vue {}
