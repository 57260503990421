





















import Header from '@/components/common/Header.vue';
import HeaderNotification from '@/components/common/HeaderNotification.vue';
import DialogBox from '@/components/common/DialogBox.vue';
import SessionExpiringModal from '@/components/common/SessionExpiringModal.vue';
import Sidebar from '@/components/common/Sidebar.vue';
import configuration from '@/configuration';
import { TranslateResult } from 'vue-i18n';
import logger from '@/logger';
import { applicationStore, authenticationStore, contentStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'dialog-box': DialogBox,
    Header,
    Sidebar,
    'session-expiring-modal': SessionExpiringModal,
    'header-notification': HeaderNotification
  }
})
export default class App extends Vue {
  dialogTitle: TranslateResult = '';
  dialogButtonText: TranslateResult = '';

  get errorModalMessage(): string {
    return applicationStore.errorModalMessage;
  }

  get errorToDisplay(): boolean {
    return applicationStore.showErrorModal;
  }

  closeErrorModal() {
    applicationStore.toggleShowErrorModal(false);
  }

  get sessionExpiring(): boolean {
    return authenticationStore.showExpModal;
  }

  closeSessionExpiringModal() {
    authenticationStore.toggleShowExpModal(false);
  }

  // For side navigation UX change
  windowWidth: number = window.innerWidth;
  get isMobile(): boolean {
    // 812px is iPhoneX
    return this.windowWidth <= 812;
  }

  drawerOpen = false;
  toggleNavDrawerFn() {
    this.drawerOpen = !this.drawerOpen;
  }

  /**
   * Set the page title on component creation
   */
  created() {
    document.title = configuration.environmentName === "Production" ?
      `${configuration.company.name} Merchant Portal` :
      `${configuration.company.name} ${configuration.environmentName} Merchant Portal`;

    logger.info(
      `Configuration loaded for node env '${process.env.NODE_ENV}' and hostname '${window.location.hostname}'`,
      configuration);

    this.dialogTitle = this.$t('app.dialog-title');
    this.dialogButtonText = this.$t('app.dialog-button-text');
  }

  async mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });

    await this.getNotifications();
  }

  async getNotifications() {
    await contentStore.refreshNotifications();
  }
}
