





































import { Component, Emit, Prop, Vue, Model, Watch } from 'vue-property-decorator';
import ActionButton from '@/components/controls/ActionButton.vue';
import { placeStore } from '@/store/store';
import { DefaultValidatorArray, RequiredFieldValidator } from '@/validators';

interface AddressEntry {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

const DefaultAddress: AddressEntry = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
};

@Component({
  components: {
    'action-button': ActionButton,
  },
})
export default class DisputeEvidenceAddress extends Vue {
  @Model('change', { required: false, default: null })
  model: string;

  @Prop({ required: false, default: false })
  disabled: boolean;

  @Prop({ required: false, default: null })
  copyFromAddressFunc?: () => string;

  @Prop({ required: false, default: null })
  copyFromAddressType: string | null;

  editing = false;
  isManualEntryMode = false;
  state?= '';
  structuredAddress = DefaultAddress;
  requiredField = DefaultValidatorArray;
  valid = true;

  get mainTextboxValidators() {
    return this.isManualEntryMode ? this.requiredField : [];
  }

  get countries() {
    return placeStore.countries;
  }

  get states() {
    return placeStore.states[this.structuredAddress.country] ?? [];
  }

  convertStructuredAddress() {
    let state = this.structuredAddress.state;
    let zip = this.structuredAddress.zip;
    if (this.structuredAddress.state && this.structuredAddress.zip) {
      state = `${this.structuredAddress.state} ${this.structuredAddress.zip}`;
      zip = '';
    }

    if (!this.isManualEntryMode) {
      this.state = [
        this.structuredAddress.line1,
        this.structuredAddress.line2,
        this.structuredAddress.city,
        state,
        zip,
        placeStore.countries.filter(c => c.code === this.structuredAddress.country)[0]?.name,
      ]
      .filter((i) => i && i !== '')
      .join(', ');
    }
  }

  async onCountryChanged(newValue) {
    this.structuredAddress.state = '';
    await placeStore.ensureStates(newValue);
  }

  onStartEditing() {
    this.editing = true;
    this.isManualEntryMode = !!this.state && this.state !== '';
  }

  onSetManualMode() {
    this.convertStructuredAddress();
    this.isManualEntryMode = true;
  }

  onCopyAddress() {
    if (!this.copyFromAddressFunc) {
      return;
    }

    this.state = this.copyFromAddressFunc();
    this.editing = false;
    this.structuredAddress = { ...DefaultAddress };
    this.isManualEntryMode = true;
  }

  validate() {
    this.valid = (this.$refs.addressform as HTMLFormElement).validate();
    return this.valid;
  }

  onSaveAddress() {
    if(!this.validate()) {
      return;
    }

    this.convertStructuredAddress();

    this.editing = false;
    this.structuredAddress = { ...DefaultAddress };
    this.isManualEntryMode = true;
  }

  onReset() {
    this.state = undefined;
    this.structuredAddress = { ...DefaultAddress };
    this.isManualEntryMode = false;
  }

  @Emit('change')
  @Watch('state')
  private onStateChanged(newState: any) {
    return newState;
  }

  async created() {
    this.requiredField = RequiredFieldValidator;
  }

  async mounted() {
    this.state = this.model;
  }
}
