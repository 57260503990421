






import BasicLayout from '@/components/layouts/BasicLayout.vue';
import Orders from '@/components/orders/Orders.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'basic-layout': BasicLayout,
    'orders': Orders,
  }
})
export default class OrdersPage extends Vue {}
