
































import { DisputeDetail, DisputeEvidence, DisputeReasonEnum } from '@/store/dispute/dispute-models';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';

@Component({
    components: {
        'dispute-details-section': DisputeDetailsSection
    },
})
export default class DisputeCancellationSection extends Vue {
    @Prop({ required: true })
    evidence: DisputeEvidence;

    @Prop({ required: true })
    dispute: DisputeDetail;

    @Prop({ required: true })
    editable: boolean;

    // local states
    cancellationPolicyDisclosure? = '';
    cancellationRebuttal? = '';

    get shouldShowSection() {
        return this.dispute.reason === DisputeReasonEnum.subscriptionCanceled;
    }

    @Watch('cancellationPolicyDisclosure')
    @Emit('evidencePropertyChanged')
    onCancellationPolicyDisclosureChanged(newValue) {
        return { propertyName: 'cancellationPolicyDisclosure', propertyValue: newValue };
    }

    @Watch('cancellationRebuttal')
    @Emit('evidencePropertyChanged')
    onCancellationRebuttalChanged(newValue) {
        return { propertyName: 'cancellationRebuttal', propertyValue: newValue };
    }

    async mounted() {
        this.cancellationPolicyDisclosure = this.evidence.cancellationPolicyDisclosure;
        this.cancellationRebuttal = this.evidence.cancellationRebuttal;
    }
}
