






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class DisputeEvidenceFormStageHeader extends Vue {
    @Prop({ required: true })
    editable: boolean;

    @Prop({ required: true })
    currentStage: number;

    @Prop({ required: true })
    stageEnum: any;

    get maxStages() {
        let count = 0;
        for (const n in this.stageEnum) {
            if (typeof this.stageEnum[n] === 'number') {
                count++;
            }
        }
        return count;
    }
}
