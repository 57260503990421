






import Error from '@/components/error/Error.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'error': Error,
  },
})
export default class ErrorPage extends Vue {}
