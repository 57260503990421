






import Logout from '@/components/login/Logout.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'logout': Logout,
  },
})
export default class LogoutPage extends Vue {}
