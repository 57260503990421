
































import { toLocalDate } from '@/datetime';
import { DisputeDetail } from '@/store/dispute/dispute-models';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DisputeReason from '../../DisputeReason.vue';

@Component({
  components: {
    "dispute-reason": DisputeReason,
  },
})
export default class DisputeSummaryPanel extends Vue {
  @Prop({ required: true })
  dispute: DisputeDetail;

  get amount() {
    return this.$n(this.dispute.disputedAmount, 'currency');
  }

  get dueDate() {
    return toLocalDate(this.dispute.evidenceDueOn);
  }

  get orderId() {
    return this.dispute.orderNumber ?? this.dispute.orderId;
  }

  get customerName() {
    return `${this.dispute.customerFirstName} ${this.dispute.customerLastName}`;
  }
}
