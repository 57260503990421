






import Help from '@/components/help/Help.vue';
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'basic-layout': BasicLayout,
    'help': Help,
  }
})
export default class HelpPage extends Vue {}
