
















import ActionButton from '@/components/controls/ActionButton.vue';
import { mdiCheck } from '@mdi/js';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';

@Component({
  components: {
    'action-button': ActionButton
  },
})
export default class DisputeSubmissionSection extends Vue {
  @Prop({ required: true })
  loading: boolean;

  @Prop({ required: true })
  submittable: boolean;

  // local states
  singleSubmissionAcknowledged = false;
  saving = false;
  saveDone = false;
  successIcon: string = mdiCheck;

  get canSubmit() {
    return this.singleSubmissionAcknowledged && !this.loading;
  }

  @Watch('loading')
  onLoadingChanged(newVal: boolean) {
    if (!newVal && this.saving) {
      this.saveDone = true;
      this.saving = false;
      setTimeout(() => {
        this.saveDone = false;
      }, 500);
    }
  }

  @Emit('submit')
  onSubmit(evt) {
    return evt;
  }

  @Emit('save')
  onSave(evt) {
    this.saving = true;
    return evt;
  }
}
