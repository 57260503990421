











import { Component, Vue } from 'vue-property-decorator';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';

@Component({
  components: {
    'dispute-details-section': DisputeDetailsSection
  },
})
export default class DisputeStartResponseHeaderSection extends Vue {
}
