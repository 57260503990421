import optimizely, { enums } from '@optimizely/optimizely-sdk';
import { ErrorHandler, LogHandler } from '@optimizely/js-sdk-logging';
import configuration from '@/configuration';
import logger from '@/logger';
import { errorHandler } from '@/errors';
import { authenticationStore } from '@/store/store';
import eventBus from '@/event-bus';
import { Route } from 'vue-router';

let optimizelyClientInstance: optimizely.Client;
let initialized = false;

const datafileLoadTimeoutInMs = 10000;

// Error message if datafile property is missing from Optimizely config
// Can be ignored because datafile is not necessary as long as sdkKey is specified
export const OptimizelyDatafileMessagePrefix = 'No datafile specified';

async function initialize() {
  if (configuration.debug.optimizely) {
    optimizely.setLogLevel('debug');
  }
  
  const customErrorHandler: ErrorHandler = {
    handleError: (exception: Error) => {
      errorHandler(exception, false);
    }
  }

  const customLogHandler: LogHandler = {
    log: (level, message) => {
      if (message.includes(OptimizelyDatafileMessagePrefix)) return;
      logger.warn(`OPTIMIZELY level: ${level}, message: ${message}`);
    }
  }

  optimizelyClientInstance = optimizely.createInstance({
    sdkKey: configuration.legacyOptimizelySdkKey,
    errorHandler: customErrorHandler,
    logger: customLogHandler,
    // Log levels 3 (warning) and 4 (error) only
    logLevel: enums.LOG_LEVEL.WARNING,
  });
  
  try {
    const result = await optimizelyClientInstance.onReady({ timeout: datafileLoadTimeoutInMs });
    
    if (result.success) {
      // initalized only if successful
      initialized = true;
      logger.info('Optimizely instance is ready!', result.success);
    } else if (result.reason) {
      eventBus.publishErrorEvent(new Error(result.reason || 'Unknown optimizely error'), true);
    }
  } catch (e) {
    logger.warn('Unable to initialize optimizely');
  }
}

/**
 * (Only for feature-flags event subscriber handlers/local check)
 * @param contextForErrorMessage 
 */
export function checkOptimizelyInitialization(contextForErrorMessage: string): boolean {
  if (!initialized) {
    const errorMessage = `Attempted to use optimizely client before initialization for ${contextForErrorMessage}`;
    logger.error(errorMessage);
    eventBus.publishErrorEvent(new Error(errorMessage));
    return false;
  }

  return true;
}

/**
 * Awaits/checks initialization (with retry logic) before fetching Optimizely
 * (For pieces dependent on Optimizely)
 * @param key 
 * @param variableKey 
 * @param defaultValue 
 */
export async function isFeatureEnabled(featureKey: string, defaultValue = false): Promise<boolean> {
  // Await Optimizely initialization
  const maxAttempts = 20;
  let attempts = 0;
  // Define resolve to make TS happy
  await new Promise((resolve: (value?) => void, reject) => {
    const isInitialized = setInterval(() => {
      attempts++;
      if (initialized) {
        clearInterval(isInitialized);
        resolve();
      } else if (attempts >= maxAttempts) {
        reject();
      }
    }, 50)
  });
  try {
    const featureVariable = !!optimizelyClientInstance.isFeatureEnabled(featureKey, authenticationStore.merchantId);
    logger.debug(`Optimizely feature key ${featureKey} => ${featureVariable}`);
    return featureVariable;
  } catch (e) {
    eventBus.publishErrorEvent(e);
    logger.error(`Failed to get feature for key ${featureKey}. Returning default value of ${defaultValue}`);
    return defaultValue;
  }
}

function handlePageView(route: Route) {
  const tags : optimizely.EventTags = {
    path: route?.path,
  };

  optimizelyClientInstance.track('mp-page-view', authenticationStore.merchantId, {}, tags);
}

function handleStripeStarted() {
  optimizelyClientInstance.track('mp-started-stripe', authenticationStore.merchantId);
}

function handleIntercomLoaded() {
  optimizelyClientInstance.track(configuration.intercom.optimizely.events.loaded, authenticationStore.merchantId);
}

function handleIntegrationPageLoaded() {
  optimizelyClientInstance.track(configuration.intercom.optimizely.events.integrationPageViewed, authenticationStore.merchantId);
}

function handleIntegrationTestPageLoaded() {
  optimizelyClientInstance.track(configuration.intercom.optimizely.events.integrationTestPageViewed, authenticationStore.merchantId);
}

export default {
  /**
   * Load optimizely and register all of the event consumers.
   */
  async load() {
    await initialize();
    eventBus.subscribePageViewEvent(handlePageView);
    eventBus.subscribeStartedStripeEvent(handleStripeStarted);
    eventBus.subscribeIntercomLoadedEvent(handleIntercomLoaded);
    eventBus.subscribeIntegrationPageLoadedEvent(handleIntegrationPageLoaded);
    eventBus.subscribeIntegrationTestPageLoadedEvent(handleIntegrationTestPageLoaded);
    logger.info('Successfully loaded Optimizely!');
  },
}