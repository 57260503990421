










import eventBus from '@/event-bus';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HeaderNotification extends Vue {
  @Prop({ required: true })
  routeToDisplay: string;
  @Prop({ required: true })
  notificationTitle: string;
  @Prop({ required: true })
  notificationDescription: string;
  @Prop({ required: false })
  trackingEventName: string;

  mounted() {
    if (this.$refs['notificationBody']) {
      const ref = this.$refs['notificationBody'] as HTMLElement;
      ref.getElementsByTagName('a').item(0)?.addEventListener('click', () => {
        eventBus.publishTrackClickEvent('Header Notification', { title: this.trackingEventName });
      });
    }
  }
}
