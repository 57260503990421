


















































































import { orderPageName } from '@/router';
import { InvoiceDetail, InvoiceLineItem, InvoiceTransaction, InvoiceTransactionTypeDto } from '@/store/statement/statement-models';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import StatementCsvDialog from './StatementCsvDialog.vue';

@Component({
    components: {
        'statement-csv-dialog': StatementCsvDialog,
    }
})
export default class StatementTransactionOrders extends Vue {
    @Prop({ required: true})
    item: InvoiceLineItem;
    @Prop({ required: true})
    details: InvoiceDetail;
    @Prop({ required: true})
    showTax: boolean;
    @Prop({ required: false})
    header: DataTableHeader; // NOTE: this is required for non-mobile view!!
    @Prop({ required: true})
    isTransactionTypeRelevant: (type: InvoiceTransactionTypeDto) => boolean;

    get isMobile() {
        return this.$vuetify.breakpoint.xs;
    }

    navigateToOrder(orderId: string) {
        this.$router.push({ name: orderPageName, params: { orderId: orderId } });
    }

    getTransactionRows(item: InvoiceLineItem) {
        if (item.hiddenRowCount &&
            item.hiddenRowCount > 0 &&
            item.transactions.length > 0 &&
            !item.transactions[item.transactions.length - 1].shouldDisplayHiddenRowMessage) {
            item.transactions[item.transactions.length - 1].shouldDisplayHiddenRowMessage = true;
        }
        return item.transactions;
    }

    getOrderIdentifier(row: InvoiceTransaction) {
        return row.orderNumber ?? row.id;
    }
}
