import configuration from "@/configuration";
import { errorHandler, handleResponseErrors } from "@/errors";
import logger from "@/logger";
import { authenticationStore } from "./store";
import { AuthorizationError } from "./store-models";

export interface RequestUrl {
    baseUrl?: string;
    service: string;
    query?: any;
}

export interface RequestDataType {
    action?: string,
    description: string;
}

export type RequestMethod = 'HEAD' | 'POST' | 'GET' | 'PUT' | 'DELETE';

export interface Request {
    url: RequestUrl | string;
    options?: {
        dataType?: RequestDataType | string;
        territory?: string;
        accessToken?: string;
        contentType?: string;
        errorMode?: RequestErrorModeEnum;
    }
}
  
// This is a special value to trigger auto discovery of content type for upload
export const AutoContentType = '[AutoContentType]';

export enum RequestErrorModeEnum
{
    returnWithError = 1,
    showErrorModal = 2,
    redirectToErrorPage = 3,
}

function constructUrl(url: RequestUrl | string): string {
    if (typeof url === 'string' || url instanceof String) {
        return url as string;
    }
    const req = url as RequestUrl;
    let baseUrl = req.baseUrl;
    if (!baseUrl) {
        // Use default base url
        baseUrl = `${configuration.links.apiDomain}merchants/${authenticationStore.merchantId}/`;
    }
    let query = '';
    if (req.query)
    {
        let first = true;
        for (const key in req.query) {
            const value = req.query[key];
            if (value === null || value === undefined) {
                continue; // NOTE: we for now ignore all null and undefined values...
            }
            if (!first) {
                query += '&';
            } else {
                query += '?';
                first = false;
            }
            query += encodeURIComponent(key) + "=" + encodeURIComponent(value);
        }
    }
    return baseUrl + req.service + query;
}

async function getData(request: Request): Promise<Response | undefined> {
    return fetchData(request, 'GET');
}

async function postData(request: Request, body: any): Promise<Response | undefined> {
    return fetchData(request, 'POST', body);
}

async function deleteData(request: Request, body: any): Promise<Response | undefined> {
    return fetchData(request, 'DELETE', body);
}

async function putData(request: Request, body: any): Promise<Response | undefined> {
    return fetchData(request, 'PUT', body);
}

function resolveAction(method: RequestMethod) {
    switch (method) {
        case 'POST':
            return 'updating';
        case 'GET':
            return 'getting';
        case 'PUT':
            return 'adding';
        case 'DELETE':
            return 'deleting';
        default:
            return method;
    }
}

function resolveDataType(method: RequestMethod, dataType?: RequestDataType | string): RequestDataType {
    let result: RequestDataType = { description: 'data' };
    if (dataType) {
        if (typeof dataType === 'string' || dataType instanceof String) {
            result = { description: dataType as string };
        } else {
            result = dataType;
        }
    }
    if (!result.action) {
        result.action = resolveAction(method);
    }
    return result;
}

async function fetchData(requestObject: Request, method: RequestMethod, body?: any): Promise<Response | undefined> {
    const dataType = resolveDataType(method, requestObject?.options?.dataType);
    if (!requestObject.options) {
        requestObject.options = {};
    }
    if (!requestObject?.options?.accessToken) {
        requestObject.options.accessToken = authenticationStore.accessToken;
    }
    let request: any = {
        method: method,
        headers: {
            authorization: `Bearer ${requestObject?.options?.accessToken}`,
            'qp-territory': requestObject?.options?.territory ?? authenticationStore.currentTerritory,
        },
    };
    if (body && !(method === "GET" || method === "HEAD")) {
        const jsonContentType = 'application/json';
        const contentType = requestObject?.options?.contentType ?? jsonContentType;
        if (contentType === jsonContentType) {
            body = JSON.stringify(body);
        }
        
        request = {
            ...request,
            body
        };

        request.headers = { ...request.headers, Accept: jsonContentType };
        if (contentType !== AutoContentType) {
            request.headers = { ...request.headers, 'Content-Type': contentType };
        }
    }
    try {
        const response = await fetch(constructUrl(requestObject.url), request);
        if (response.status > 226) {
            const errorMode = requestObject.options.errorMode ?? RequestErrorModeEnum.showErrorModal;
            if (errorMode === RequestErrorModeEnum.redirectToErrorPage) {
                errorHandler(new Error(resolveErrorMessage(dataType, response))); // Redirect
            }
            else {
                // Trigger error modal
                await handleResponseErrors(response, dataType.description, errorMode === RequestErrorModeEnum.showErrorModal);
            }
        }
        else {
            return response;
        }
    } catch (e) {
        console.log(e);
        // If 401, throw
        if (e instanceof AuthorizationError) throw e;
        // Else log error and trigger error modal
        logger.error(resolveErrorMessage(dataType), e);
    }
    return undefined;
}

function resolveErrorMessage(dataType: RequestDataType, response?: any) {
    return response?.error ?? `Undefined error ${dataType.action} ${dataType.description}`;
}

export async function postObject<T>(request: Request, body: any = null, defaultResult?: any): Promise<T> {
    const response = await postData(request, body);

    if (response) {
        return await response.json();
    }
    return defaultResult;
}

export async function deleteObject<T>(request: Request, body?: any, defaultResult?: any): Promise<T> {
    const response = await deleteData(request, body);

    if (response) {
        return await response.json();
    }
    return defaultResult;
}

export async function putObject<T>(request: Request, body: any, defaultResult?: any): Promise<T> {
    const response = await putData(request, body);

    if (response) {
        return await response.json();
    }
    return defaultResult;
}

export async function postForm<T>(request: Request, body?: any, defaultResult?: any): Promise<T> {
    const response = await postData({
        ...request,
        options: {
            ...request.options,
            contentType: AutoContentType
        }
     }, body);

    if (response) {
        return await response.json();
    }
    return defaultResult;
}

export async function postFile<T>(request: Request, file: File, defaultResult?: any): Promise<T> {
    const formData = new FormData();
    formData.append('file', file);

    return await postForm(request, formData, defaultResult);
}

export async function getObject<T>(request: Request, defaultResult?: any): Promise<T> {
    const response = await getData(request);

    if (response) {
        return await response.json();
    }
    return defaultResult;
}

export async function getBlob(request: Request, defaultResult?: any): Promise<Blob> {
    const response = await getData(request);

    if (response) {
        return await response.blob();
    }
    return defaultResult;
}

export async function downloadFile(filename: string, request: Request): Promise<boolean> {
    const blob = await getBlob(request);
    return await downloadBlob(blob, filename);
}

export async function downloadBlob(blob: Blob, filename: string): Promise<boolean> {
    if (blob)
    {
        // Create and return download url
        const downloadUrl = window.URL.createObjectURL(blob);

        if (downloadUrl) // Just in case...
        {
            // Create downloading anchor
            const downloadAnchor = document.createElement("a");
            downloadAnchor.href = downloadUrl;
            downloadAnchor.download = filename;

            // Add, use, and remove anchor
            document.body.appendChild(downloadAnchor);
            downloadAnchor.click();
            document.body.removeChild(downloadAnchor);
            return true;
        }
    }
    return false;
}