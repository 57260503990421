
















import { DisputeDetail } from '@/store/dispute/dispute-models';
import { Component, Vue, Prop } from 'vue-property-decorator';
import DisputeStatus from '../../DisputeStatus.vue';

@Component({
  components: {
    'dispute-status': DisputeStatus
  },
})
export default class DisputeDetailsSummaryTitle extends Vue {
  @Prop({ required: true })
  dispute: DisputeDetail;

  get disputeId() {
    return this.dispute.disputeId ?? this.dispute.id;
  }

  get amount() {
    return this.$n(this.dispute.disputedAmount, 'currency');
  }
}
