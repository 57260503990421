














import BasicLayout from '@/components/layouts/BasicLayout.vue';
import StatementCsvDialog from '@/components/statements/StatementCsvDialog.vue';
import StatementDetails from '@/components/statements/StatementDetails.vue';
import { statementsPageName } from '@/router';
import { InvoiceDetail } from '@/store/statement/statement-models';
import { statementStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'basic-layout': BasicLayout,
    'statement-details': StatementDetails,
    'statement-csv-dialog': StatementCsvDialog,
  }
})
export default class StatementDetailsPage extends Vue {
  get loading(): boolean {
    return statementStore.loadingStatementDetails;
  }

  get details(): InvoiceDetail {
    return statementStore.statementDetails;
  }

  navigateToStatements() {
    this.$router.push({ name: statementsPageName });
  }

  async created() {
    // Fetch statement details on page creation to ensure loadStatementDetails() is called before any components attempt to load and make api calls
    if (this.$route.params.statementId && typeof this.$route.params.statementId === "string") {
       await statementStore.loadStatementDetails(this.$route.params.statementId);
    }
  }
}
