






























import configuration from '@/configuration';
import { statementsPageName } from '@/router';
import moment from 'moment-timezone';
import StatementSummary from './StatementSummary.vue';
import { InvoiceDetail } from '@/store/statement/statement-models';
import { applicationStore, authenticationStore, featureStore, statementStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import SimpleView from '../layouts/SimpleView.vue';
import StatementTransactions from './StatementTransactions.vue';

@Component({
  components: {
    'simple-view': SimpleView,
    'statement-summary': StatementSummary,
    'statement-transactions': StatementTransactions,
  }
})
export default class StatementDetails extends Vue {
  $refs!: {
    form: HTMLFormElement;
    outerTable: any;
  };

  showDialog = false;

  get showTax() {
    return featureStore.shouldDisplayTaxInfo;
  }

  get details(): InvoiceDetail {
    return statementStore.statementDetails;
  }

  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  get currentIdentity() {
    return authenticationStore.currentIdentity;
  }

  /** Display the date, in Utc using the configuration.dateFormat */
  displayDate(date?: Date): string {
    if (date) {
      return moment(date).utc().locale(this.currentLocale).format(configuration.dateFormat);
    }
    return '';
  }

  navigateToStatements() {
    this.$router.push({ name: statementsPageName });
  }
}
