export interface StatementSearchOptions {
    status?: PaymentStatusEnum;
    page: number;
    pageSize: number;
    orderBy?: string;
    startDate: Date | string;
    endDate: Date | string;
}

export interface StatementSearchResult {
    value: Invoice[];
    total: number;
}

export interface StatementDetailsResult {
    invoiceDetail: InvoiceDetail;
}

//
//
// Since the backend is using the term "invoice" instead of "statement",
// keep below objects names so they are matching with the ones at the backend.
//
//

export interface Invoice {
    id: string;
    merchantId: string;
    merchantName: string;
    openDate: Date;
    closeDate: Date;
    isInvoiceClosed: boolean;
    payoutPlatform: string;
    payment?: PaymentStatusEnum;
    payoutDate?: Date;
    currencyCode: string;
    amount?: number;
    invoiceNumber?: string;
}

export interface InvoiceTransaction {
    id: string;
    merchantId: string;
    orderId: string;
    orderNumber?: string;
    invoiceId?: string;
    transactionDate: Date;
    productType: string;
    invoiceTransactionType: InvoiceTransactionTypeDto;
    currencyCode: string;
    amount: number;
    taxPercent?: number;
    taxAmount?: number;
    shouldDisplayHiddenRowMessage?: boolean;
}

export enum PaymentStatusEnum {
    unknown = 0,
    notStarted = 1,
    queued = 2,
    complete = 3,
    failed = 4,
    manualQueued = 5,
}

export interface InvoiceDetail {
    items: InvoiceLineItem[];
    currencyCode: string;
    amount: number;
    taxAmount?: number;
    openDate: Date;
    closeDate: Date;
    invoiceNumber?: string;
    merchantTradingName: string;
    merchantLegalName: string;
    merchantId: string;
    invoiceId: string;
    hasVirtualCardTransactions?: boolean;
}

export interface InvoiceLineItem {
    invoiceTransactionType: InvoiceTransactionTypeDto;
    currencyCode: string;
    amount: number;
    taxAmount?: number;
    transactions: InvoiceTransaction[];
    hiddenRowCount?: number;
}

export interface InvoiceTransactionsByOrderResponse {
    rows: InvoiceTransactionReportRow[];
}

export interface InvoiceTransactionReportRow {
    merchantId: string,
    transactionId: string,
    transactionDateTime: Date,
    statementId: string,
    statementNumber?: string,
    orderCreatedDateTime: Date,
    orderNumber?: string,
    orderId: string,
    merchantName?: string,
    merchantReference?: string,
    merchantPlatformId?: string,
    transactionType?: string,
    debitOrCredit: string,
    currency: string,
    amount: number,
    amountSigned: number,
    taxAmount?: number,
    taxAmountSigned?: number,
    statementStatus: PaymentStatusEnum,
    productType: string
}

export enum InvoiceTransactionTypeDto {
    Capture = 'Capture',
    MerchantFee = 'MerchantFee',
    Refund = 'Refund',
    MerchantFeeReversal = 'MerchantFeeReversal',
    TransactionFeeReversal = 'TransactionFeeReversal',
    TransactionFee = 'TransactionFee',
    MerchantFeeForPaymentPlan = 'MerchantFeeForPaymentPlan',
    MerchantFeeForPaymentPlanReversal = 'MerchantFeeForPaymentPlanReversal',
}