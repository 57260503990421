






import configuration from '@/configuration';
import moment from 'moment-timezone';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SimpleCopyrightFooter extends Vue {
  currentYear = moment().year();
  companyFormalName = configuration.company.companyFormalName;
}
