


























import SessionExpiredImage from '@/assets/images/session-expired.svg?inline';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import { authenticationStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';

/**
 * This component is used to signify a session that expired for a user.
 */
@Component({
  components: {
    'session-expired-image': SessionExpiredImage,
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
  },
})
export default class SessionExpired extends Vue {
  loading = false;

  /**
   * Allow the user to return to login page
   */
  async navigateLogin(event: Event) {
    event.preventDefault();

    // UI idempotency in case a user clicks more than once
    if (this.loading) return;

    this.loading = true;
    await authenticationStore.logout(true);
  }
}
