







import { Component, Vue } from "vue-property-decorator";
import { disputeStore } from "@/store/store";

@Component
export default class DisputeServiceUnavailableWarning extends Vue {
}
