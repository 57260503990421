












import { DisputeEvidence, DisputeProductServiceTypeEnum } from '@/store/dispute/dispute-models';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';

@Component({
    components: {
        'dispute-details-section': DisputeDetailsSection
    },
})
export default class DisputeServiceDetailsSection extends Vue {
    @Prop({ required: true })
    evidence: DisputeEvidence;

    @Prop({ required: true })
    editable: boolean;

    // local states
    serviceDate? = '';

    get shouldShowSection() {
        return this.evidence.productServiceType == DisputeProductServiceTypeEnum.offlineService || (!this.editable && this.evidence.serviceDate);
    }

    @Watch('serviceDate')
    @Emit('evidencePropertyChanged')
    onServiceDateChanged(newValue) {
        return { propertyName: 'serviceDate', propertyValue: newValue };
    }

    async mounted() {
        this.serviceDate = this.evidence.serviceDate;
    }
}
