


















import eventBus from '@/event-bus';
import { Timezone } from '@/store/application/application-models';
import { applicationStore } from '@/store/store';
import { orderBy } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';

const guessTimezone = (timezones: Timezone[]): string | null => {
  // first attempt to look up by code
  try {
    const shortTzCode = new Date()
      .toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'short' })
      .substring(4);

    const matchingByCode = timezones.find(tz => tz.code === shortTzCode);
    if (matchingByCode) {
      return shortTzCode;
    }
  } catch {
    // deliberately ignoring errors
  }

  // next attempt to look up by offset
  try {
    const offsetInMinutes = new Date().getTimezoneOffset();
    const offsetInHours = Math.floor(offsetInMinutes / 60);

    const matchingByOffset = timezones.find(tz => tz.utcOffset === offsetInHours);
    if (matchingByOffset) {
      return matchingByOffset[0].code;
    }
  } catch {
    // deliberately ignoring errors
  }

  // failing that, do not attempt to guess
  return null;
}

const reorderTimezones = (timezones: Timezone[]): Timezone[] => {
  const guessedTzCode = guessTimezone(timezones);
  if (!guessedTzCode) {
    return timezones;
  }

  const matchingTimezone = timezones.find(tz => tz.code === guessedTzCode);
  if (!matchingTimezone) {
    return timezones;
  }

  // prefer matching by same utc offset, ordered alphabetically
  const matchedByOffset = orderBy(
    timezones.filter(tz => tz.utcOffset === matchingTimezone.utcOffset && tz.code !== guessedTzCode),
    tz => tz.display
  );
  // but don't order unmatched
  const notMatchedByOffset = timezones.filter(tz => tz.utcOffset !== matchingTimezone.utcOffset);

  return [
    matchingTimezone,
    ...matchedByOffset,
    ...notMatchedByOffset
  ];
}

@Component
export default class TimezoneSettings extends Vue {
    supportedTimezones = reorderTimezones(applicationStore.currentCountry.supportedTimezones);

    timezoneDisplay(code: string) {
      return this.$t(`timezones.${code}`);
    }
    
    get currentTimezone() {
      return applicationStore.currentTimezone;
    }

    set currentTimezone(newTimezone) {
      eventBus.publishTrackClickEvent('Timezone Changed', { oldTimezone: applicationStore.currentTimezone, newTimezone });
      applicationStore.setCurrentTimezone(newTimezone);
    }
}
