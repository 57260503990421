






import { Component, Vue } from 'vue-property-decorator';

/**
 * Routing page for Updates
 */
@Component
export default class UpdatesMainPage extends Vue {}
