



























import NotFoundImage from '@/assets/images/404.svg?inline';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import { Component, Vue } from 'vue-property-decorator';

/**
 * Component used for not-found items.
 */
@Component({
  components: {
    'not-found-image': NotFoundImage,
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
  },
})
export default class NotFound extends Vue {
  loading = false;

  /**
   * Returns to the cart on the merchant's website.
   */
  navigateBack(event: Event) {
    event.preventDefault();

    // UI idempotency in case a user clicks more than once
    if (this.loading) return;

    this.loading = true;
    this.$router.go(-1);
  }
}
