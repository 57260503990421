




































































































import SearchIcon from '@/assets/images/search.svg?inline';
import DoubleDatePicker from '@/components/controls/DoubleDatePicker.vue';
import InvoicesIcon from '@/assets/images/icons/sidebar/InvoicesIconLarge.svg?inline';
import configuration from '@/configuration';
import moment from 'moment-timezone';
import { TranslateResult } from 'vue-i18n';
import { Invoice, PaymentStatusEnum } from '@/store/statement/statement-models';
import { applicationStore, authenticationStore, statementStore } from '@/store/store';
import { DataOptions, DataTableHeader } from 'vuetify';
import { statementDetailsPageName } from '@/router';
import { Component, Watch, Vue } from 'vue-property-decorator';
import TableFooter from '../controls/TableFooter.vue';
import StatementCsvDialog from './StatementCsvDialog.vue';
import SearchView from '../layouts/SearchView.vue';

@Component({
  components: {
    'search-view': SearchView,
    'double-date-picker': DoubleDatePicker,
    'invoices-icon': InvoicesIcon,
    'search-icon': SearchIcon,
    'table-footer': TableFooter,
    'statement-csv-dialog': StatementCsvDialog,
  }
})
export default class Statements extends Vue {
  $refs!: {
    form: HTMLFormElement;
    dateRange: DoubleDatePicker;
  };

  // Allow skeleton loader to appear before table finishes loading (the first load only)
  firstLoad = true;

  statusFilter?: PaymentStatusEnum = undefined;
  tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['openDate'],
    sortDesc: [true], // default date desc
    groupBy: [],
    groupDesc: [],
    multiSort: false, // ability to sort by more than one column at once
    mustSort: true, // table must be sorted by at least one column
  };
  startDate = moment.utc().startOf('month').toISOString();
  endDate = moment.utc().endOf('month').toISOString();
  selectedStatus = '';

  get loading(): boolean {
    return statementStore.loadingStatements;
  }

  get notificationFeatureFlag(): boolean {
    return configuration.featureFlags.transactionNotification;
  }

  get footerOptions(): any {
    return TableFooter.getDefaultOptions(key => this.$t(key));
  }

  get statementArray(): Invoice[] {
    return statementStore.statementArray;
  }

  get statementCount(): number {
    return statementStore.statementCount;
  }

  get tableHeaders(): DataTableHeader[] {
    return [
      {
        text: this.$t('statements.id').toString(),
        value: 'id',
        sortable: false,
      },
      {
        text: this.$t('statements.openDate').toString(),
        value: 'openDate',
      },
      {
        text: this.$t('statements.closeDate').toString(),
        value: 'closeDate',
      },
      {
        text: this.$t('statements.paymentStatus').toString(),
        value: 'payment',
        sortable: false,
      },
      {
        text: this.$t('statements.payoutDate').toString(),
        value: 'payoutDate',
        sortable: false,
      },
      {
        text: this.$t('statements.currencyCode').toString(),
        value: 'currencyCode',
        sortable: false,
        align: 'center',
      },
      // ...(configuration.isVatEnabled ? vatColumns : []),
      {
        text: this.$t('statements.amount').toString(),
        value: 'amount',
        sortable: false,
        align: 'end',
      },
      {
        text: this.$t('statements.download').toString(),
        value: 'download',
        sortable: false,
        align: 'center',
      }
    ];
  }

  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  get currentIdentity() {
    return authenticationStore.currentIdentity;
  }

  get statusFilterText(): TranslateResult {
    return this.$t('statements.status-filter');
  }

  get paymentStatusItems() {
    // Exclude the unknown status
    const values: string[] = [];
    for (var n in PaymentStatusEnum) {
        if (typeof PaymentStatusEnum[n] === 'number' && n !== PaymentStatusEnum[PaymentStatusEnum.unknown]) {
          values.push(n);
        }
    }
    return values.map(value => {
      return { value: PaymentStatusEnum[value], text: this.displayPaymentStatus(PaymentStatusEnum[value]) }
    });
  }

  /** Display the date, in Utc using the configuration.dateFormat */
  displayDateUtc(date?: Date): string {
    if (date) {
      return moment(date).utc().locale(this.currentLocale).format(configuration.dateFormat);
    }
    return '';
  }

  displayPaymentStatusString(paymentStatusString: string): string {
    return this.displayPaymentStatus(PaymentStatusEnum[paymentStatusString]);
  }

  displayPaymentStatus(paymentStatus: PaymentStatusEnum): string {
    switch (paymentStatus) {
      case PaymentStatusEnum.notStarted:
        return this.$t("statements.payment-status-not-started").toString();
      case PaymentStatusEnum.queued:
        return this.$t("statements.payment-status-queued").toString();
      case PaymentStatusEnum.complete:
        return this.$t("statements.payment-status-complete").toString();
      case PaymentStatusEnum.failed:
        return this.$t("statements.payment-status-failed").toString();
      case PaymentStatusEnum.manualQueued:
        return this.$t("statements.payment-status-queued-manually").toString();
      default:
        return '';
    }
  }

  displayInvoiceNumber(invoice) {
    return invoice.invoiceNumber ?? invoice.id;
  }

  @Watch('currentIdentity')
  async updateSearch() {
    // Update invoices list as identity changes
    await this.searchStatements();
  }

  @Watch('tableOptions')
  async updateTable() {
    await this.searchStatements();
  }

  async paginate(newPage) {
    this.tableOptions.page = newPage;
    await this.searchStatements();
  }

  onStatusFilterChanged(status?: { value:PaymentStatusEnum }) {
    this.statusFilter = status?.value;

    this.searchStatements(1);
  }

  async searchStatements(pageNumber: number | null = null) {
    this.tableOptions.page = pageNumber || this.tableOptions.page;
    const { page, itemsPerPage, sortDesc, sortBy } = this.tableOptions;

    await statementStore.searchStatements({
      options: {
        status: this.statusFilter,
        page,
        pageSize: itemsPerPage,
        orderBy: sortDesc[0] ? `${sortBy[0]},true` : `${sortBy[0]},false`,
        startDate: this.startDate,
        endDate: this.endDate,
      },
      apiEndDate:  DoubleDatePicker.getInclusiveEndDate(this.endDate),
    });
    // Must use history push to ensure the page is not reloaded
    history.pushState({}, '', `?status=${this.statusFilter}&page=${this.tableOptions.page}&pageSize=${this.tableOptions.itemsPerPage}&desc=${this.tableOptions.sortDesc[0]}$sortBy=${this.tableOptions.sortBy[0]}`);
  }

  async startDateUpdated(newDate: string) {
    this.startDate = moment.utc(newDate, "YYYY-MM-DD").startOf("day").toISOString();
    await this.searchStatements(1);
  }

  async endDateUpdated(newDate: string) {
    this.endDate = moment.utc(newDate, "YYYY-MM-DD").endOf("day").toISOString();
    await this.searchStatements(1);
  }

  async mounted() {
    // Check query params
    if (this.$route.query.page && !isNaN(parseInt(this.$route.query.page as string, 10))) this.tableOptions.page = parseInt(this.$route.query.page as string, 10);
    if (this.$route.query.pageSize && !isNaN(parseInt(this.$route.query.pageSize as string, 10))) this.tableOptions.itemsPerPage = parseInt(this.$route.query.pageSize as string, 10);
    if (this.$route.query.statusFilter && !isNaN(parseInt(this.$route.query.statusFilter as string, 10))) this.statusFilter = parseInt(this.$route.query.statusFilter as string, 10);
    if (this.$route.query.desc) this.tableOptions.sortDesc[0] = this.$route.query.desc === 'true';
    if (this.$route.query.sortBy) this.tableOptions.sortBy[0] = this.$route.query.sortBy as string;

    // Disable skeleton loader
    this.firstLoad = false;
  }

  async navigateStatement(invoice: Invoice) {
    this.$router.push({ name: statementDetailsPageName, params: { statementId: invoice.id ?? '' } });
  }
}
