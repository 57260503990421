




















import { DisputeEvidence, DisputeProductServiceTypeEnum } from '@/store/dispute/dispute-models';
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';

@Component({
    components: {
        'dispute-details-section': DisputeDetailsSection,
    },
})
export default class DisputeDigitalServiceDetailsSection extends Vue {
    @Prop({ required: true })
    evidence: DisputeEvidence;

    @Prop({ required: true })
    editable: boolean;

    // local states
    accessActivityLog? = '';

    get shouldShowSection() {
        return this.evidence.productServiceType === DisputeProductServiceTypeEnum.digitalProductOrService || (!this.editable && this.evidence.accessActivityLog);
    }

    @Watch('accessActivityLog')
    @Emit('evidencePropertyChanged')
    onAccessActivityLogChanged(newValue) {
        return { propertyName: 'accessActivityLog', propertyValue: newValue };
    }

    async mounted() {
        this.accessActivityLog = this.evidence.accessActivityLog;
    }
}
