


































































































import InvoicesIcon from '@/assets/images/icons/sidebar/InvoicesIconLarge.svg?inline';
import { InvoiceDetail, InvoiceLineItem, InvoiceTransactionTypeDto } from '@/store/statement/statement-models';
import { mdiChevronDown, mdiChevronUp, mdiCollapseAll, mdiExpandAll } from '@mdi/js';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DataOptions, DataTableHeader } from 'vuetify';
import StatementTransactionOrders from './StatementTransactionOrders.vue';
import StatementTransactionTotals from './StatementTransactionTotals.vue';

@Component({
    components: {
        'statement-transaction-orders': StatementTransactionOrders,
        'statement-transaction-totals': StatementTransactionTotals,
        'invoices-icon': InvoicesIcon,
    }
})
export default class StatementTransactions extends Vue {
    @Prop({ required: true })
    details: InvoiceDetail;
    @Prop({ required: true })
    showTax: boolean;

    collapseAllIcon: string = mdiCollapseAll;
    expandAllIcon: string = mdiExpandAll;
    collapseIcon: string = mdiChevronUp;
    expandIcon: string = mdiChevronDown;

    expandedRows: any = [];
    tableOptions: DataOptions = {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['transactionDate'],
        sortDesc: [true], // default date desc
        groupBy: [],
        groupDesc: [],
        multiSort: false, // ability to sort by more than one column at once
        mustSort: true, // table must be sorted by at least one column
    };

    get isMobile() {
        return this.$vuetify.breakpoint.xs;
    }

    get tableHeaders(): DataTableHeader[] {
        const headers: Array<DataTableHeader> = [
            {
                text: '',
                value: 'toggle',
                align: 'start',
                sortable: false,
                class: "lean"
            },
            {
                text: this.$t('statementDetails.description').toString(),
                value: 'invoiceTransactionType',
                align: 'start',
                sortable: false,
                class: "lean nowrap"
            },
            {
                text: this.$t('statementDetails.orderId').toString(),
                value: 'orderId',
                align: 'start',
                sortable: false,
                class: "fill"
            },
            {
                text: this.$t('statementDetails.amount').toString(),
                value: 'amount',
                align: 'end',
                sortable: false,
                class: "lean10p amount"
            },
            {
                text: this.$t('statementDetails.taxAmount').toString(),
                value: 'taxAmount',
                align: 'end',
                sortable: false,
                class: "lean10p amount"
            },
            {
                text: this.$t('statementDetails.total').toString(),
                value: 'total',
                align: 'end',
                sortable: false,
                class: "lean10p amount"
            },
        ];
        const filtered = headers.filter(h => this.showTax || (h.value !== 'taxAmount' && h.value !== 'amount'));
        if (this.isMobile) {
            // move the toggle column to the last
            filtered.push(filtered.shift() as DataTableHeader);
        }
        return filtered;
    }

    getLineItemOrderCount(item: InvoiceLineItem) {
        return item.transactions.length + (item.hiddenRowCount ?? 0);
    }

    isTransactionTypeRelevant(type: InvoiceTransactionTypeDto) {
        switch (type) {
            case InvoiceTransactionTypeDto.Capture:
            case InvoiceTransactionTypeDto.Refund:
                return !this.details.hasVirtualCardTransactions;
        }
        return true;
    }

    onRowClicked(item, event) {
        if (!event.isExpanded) {
            this.expandedRows.push(item);
            return;
        }
        const index = this.expandedRows.findIndex(i => i === item);
        this.expandedRows.splice(index, 1)
    }

    isOpen(item: InvoiceLineItem) {
        return this.expandedRows.includes(item);
    }

    expandRows() {
        for (let i = 0; i < this.details.items.length; i += 1) {
            this.expandedRows.push(this.details.items[i]);
        }
    }

    collapseRows() {
        this.expandedRows = [];
    }

    allRowsCollapsed(): boolean {
        return this.expandedRows.length == 0;
    }

    displayTransactionTypeDescription(type: InvoiceTransactionTypeDto) {
        switch (type) {
            case InvoiceTransactionTypeDto.Capture:
                return this.$t('statementDetails.transaction-type-orders-captured');
            case InvoiceTransactionTypeDto.Refund:
                return this.$t('statementDetails.transaction-type-order-returns-refunds');
            case InvoiceTransactionTypeDto.MerchantFeeReversal:
                return this.$t('statementDetails.transaction-type-order-service-fees-refunds');
            case InvoiceTransactionTypeDto.TransactionFeeReversal:
                return this.$t('statementDetails.transaction-type-order-transaction-fees-refunds');
            case InvoiceTransactionTypeDto.MerchantFeeForPaymentPlanReversal:
                return this.$t('statementDetails.transaction-type-order-mfpp-refunds');
            case InvoiceTransactionTypeDto.MerchantFeeForPaymentPlan:
                return this.$t('statementDetails.transaction-type-mfpp-collected');
            case InvoiceTransactionTypeDto.MerchantFee:
                return this.$t('statementDetails.transaction-type-merchant-service-fees');
            case InvoiceTransactionTypeDto.TransactionFee:
                return this.$t('statementDetails.transaction-type-merchant-transaction-fees');

            default:
                return InvoiceTransactionTypeDto[type];
        }
    }

    async mounted() {
        // Make rows collapsed by default
        if (!this.allRowsCollapsed()) {
            this.collapseRows();
        }
    }
}
