









import BasicLayout from '@/components/layouts/BasicLayout.vue';
import StatementCsvDialog from '@/components/statements/StatementCsvDialog.vue';
import Statements from '@/components/statements/Statements.vue';
import { StatementSearchOptions } from '@/store/statement/statement-models';
import { statementStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'basic-layout': BasicLayout,
    'statement-csv-dialog': StatementCsvDialog,
    'statements': Statements,
  }
})
export default class StatementsPage extends Vue {
  get lastSearchOptions(): StatementSearchOptions {
    return statementStore.lastSearchOptions;
  }
}
