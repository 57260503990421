






import { Component, Vue } from 'vue-property-decorator';

/**
 * Routing page for Statements
 */
@Component
export default class StatementsMainPage extends Vue {}
