
























import configuration from '@/configuration';
import moment from 'moment-timezone';
import { Refund } from '@/store/order/order-models';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RefundsTable extends Vue {
  @Prop({ required: true })
  refunds: Refund[];

  get noRefunds(): boolean {
    return !this.refunds.length;
  }

  /** Display the date, in Utc using the configuration.dateFormat */
  displayDate(date: any) {
    return moment(date).utc().format(configuration.dateFormat);
  }
}
