






import NotFound from '@/components/error/NotFound.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'not-found': NotFound,
  },
})
export default class NotFoundPage extends Vue {}
