






import { Component, Vue } from 'vue-property-decorator';
import BasicLayout from '@/components/layouts/BasicLayout.vue';
import Updates from '@/components/updates/Updates.vue';

@Component({
  components: {
    'basic-layout': BasicLayout,
    'updates': Updates
  }
})
export default class UpdatesPage extends Vue {}
