import LogMethod from '@/decorators/logger-decorator';
import store from '@/store/store';
import moment from 'moment';
import {
  config,
  Action,
  Module,
  VuexModule,
  Mutation,
} from 'vuex-module-decorators';
import {
  AggregatedOrdersResult,
  AggregatedTransactionsResult,
  CustomerDataTypeEnum,
  AggregatedCustomersResult,
  AggregatedCustomer,
  AggregatedEntry,
  ComparableAggregatedResult
} from './insights-models';
import { getObject } from '../store-requests';

// Set rawError for all Actions in module to true
config.rawError = true;

const DefaultComparableAggregatedResult = {
  current: {
    startDate: new Date(),
    endDate: new Date(),
    data: [],
  }
};

/**
 * The insights store is responsible for managing insights data and business logic
 */
@Module({
  name: 'insights',
  namespaced: true,
  store,
})
export default class InsightsStore extends VuexModule {
  aggregatedOrdersResult: ComparableAggregatedResult | undefined = DefaultComparableAggregatedResult;
  aggregatedTransactionsResult: ComparableAggregatedResult | undefined = DefaultComparableAggregatedResult;
  aggregatedCustomersResult: ComparableAggregatedResult | undefined = DefaultComparableAggregatedResult;
  loadingCustomers = false;
  loadingOrders = false;
  loadingTransactions = false;

  @Mutation
  setLoadingCustomers(loading: boolean) {
    this.loadingCustomers = loading;
  }

  @Mutation
  setLoadingOrders(loading: boolean) {
    this.loadingOrders = loading;
  }

  @Mutation
  setLoadingTransactions(loading: boolean) {
    this.loadingTransactions = loading;
  }

  @Mutation
  setAggregatedTransactionsResult(result?: ComparableAggregatedResult) {
    InsightsStore.reconcileEndDate(result);
    this.aggregatedTransactionsResult = result;
  }

  @Mutation
  setAggregatedOrdersResult(result?: ComparableAggregatedResult) {
    InsightsStore.reconcileEndDate(result);
    this.aggregatedOrdersResult = result;
  }

  @Mutation
  setAggregatedCustomersResult(result?: ComparableAggregatedResult) {
    InsightsStore.reconcileEndDate(result);
    this.aggregatedCustomersResult = result;
  }

  /**
   * Get AggregatedOrders for order related insights charts
   */
  @Action
  async loadAggregatedOrders(args: { startDate: string, endDate: string, loadPreviousYearData: boolean }): Promise<any> {
    this.setLoadingOrders(true);

    const cd = this.getAggregatedOrdersInternal({ startDate: args.startDate, endDate: args.endDate });

    let pd = Promise.resolve(undefined as AggregatedOrdersResult | undefined);
    if (args.loadPreviousYearData) {
      const { start, end } = InsightsStore.getComparisonDates(args.startDate, args.endDate);
      pd = this.getAggregatedOrdersInternal({ startDate: start, endDate: end });
    }

    const results = await Promise.all([cd, pd]);
    this.setAggregatedOrdersResult({ current: results[0], previous: results[1] });

    this.setLoadingOrders(false);
  }

  @Action
  private async getAggregatedOrdersInternal(args: { startDate: string, endDate: string }): Promise<AggregatedOrdersResult | undefined> {
    const url = {
      service: 'insights/aggregated-orders',
      query: args
    };

    return getObject({
      url,
      options: {
        dataType: `orders data`
      }
    });
  }

  /**
   * Get AggregatedTransactions for transaction related insights charts
   */
  @Action
  async loadAggregatedTransactions(args: { startDate: string, endDate: string, loadPreviousYearData: boolean }): Promise<any> {
    this.setLoadingTransactions(true);

    const cd = this.getAggregatedTransactionsInternal({ startDate: args.startDate, endDate: args.endDate });

    let pd = Promise.resolve(undefined as AggregatedTransactionsResult | undefined);
    if (args.loadPreviousYearData) {
      const { start, end } = InsightsStore.getComparisonDates(args.startDate, args.endDate);
      pd = this.getAggregatedTransactionsInternal({ startDate: start, endDate: end });
    }

    const results = await Promise.all([cd, pd]);
    this.setAggregatedTransactionsResult({ current: results[0], previous: results[1] });

    this.setLoadingTransactions(false);
  }
 
   @Action
   private async getAggregatedTransactionsInternal(args: { startDate: string, endDate: string }): Promise<AggregatedTransactionsResult | undefined> {
    const url = {
      service: 'insights/aggregated-transactions',
      query: args
    };
 
     return getObject({
      url,
      options: {
        dataType: `transactions data`
      }
    });
   }

   /**
   * Get AggregatedCustomers for customer related insights charts
   */
  @Action
  async loadAggregatedCustomers(args: { startDate: string, endDate: string, type?: CustomerDataTypeEnum, loadPreviousYearData: boolean }): Promise<any> {
    this.setLoadingCustomers(true);

    const cd = this.getAggregatedCustomersInternal({ startDate: args.startDate, endDate: args.endDate });

    let pd = Promise.resolve(undefined as AggregatedCustomersResult | undefined);
    if (args.loadPreviousYearData) {
      const { start, end } = InsightsStore.getComparisonDates(args.startDate, args.endDate);
      pd = this.getAggregatedCustomersInternal({ startDate: start, endDate: end });
    }

    const results = await Promise.all([cd, pd]);
    this.setAggregatedCustomersResult({ current: results[0], previous: results[1] });

    this.setLoadingCustomers(false);
  }
 
  @Action
  private async getAggregatedCustomersInternal(args: { startDate: string, endDate: string, type?: CustomerDataTypeEnum }): Promise<AggregatedCustomersResult | undefined> {
    const url = {
      service: 'insights/aggregated-customers',
      query: args
    };

    return getObject({
      url,
      options: {
        dataType: `customers data`
      }
    });
  }

  /*
  * Utility Functions - Called by individual charts
  */
  static async createKeyValueArrays(arr: Array<AggregatedEntry>, startDate: Date, endDate: Date, getValueFunc: (entry?: any) => number) {
    const keys = new Array<Date>();
    const values = new Array<number>();
    let index = 0;
    // Dates could be a string. Parse them again to prevent bad comparison.
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    for (let curDate = new Date(startDate); curDate <= endDate; curDate.setDate(curDate.getDate() + 1)) {
      keys.push(new Date(curDate));
      let matched = false;
      if (index < arr.length) {
        if (new Date(arr[index].date).setHours(0,0,0,0) === curDate.setHours(0,0,0,0)) {
          values.push(getValueFunc(arr[index]));
          matched = true;
        }
      }
      if (matched) {
        index++;
      }
      else {
        values.push(getValueFunc(null));
      }
      await InsightsStore.sleep(); // Don't stall UI!!
    }
    return { keys, values };
  }

  static async createKeyValueArraysByCategory(arr: Array<AggregatedCustomer>, type: CustomerDataTypeEnum, startDate: Date, endDate: Date) {
    // Expensive multi-pass looping!!
    // Optimize it to use single pass looping!!
    const data = arr.filter(entry => entry.type === type);
    const categories = new Array<string>();

    let count = 0;
    for (const entry of data) {
      if (!categories.find(s => s === entry.category)) {
        categories.push(entry.category);
      }
      count++;
      if (count % 10 === 0) {
        await InsightsStore.sleep(); // Don't stall UI!!
      }
    }

    let keys = new Array<Date>();
    const values = new Array<Array<number>>();
    for (const c of categories) {
      const r = await InsightsStore.createKeyValueArrays(data.filter(entry => entry.category === c), startDate, endDate, entry => entry?.entityCount ?? 0);
      values.push(r.values);
      if (keys.length === 0) {
        keys = r.keys;
      }
    }
    return { keys, categories, values };
  }

  static async aggregateByCategory(arr: Array<AggregatedCustomer>, type: CustomerDataTypeEnum, labels?: Array<string>) {
    // NOTE: the sorting of category value is done at the back end!!
    const keys = labels ?? new Array<string>();
    const values = new Array<number>();

    for (const entry of arr.filter(entry => entry.type === type)) {
      // These are the entries with the correct type requested, now create the aggregates
      let index;
      if (!keys.find((k, i) => {
          if (k === entry.category) {
            index = i;
            return true;
          }
          return false;
        })) {
        index = keys.push(entry.category);
      }
      if (values.length > index) {
        values[index] = values[index] + entry.entityCount;
      }
      else {
        values.push(entry.entityCount);
      }
      await InsightsStore.sleep(); // Don't stall UI!!
    }

    return { keys, values };
  }

  private static reconcileEndDate(result?: ComparableAggregatedResult) {
    // NOTE: this is needed as backend is exclusive, so need to subtract one day
    if (result?.current?.endDate) {
      result.current.endDate = new Date(moment.utc(result.current.endDate).subtract(1, "day").toISOString());
    }
    if (result?.previous?.endDate) {
      result.previous.endDate = new Date(moment.utc(result.previous.endDate).subtract(1, "day").toISOString());
    }
  }

  private static getComparisonDates(startDate: string, endDate: string) {
    // This handles leap years. We want to fetch the same number of data points for the previous year.
    const days = moment(endDate).diff(moment(startDate), "days");

    const end = moment.utc(endDate, "YYYY-MM-DD").subtract(1, 'year').startOf("day").toISOString();
    const start = moment.utc(end).subtract(days, 'days').startOf("day").toISOString();

    return { start, end };
  }

  static async sleep(ms = 0) {
    return new Promise(res => setTimeout(res, ms));
  }

  @Mutation
  @LogMethod
  reset() {
    this.aggregatedCustomersResult = DefaultComparableAggregatedResult;
    this.aggregatedOrdersResult = DefaultComparableAggregatedResult;
    this.aggregatedTransactionsResult = DefaultComparableAggregatedResult;
    this.loadingCustomers = false;
    this.loadingOrders = false;
    this.loadingTransactions = false;
  }
}