























import { Component, Emit, Vue } from 'vue-property-decorator';
import { DisputeProductServiceTypeEnum } from '@/store/dispute/dispute-models';
import DisputeDetailsSection from '../DisputeDetailsSection.vue';
import ActionButton from '@/components/controls/ActionButton.vue';
import { DefaultValidatorArray, RequiredFieldValidator } from '@/validators';

@Component({
  components: {
    'action-button': ActionButton,
    'dispute-details-section': DisputeDetailsSection
  },
})
export default class DisputeProductServiceSection extends Vue {
  requiredTextField = DefaultValidatorArray;
  requiredRadioField = DefaultValidatorArray;
  description = '';
  productServiceType: string | null = null;

  get disputeProductServiceTypeItems() {
    // Exclude unspecified type
    const values: string[] = [];
    for (var n in DisputeProductServiceTypeEnum) {
      if (typeof DisputeProductServiceTypeEnum[n] === 'number' && n !== DisputeProductServiceTypeEnum[DisputeProductServiceTypeEnum.unspecified]) {
        values.push(n);
      }
    }
    return values.map(value => {
      return { value, label: this.getProductServiceType(DisputeProductServiceTypeEnum[value]) };
    });
  }

  getProductServiceType(type?: DisputeProductServiceTypeEnum) {
    switch (type) {
      case DisputeProductServiceTypeEnum.digitalProductOrService:
        return this.$t('disputeFormProductServiceSection.product-service-type-digital-product-or-service');
      case DisputeProductServiceTypeEnum.eventBookingOrReservation:
        return this.$t('disputeFormProductServiceSection.product-service-type-event-booking-or-reservation');
      case DisputeProductServiceTypeEnum.offlineService:
        return this.$t('disputeFormProductServiceSection.product-service-type-offline-service');
      case DisputeProductServiceTypeEnum.other:
        return this.$t('disputeFormProductServiceSection.product-service-type-other');
      case DisputeProductServiceTypeEnum.physicalProduct:
        return this.$t('disputeFormProductServiceSection.product-service-type-physical-product');
      default:
        return type;
    }
  }

  async created() {
    this.requiredTextField = RequiredFieldValidator;
    this.requiredRadioField = RequiredFieldValidator;
  }

  isNotEmptyString = (str: string): boolean => {
    return !!str && str.trim().length > 0;
  };

  @Emit('next')
  next() {
    return {
      description: this.description,
      productServiceType: DisputeProductServiceTypeEnum[this.productServiceType as string]
    };
  }
}
