



























































import logger from '@/logger';
import { authenticationStore } from '@/store/store';
import { TranslateResult } from 'vue-i18n';
import { Component, Prop, Vue } from 'vue-property-decorator';
/**
 * Component is used to center an image with a fixed width
 * in a flex container.
 */
@Component({
  components: { }
})
export default class SessionExpiringModal extends Vue {
  @Prop({ required: true })
  show: boolean;

  @Prop({ required: true })
  cancel: () => void;

  loading = false;
  done = false;

  title: TranslateResult = '';
  text: TranslateResult = '';

  dialogClass = 'qp-info';

  /**
   * Trigger authentication refresh
   */
  async refreshAuth(event: Event) {
    event.preventDefault();

    // UI idempotency so a user doesn't submit a form that's already been submitted
    if (this.loading) {
      return;
    }

    this.loading = true;

    try {
      await authenticationStore.refreshToken();
      this.text = this.$t('sessionExpiringModal.success');
      this.title = this.$t('sessionExpiringModal.title-success');
      this.dialogClass = 'qp-success';
    } catch(e) {
      logger.error('Error refreshing token: ', e);
      this.text = this.$t('sessionExpiringModal.failure');
      this.dialogClass = 'qp-error';
    } finally {
      this.loading = false;
      this.done = true;
    }
  }

  mounted() {
    // Initialize with i18n
    this.title = this.$t('sessionExpiringModal.title');
    this.text = this.$t('sessionExpiringModal.text');
  }
}
