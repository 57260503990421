

















import configuration from '@/configuration';
import MerchantIntegrationInformation from '@/components/common/MerchantIntegrationInformation.vue';
import SecuritySettings from '@/components/settings/SecuritySettings.vue';
import StripeSettings from '@/components/settings/StripeSettings.vue';
import { Component, Vue } from 'vue-property-decorator';
import TimezoneSettings from './TimezoneSettings.vue';
import SimpleView from '../layouts/SimpleView.vue';

@Component({
  components: {
    'simple-view': SimpleView,
    'merchant-integration-info': MerchantIntegrationInformation,
    'security-settings': SecuritySettings,
    'stripe-settings': StripeSettings,
    'timezone-settings': TimezoneSettings
  },
})
export default class Settings extends Vue {
  get showStripeConnect(): boolean {
    return configuration.stripe.stripeConnectEnabled;
  }
}
