















































































import SearchIcon from '@/assets/images/search.svg?inline';
import DoubleDatePicker from '@/components/controls/DoubleDatePicker.vue';
import DisputesIcon from '@/assets/images/icons/sidebar/DisputesIconLarge.svg?inline';
import configuration from '@/configuration';
import moment from 'moment-timezone';
import { TranslateResult } from 'vue-i18n';
import { applicationStore, authenticationStore, disputeStore } from '@/store/store';
import { DataOptions, DataTableHeader } from 'vuetify';
import { Component, Watch, Vue } from 'vue-property-decorator';
import TableFooter from '../controls/TableFooter.vue';
import SearchView from '../layouts/SearchView.vue';
import DisputeStatus from './DisputeStatus.vue';
import DisputeReason from './DisputeReason.vue';
import { Dispute, DisputeDateFilterEnum, DisputeReconciledStatusEnum, DisputeStatusEnum } from '@/store/dispute/dispute-models';
import { disputeDetailsPageName } from '@/router';
import DisputeDateFilter from './DisputeDateFilter.vue';

@Component({
  components: {
    'search-view': SearchView,
    'double-date-picker': DoubleDatePicker,
    'disputes-icon': DisputesIcon,
    'search-icon': SearchIcon,
    'table-footer': TableFooter,
    'dispute-status': DisputeStatus,
    'dispute-reason': DisputeReason,
    'dispute-date-filter': DisputeDateFilter,
  }
})
export default class Disputes extends Vue {
  $refs!: {
    form: HTMLFormElement;
    dateRange: DoubleDatePicker;
  };

  // Allow skeleton loader to appear before table finishes loading (the first load only)
  firstLoad = true;

  statusFilter?: DisputeStatusEnum = DisputeStatusEnum.needsResponse;
  tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    sortBy: ['evidenceDueOn'],
    sortDesc: [true],
    groupBy: [],
    groupDesc: [],
    multiSort: false, // ability to sort by more than one column at once
    mustSort: true, // table must be sorted by at least one column
  };
  endDate = moment.utc().endOf('day').toISOString();
  startDate = moment.utc().subtract(3, 'month').startOf('month').toISOString();
  selectedStatus = this.getStatusString(this.statusFilter).toString();

  dateFilter?: DisputeDateFilterEnum = DisputeDateFilterEnum.disputeDate;
  selectedDateFilter = this.dateFilter;

  get loading(): boolean {
    return disputeStore.loadingDisputes;
  }

  get notificationFeatureFlag(): boolean {
    return configuration.featureFlags.transactionNotification;
  }

  get footerOptions(): any {
    return TableFooter.getDefaultOptions(key => this.$t(key));
  }

  get disputeArray(): Dispute[] {
    return disputeStore.disputeArray;
  }

  get disputeCount(): number {
    return disputeStore.disputeCount;
  }

  get tableHeaders(): DataTableHeader[] {
    return [
      {
        text: this.$t('disputes.id').toString(),
        value: 'id',
        sortable: false,
      },
      {
        text: this.$t('disputes.amount').toString(),
        value: 'amount',
        sortable: false,
        align: 'end',
      },
      {
        text: this.$t('disputes.status').toString(),
        value: 'status',
        sortable: false,
      },
      {
        text: this.$t('disputes.reason').toString(),
        value: 'reason',
        sortable: false,
      },
      {
        text: this.$t('disputes.customer-name').toString(),
        value: 'customerName',
        sortable: false,
      },
      {
        text: this.$t('disputes.order-id').toString(),
        value: 'orderId',
        sortable: false,
      },
      {
        text: this.$t('disputes.disputed-on').toString(),
        value: 'disputedOn',
      },
      {
        text: this.$t('disputes.evidence-due-on').toString(),
        value: 'evidenceDueOn',
      }
    ];
  }

  get currentLocale(): string {
    return applicationStore.currentLocale.locale;
  }

  get currentIdentity() {
    return authenticationStore.currentIdentity;
  }

  get statusFilterText(): TranslateResult {
    return this.$t('disputes.status-filter');
  }

  get disputeStatusItems() {
    const values: string[] = [];
    for (var n in DisputeStatusEnum) {
        if (typeof n === 'string') {
          values.push(n);
        }
    }
    return values.map(value => {
      return { value: DisputeStatusEnum[value], text: this.getStatusString(DisputeStatusEnum[value]) }
    });
  }

  /** Display the date, in Utc using the configuration.dateFormat */
  displayDateUtc(date?: Date): string {
    if (date) {
      return moment(date).utc().locale(this.currentLocale).format(configuration.dateFormat);
    }
    return '';
  }

  displayCustomerName(dispute) {
    return `${dispute.customerFirstName} ${dispute.customerLastName}`;
  }

  displayOrderNumber(dispute) {
    return dispute.orderNumber ?? dispute.paymentPlanId;
  }

  displayDisputeNumber(dispute) {
    return dispute.disputeId ?? dispute.id;
  }

  getStatusString(status?: DisputeStatusEnum): TranslateResult | string {
    return DisputeStatus.getStatusString(status === undefined ? undefined : DisputeReconciledStatusEnum[DisputeStatusEnum[status]]);
  }

  @Watch('currentIdentity')
  async updateSearch() {
    // Update results as identity changes
    // When logging out don't bother searching
    if (!this.currentIdentity?.merchant_id) {
      return;
    }
    await this.searchDisputes();
  }

  @Watch('tableOptions')
  async updateTable() {
    await this.searchDisputes();
  }

  async paginate(newPage) {
    this.tableOptions.page = newPage;
    await this.searchDisputes();
  }

  onStatusFilterChanged(status?: { value: DisputeStatusEnum }) {
    this.statusFilter = status?.value;

    this.searchDisputes(1);
  }

  onDateFilterChanged(value: DisputeDateFilterEnum) {
    this.dateFilter = value;

    this.searchDisputes(1);
  }

  async searchDisputes(pageNumber: number | null = null) {
    this.tableOptions.page = pageNumber || this.tableOptions.page;
    const { page, itemsPerPage, sortDesc, sortBy } = this.tableOptions;

    await disputeStore.searchDisputes({
      options: {
        status: this.statusFilter,
        dateType: this.dateFilter,
        page,
        pageSize: itemsPerPage,
        orderBy: sortDesc[0] ? `${sortBy[0]},desc` : `${sortBy[0]},asc`,
        startDate: this.startDate,
        endDate: this.endDate,
      },
      apiEndDate: DoubleDatePicker.getInclusiveEndDate(this.endDate)
    });

    this.setDisputesSearchParams();
  }

  async startDateUpdated(newDate: string) {
    this.startDate = moment.utc(newDate, "YYYY-MM-DD").startOf("day").toISOString();
    await this.searchDisputes(1);
  }

  async endDateUpdated(newDate: string) {
    this.endDate = moment.utc(newDate, "YYYY-MM-DD").endOf("day").toISOString();
    await this.searchDisputes(1);
  }

  async mounted() {
    // Check query params
    if (this.$route.query.page && !isNaN(parseInt(this.$route.query.page as string, 10))) this.tableOptions.page = parseInt(this.$route.query.page as string, 10);
    if (this.$route.query.pageSize && !isNaN(parseInt(this.$route.query.pageSize as string, 10))) this.tableOptions.itemsPerPage = parseInt(this.$route.query.pageSize as string, 10);
    if (this.$route.query.status && Object.values(DisputeStatusEnum).includes(this.$route.query.status as DisputeStatusEnum)) {
      this.statusFilter = this.$route.query.status as DisputeStatusEnum;
      this.selectedStatus = this.getStatusString(DisputeStatusEnum[this.statusFilter])?.toString();
    }
    if (this.$route.query.date && Object.values(DisputeDateFilterEnum).includes(this.$route.query.date as DisputeDateFilterEnum)) {
      this.dateFilter = this.$route.query.date as DisputeDateFilterEnum;
      this.selectedDateFilter = this.dateFilter;
    }
    if (this.$route.query.desc) this.tableOptions.sortDesc[0] = this.$route.query.desc === 'true';
    if (this.$route.query.sortBy) this.tableOptions.sortBy[0] = this.$route.query.sortBy as string;

    // Disable skeleton loader
    this.firstLoad = false;
  }

  async navigateDispute(dispute: Dispute) {
    this.$router.push({ name: disputeDetailsPageName, params: { disputeId: dispute.id ?? '' } });
  }

  setDisputesSearchParams() {
    const url = new URL(window.location.href);

    let params = new URLSearchParams('');
    
    if(this.statusFilter) {
      params.set('status', this.statusFilter as string);
    }
    params.set('page', this.tableOptions.page.toString());
    params.set('pageSize', this.tableOptions.itemsPerPage.toString());
    params.set('desc', this.tableOptions.sortDesc[0].toString());
    params.set('sortBy', this.tableOptions.sortBy[0]);

    
    url.search = '' + params;

    // Must use history push to ensure the page is not reloaded
    history.pushState({}, '', url.toString());
  }
}
