




























import ErrorImage from '@/assets/images/error.svg?inline';
import SimpleCopyrightFooter from '@/components/common/SimpleCopyrightFooter.vue';
import SimpleLogoHeader from '@/components/common/SimpleLogoHeader.vue';
import configuration from '@/configuration';
import logger from '@/logger';
import { Component, Vue } from 'vue-property-decorator';

/**
 * Component used to denote errors returned from the server.  It can be used
 * to convey a number of expected and unexpected error flows to our customers.
 */
@Component({
  components: {
    'error-image': ErrorImage,
    'simple-footer': SimpleCopyrightFooter,
    'simple-header': SimpleLogoHeader,
  },
})
export default class Error extends Vue {
  loading = false;
  /**
   * Get the Error ID for the unhandled error to present to the user.  They can share
   * this with customer support to help the QP team track down what happened.
   */
  get errorId(): string {
    return this.$route.params.errorId;
  }

  get companyName(): string {
    return configuration.company.name;
  }

  /**
   * Return to the previous page to attempt the action again.
   */
  tryAgain(event: Event) {
    event.preventDefault();

    // UI idempotency in case a user clicks more than once
    if (this.loading) return;

    this.loading = true;
    const routeName = this.$route.meta.backRouteName;
    if (routeName) {
      this.$router.push({ name: routeName }).catch(err => {
        // Ignore the vuex err regarding navigating to the page they are already on.
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // Log any other errors
          logger.error(err);
        }
        this.loading = false;
      });
    } else {
      this.$router.go(-1);
    }
  }
}
