import {
  SupportedCountry,
  Territory,
} from '@/store/application/application-models';
/**
 * This defines environments and their specific configuration overrides.  Overrides can be driven
 * by matching node environments or matching window hostnames.
 */
interface EnvironmentConfiguration {
  name: 'Local' | 'Local-GB' | 'Local Prod' | 'Local-MX' | 'Local-SG' |
  'E2E Test' | 'Unit Test' |
  'Development' | 'Sandbox' |'Production' |
  'Development-GB' | 'Sandbox-GB' |  'Production-GB' |
  'Development-MX' | 'Sandbox-MX' |  'Production-MX' |
  'Development-SG' | 'Sandbox-SG' |  'Production-SG' ;
  /**
   * Hostnames always should be lower case.
   */
  hostnames?: string[];
  nodeEnv?: 'e2e-test' | 'local-prod' | 'local-gb' | 'local-mx' | 'local-sg' | 'test';
  overrideConfiguration?: () => void;
  applyPreviewConfiguration?: () => void;
}

/**
 * This is the base or default configuration that applies to local and all non-standard
 * environments.
 */
const configuration = {
  address: {
    includeState: true
  },
  company: {
    name: 'Zip',
    companyFormalName: 'Zip Co.',
  },
  dateFormat: 'MMM D, YYYY',
  dateTimeFormat: 'MMM D, YYYY HH:mm',
  csvDateFormat: 'MM/DD/YYYY',
  timeFormat: 'hh:mm:ss A z',
  dateAndTimeFormat: ' MMM D, YYYY hh:mm:ss A z',
  isVatEnabled: false,
  environmentName: process.env.NODE_ENV,
  isUnitTestEnvironment: false,
  segmentKey: 'dQxGk3CA3SrT2C22QBVDTPxiqEglcnLL',
  serviceDomains: ['quadpay.com', 'quadpay.xyz'],
  supportEmail: 'partners@us.zip.co',
  auth: {
    audience: 'https://merchants-auth-dev.quadpay.com',
    clientId: 'B3OBCpBobt3doU6W9ZkKELsjShVyJKOY',
    domain: 'merchant-login.dev.us.zip.co',
    // https://auth0.com/docs/libraries/auth0js/v9#initialization
    leeway: 5,
  },
  contentful: {
    // Quadpay > Merchants space.  Make changes using pull requests in [this repo](https://github.com/the-urge-tech/contentful-terraforms).  Chat to Andre Susanto for access
    spaceId: 'l6e5nwwfjxup',
    environment: 'staging',
    contentDeliveryToken: 'QXdimRpTSbX_bz4-_h35qvvZMvWJ9uIvxb0sqwu4H_o',
    contentPreviewToken: 'dRzzA7SBvuCx3E-xhimGm_NiY-dgGdcbniJPQKS7ZD4',
  },
  datadog: {
    applicationId: 'df40ee06-3443-4acd-8f44-219fd9497017',
    clientToken: 'pub65ec2e1e9da358768c358d6d5779ec50',
    disableFs: false,
  },
  debug: {
    appLogEnabled: true,
    segment: false,
    vuexStrict: false,
    fullstory: false,
    optimizely: false,
  },
  featureFlags: {
    datadog: true,
    directPageAccess: false,
    segment: true,
    sessionExpiration: true,
    fullstory: true,
    optimizelyEnabled: true,
    intercom: true,
    disputes: false,
    statements: false,
    insights: false,
    transactions: true, // legacy transaction pages
    transactionNotification: true,
    csvV2Enabled: false,
    csvV2NoDateLimitMerchants: [
      '5419535A-69CC-44C6-9FF7-F14A9E7C7FC4',
      'D09260F1-6E69-4337-8E5F-1164B385F485',
      '7CBDA528-35BD-4D16-BC14-9F62D7B17845'
    ]
  },
  google: {
    mapsAutocompleteApiKey: 'AIzaSyDZkSmtwHGLcF5X6G0y_Ts_JX7Derr9K6I',
  },
  intercom: {
    applicationId: 'nzbattsv',
    optimizely: {
      key: 'intercom_mss',
      events: {
        loaded: 'intercom_mss_loaded',
        integrationPageViewed: 'merchant-integration-page',
        integrationTestPageViewed: 'merchant-integration-test-page'
      }
    }
  },
  statements: {
    taxInfoEnabledTerritories: ['GB'],
    optimizely: {
      showStatementPagesKey: 'merchant_force_show_statement_pages',
      displayTaxInfoKey: 'merchant_force_display_tax_info',
      hideTransactionPagesKey: 'merchant_force_hide_transaction_pages'
    }
  },
  insights: {
    optimizely: {
      showInsightPagesKey: 'merchant_force_show_insight_pages'
    }
  },
  disputes: {
    allowEvidenceStaging: false,
    optimizely: {
      showDisputePagesKey: 'merchant_force_show_dispute_pages'
    }
  },
  links: {
    apiDomain: 'https://master.branch.quadpay.xyz/',
    shopifyIntegrationVideoLink: 'https://www.youtube.com/embed/YuhO-2Bce1s',
    msaTermsAndConditionsStandardLink: 'https://assets.quadpay.com/assets/msas/Zip_US_Merchant_Services_Agreement_18_Aug_2021.pdf',
    msaTermsAndConditionsHairLink: 'https://assets.quadpay.com/assets/msas/Zip_US_Merchant_Services_Agreement_18_Aug_2021.pdf',
    privacyLink: 'https://zip.co/us/privacy-policy',
    customerTermsLink: 'https://zip.co/us/quadpay-terms-of-service',
    merchantTermsLink: 'https://assets.quadpay.com/assets/msas/Zip_US_Merchant_Services_Agreement_18_Aug_2021.pdf',
    merchantHelpCenter: 'https://merchant-help.us.zip.co/hc/en-us',
    disputesAndRefundsLink: 'https://merchant-help.us.zip.co/hc/en-us/articles/10252017432475',
    respondingToDisputeLink: 'https://merchant-help.us.zip.co/hc/en-us/articles/9381791717659-Responding-to-disputes',
    preventingDisputesLink: 'https://merchant-help.us.zip.co/hc/en-us/articles/9383186562459-Best-practices-for-preventing-disputes',
  },
  stripe: {
    stripeClientId: 'ca_BO1C0mXmozYeitcsDBCKVzXcAEgbNGX4',
    stripeClientIdDev: 'ca_BO1CWHu6KY48cVOu1nzLVLcGFWLggEYC',
    stripeConnectBaseUrl: 'https://connect.stripe.com/express/oauth/authorize',
    stripeConnectRedirectUrl: 'https://redirect',
    stripeConnectEnabled: true,
  },
  supportedCountries: [
    Object.freeze({
      code: 'US',
      displayName: 'United States',
      currencyCode: 'USD',
      currencySymbol: '$',
      defaultMinTransaction: '35',
      defaultMaxTransaction: '1500',
      minPhoneNumberLength: 7,
      maxPhoneNumberLength: 11,
      supportedLocales: [
        {
          locale: 'en-US',
          language: 'en',
          displayName: 'English',
        },
      ],
      territories: [Territory.US],
      supportedTimezones: [
        {
          utcOffset: 0,
          code: "UTC"
        },
        {
          utcOffset: -5,
          code: "EST"
        },
        {
          utcOffset: -4,
          code: "EDT"
        },
        {
          utcOffset: -6,
          code: "CST"
        },
        {
          utcOffset: -5,
          code: "CDT"
        },
        {
          utcOffset: -7,
          code: "MST"
        },
        {
          utcOffset: -6,
          code: "MDT"
        },
        {
          utcOffset: -8,
          code: "PST"
        },
        {
          utcOffset: -7,
          code: "PDT"
        },
        {
          utcOffset: -10,
          code: "HST"
        },
        {
          utcOffset: -9,
          code: "HDT"
        },
      ]
    }),
    Object.freeze({
      code: 'CA',
      displayName: 'Canada',
      currencyCode: 'CAD',
      currencySymbol: '$',
      defaultMinTransaction: '35',
      defaultMaxTransaction: '1500',
      minPhoneNumberLength: 7,
      maxPhoneNumberLength: 11,
      supportedLocales: [
        {
          locale: 'en-CA',
          language: 'en',
          displayName: 'English',
        },
        {
          locale: 'fr-CA',
          language: 'fr',
          displayName: 'Français',
        },
      ],
      supportedTimezones: [
        {
          utcOffset: 0,
          code: "UTC"
        },
        {
          utcOffset: -4,
          code: "AST"
        },
        {
          utcOffset: -3,
          code: "ADT"
        },
        {
          utcOffset: -5,
          code: "EST"
        },
        {
          utcOffset: -4,
          code: "EDT"
        },
        {
          utcOffset: -6,
          code: "CST"
        },
        {
          utcOffset: -5,
          code: "CDT"
        },
        {
          utcOffset: -7,
          code: "MST"
        },
        {
          utcOffset: -6,
          code: "MDT"
        },
        {
          utcOffset: -8,
          code: "PST"
        },
        {
          utcOffset: -7,
          code: "PDT"
        },
        {
          utcOffset: -2.5,
          code: "NDT"
        },
      ],
      territories: [Territory.CA],
    }),
    Object.freeze({
      code: 'GB',
      displayName: 'United Kingdom',
      currencyCode: 'GBP',
      currencySymbol: '£',
      defaultMinTransaction: '0',
      defaultMaxTransaction: '1500',
      minPhoneNumberLength: 7,
      maxPhoneNumberLength: 11,
      supportedLocales: [
        {
          locale: "en-GB",
          language: "en",
          displayName: "English",
        },
      ],
      supportedTimezones: [
        {
          utcOffset: 0,
          code: "GMT"
        },
        {
          utcOffset: 1,
          code: "BST"
        },
      ],
      territories: [Territory.GB],
    }),
    Object.freeze({
      code: 'MX',
      displayName: 'Mexico',
      currencyCode: 'MXN',
      currencySymbol: '$',
      defaultMinTransaction: '0',
      defaultMaxTransaction: '1500',
      minPhoneNumberLength: 7,
      maxPhoneNumberLength: 11,
      supportedLocales: [
        {
          locale: "es-MX",
          language: "es",
          displayName: "Spanish",
        },
      ],
      supportedTimezones: [
        {
          utcOffset: 0,
          code: "UTC"
        },
        {
          utcOffset: -5,
          code: "EST"
        },
        {
          utcOffset: -4,
          code: "EDT"
        },
        {
          utcOffset: -6,
          code: "CST"
        },
        {
          utcOffset: -5,
          code: "CDT"
        },
        {
          utcOffset: -7,
          code: "MST"
        },
        {
          utcOffset: -6,
          code: "MDT"
        },
        {
          utcOffset: -8,
          code: "PST"
        },
        {
          utcOffset: -7,
          code: "PDT"
        },
      ],
      territories: [Territory.MX],
    }),
    Object.freeze({
      code: 'SG',
      displayName: 'Singapore',
      currencyCode: 'SGD',
      currencySymbol: '$',
      defaultMinTransaction: '0',
      defaultMaxTransaction: '1500',
      minPhoneNumberLength: 8,
      maxPhoneNumberLength: 8,
      supportedLocales: [
        {
          locale: "en-SG",
          language: "en",
          displayName: "English",
        },
      ],
      supportedTimezones: [
        {
          utcOffset: 0,
          code: "UTC"
        },
        {
          utcOffset: 8,
          code: "SGT"
        },
      ],
      territories: [Territory.SG],
    }),
  ] as SupportedCountry[],
  legacyOptimizelySdkKey: 'Q59obBtREGSA85kQhG7w8A',
  optimizelySdkKey: 'Aw1bx3q4xwfwHKNGfzQAZ',
};

const environments: EnvironmentConfiguration[] = [
  {
    name: 'Local',
    hostnames: ['localhost'],
    overrideConfiguration: () => {
      configuration.environmentName = "Local";
      configuration.debug.optimizely = true;
      configuration.featureFlags.disputes = true;
      configuration.featureFlags.statements = true;
      configuration.featureFlags.insights = true;
      configuration.featureFlags.csvV2Enabled = true;
      // Useful flags for development
      // configuration.featureFlags.directPageAccess = true;
      // configuration.featureFlags.sessionExpiration = false;
    }
  },
  {
    name: 'Local-GB',
    nodeEnv: 'local-gb',
    overrideConfiguration: () => {
      configuration.debug.optimizely = false;
      // Content
      configuration.dateFormat = "MMM D, YYYY";
      configuration.address.includeState = false;
      configuration.csvDateFormat = "DD/MM/YYYY";
      configuration.environmentName = "Local";
      configuration.serviceDomains = ['zip.co', 'zip.xyz'];
      configuration.supportEmail = 'noreply@zip.co';
      configuration.isVatEnabled = true;
      // Services
      // Auth
      configuration.auth.audience = "https://auth-merchant.dev.gb.zip.co";
      configuration.auth.clientId = "r0K7o1FOQPe0PV89YmRmx39kYAvOSI0s";
      configuration.auth.domain = "merchant-login.dev.gb.zip.co";
      // Links
      configuration.links.apiDomain = "https://api.dev.gb.zip.co/";
      configuration.links.shopifyIntegrationVideoLink = "https://www.youtube.com/embed/YuhO-2Bce1s";
      configuration.links.msaTermsAndConditionsStandardLink = "https://zip.co";
      configuration.links.msaTermsAndConditionsHairLink = "https://zip.co";
      configuration.links.privacyLink = "https://zip.co";
      configuration.links.customerTermsLink = "https://zip.co";
      // Stripe
      configuration.stripe.stripeClientId = "ca_IUKpKIalmbS6ADAiRPoMoUgYfX2ZVBtC";
      configuration.stripe.stripeClientIdDev = "ca_IUKpBKDZ5u5CyTwDu0jSfXiFMgDb67ZT";
      configuration.stripe.stripeConnectBaseUrl = "https://connect.stripe.com/express/oauth/authorize";
      configuration.stripe.stripeConnectRedirectUrl = "https://redirect";
    }
  },
  {
    name: 'Local-MX',
    nodeEnv: 'local-mx',
    overrideConfiguration: () => {
      configuration.debug.optimizely = false;
      // Content
      configuration.dateFormat = "MMM D, YYYY";
      configuration.address.includeState = false;
      configuration.csvDateFormat = "DD/MM/YYYY";
      configuration.environmentName = "Local-MX";
      configuration.serviceDomains = ['zip.co', 'zip.xyz'];
      configuration.supportEmail = 'mx@partners.zip.co';
      configuration.isVatEnabled = true;
      // Services
      // Auth
      configuration.auth.audience = "https://auth-merchant.dev.mx.zip.co";
      configuration.auth.clientId = "9Xk85M6Pv3aR6ST9iI3R0PJQuki4JBMG";
      configuration.auth.domain = "merchant-login.dev.mx.zip.co";
      // Links
      configuration.links.apiDomain = "https://api.dev.mx.zip.co/";
      configuration.links.shopifyIntegrationVideoLink = "https://www.youtube.com/embed/YuhO-2Bce1s";
      configuration.links.msaTermsAndConditionsStandardLink = "https://zip.co";
      configuration.links.msaTermsAndConditionsHairLink = "https://zip.co";
      configuration.links.privacyLink = "https://zip.co/es-mx/politicas-de-privacidad";
      configuration.links.customerTermsLink = "https://zip.co/es-mx/terminos-y-condiciones";
      // Stripe
      configuration.stripe.stripeClientId = "ca_IUKpKIalmbS6ADAiRPoMoUgYfX2ZVBtC";
      configuration.stripe.stripeClientIdDev = "ca_IUKpBKDZ5u5CyTwDu0jSfXiFMgDb67ZT";
      configuration.stripe.stripeConnectBaseUrl = "https://connect.stripe.com/express/oauth/authorize";
      configuration.stripe.stripeConnectRedirectUrl = "https://redirect";
    }
  },
  {
    name: 'Local Prod',
    nodeEnv: 'local-prod',
    overrideConfiguration: () => {
      configuration.environmentName = "Local";
      configuration.featureFlags.disputes = true;
      configuration.contentful.environment = 'master';
      configuration.contentful.contentDeliveryToken = 'MRd6K6FgaB413cwi4IWgFdZwF1eTrRnnCihblfC9qIY';
      configuration.contentful.contentPreviewToken = 'Z60ImCurByqY4Xeye9Xs1g-noPLV0vXjBFdNTXwoUao';
    }
  },
  {
    name: 'E2E Test',
    nodeEnv: 'e2e-test',
    overrideConfiguration: () => {
      configuration.environmentName = "E2E Test";
      configuration.featureFlags.intercom = false;
      configuration.featureFlags.segment = false;
    }
  },
  {
    name: 'Unit Test',
    nodeEnv: 'test',
    overrideConfiguration: () => {
      configuration.debug.appLogEnabled = false;
      configuration.environmentName = "Unit Test";
      configuration.featureFlags.intercom = false;
      configuration.featureFlags.segment = false;
      configuration.isUnitTestEnvironment = true;
      configuration.datadog.disableFs = true;
      configuration.debug.optimizely = true;
      configuration.featureFlags.statements = true;
      configuration.featureFlags.fullstory = false;
      configuration.featureFlags.disputes = true;
      configuration.featureFlags.csvV2Enabled = true;
    }
  },
  {
    name: 'Development',
    hostnames: ['merchant-portal-development.quadpay.xyz', 'merchant.dev.us.zip.co', 'merchant.dev.ca.zip.co'],
    overrideConfiguration: () => {
      configuration.environmentName = "Development";
      configuration.google.mapsAutocompleteApiKey = 'AIzaSyBazTUhhuTpdP_lfm1gHNk16JM9-u-Sl3E';
      configuration.legacyOptimizelySdkKey = 'S6fJ9TEtTv9erZiLjhNCYv';
      configuration.disputes.allowEvidenceStaging = true;
      configuration.featureFlags.disputes = true;
      configuration.featureFlags.csvV2Enabled = true;
      configuration.featureFlags.statements = true;
      configuration.featureFlags.insights = true;
    }
  },
  {
    name: 'Development-GB',
    hostnames: ['merchant.dev.gb.zip.co'],
    overrideConfiguration: () => {
      configuration.debug.optimizely = true;
      // Content
      configuration.dateFormat = "MMM D, YYYY";
      configuration.address.includeState = false;
      configuration.csvDateFormat = "DD/MM/YYYY";
      configuration.environmentName = "Development";
      configuration.serviceDomains = ['zip.co', 'zip.xyz'];
      configuration.supportEmail = 'noreply@zip.co';
      configuration.isVatEnabled = true;
      configuration.google.mapsAutocompleteApiKey = 'AIzaSyBazTUhhuTpdP_lfm1gHNk16JM9-u-Sl3E';
      // Services
      // Auth
      configuration.auth.audience = "https://auth-merchant.dev.gb.zip.co";
      configuration.auth.clientId = "W77aPjsZahoOw447o9wuXwG54YIXaY1l";
      configuration.auth.domain = "merchant-login.dev.gb.zip.co";
      // Links
      configuration.links.apiDomain = "https://api.dev.gb.zip.co/";
      configuration.links.shopifyIntegrationVideoLink = "https://www.youtube.com/embed/YuhO-2Bce1s";
      configuration.links.msaTermsAndConditionsStandardLink = "https://zip.co";
      configuration.links.msaTermsAndConditionsHairLink = "https://zip.co";
      configuration.links.privacyLink = "https://zip.co";
      configuration.links.customerTermsLink = "https://zip.co";
      // Stripe
      configuration.stripe.stripeClientId = "ca_IUKpKIalmbS6ADAiRPoMoUgYfX2ZVBtC";
      configuration.stripe.stripeClientIdDev = "ca_IUKpBKDZ5u5CyTwDu0jSfXiFMgDb67ZT";
      configuration.stripe.stripeConnectBaseUrl = "https://connect.stripe.com/express/oauth/authorize";
      configuration.stripe.stripeConnectRedirectUrl = "https://redirect";
    }
  },
  {
    name: 'Development-MX',
    hostnames: ['merchant.dev.mx.zip.co'],
    overrideConfiguration: () => {
      // Feature flag
      configuration.featureFlags.intercom = false;

      configuration.debug.optimizely = true;
      // Content
      configuration.dateFormat = "MMM D, YYYY";
      configuration.csvDateFormat = "DD/MM/YYYY";
      configuration.environmentName = "Development";
      configuration.serviceDomains = ['zip.co'];
      configuration.supportEmail = 'mx@partners.zip.co';
      configuration.isVatEnabled = false;
      configuration.google.mapsAutocompleteApiKey = 'AIzaSyBazTUhhuTpdP_lfm1gHNk16JM9-u-Sl3E';
      // Services
      // Auth
      configuration.auth.audience = "https://auth-merchant.dev.mx.zip.co";
      configuration.auth.clientId = "9Xk85M6Pv3aR6ST9iI3R0PJQuki4JBMG";
      configuration.auth.domain = "merchant-login.dev.mx.zip.co";
      // Links
      configuration.links.apiDomain = "https://api.dev.mx.zip.co/";
      configuration.links.shopifyIntegrationVideoLink = "https://www.youtube.com/embed/YuhO-2Bce1s";
      configuration.links.msaTermsAndConditionsStandardLink = "https://zip.co";
      configuration.links.msaTermsAndConditionsHairLink = "https://zip.co";
      configuration.links.privacyLink = "https://zip.co/es-mx/politicas-de-privacidad";
      configuration.links.customerTermsLink = "https://zip.co/es-mx/terminos-y-condiciones";
      // Stripe
      configuration.stripe.stripeClientId = "ca_IUKpKIalmbS6ADAiRPoMoUgYfX2ZVBtC";
      configuration.stripe.stripeClientIdDev = "ca_IUKpBKDZ5u5CyTwDu0jSfXiFMgDb67ZT";
      configuration.stripe.stripeConnectBaseUrl = "https://connect.stripe.com/express/oauth/authorize";
      configuration.stripe.stripeConnectRedirectUrl = "https://redirect";
    }
  },
  {
    name: 'Sandbox',
    hostnames: ['merchant-portal-sandbox.quadpay.com', 'merchant.sand.us.zip.co', 'merchant.sand.ca.zip.co'],
    overrideConfiguration: () => {
      configuration.environmentName = "Sandbox";
      configuration.auth.clientId = 'OElLErAEwkOzD2X3R9he8jFIItY45D4o';
      configuration.debug.appLogEnabled = false;
      configuration.google.mapsAutocompleteApiKey = 'AIzaSyD3OF-_3zrf8ksgSPM7MFRMAvdcmvQ0qPE';
      configuration.links.apiDomain = 'https://sandbox.api.quadpay.com/';
      configuration.segmentKey = 'GFYeOZl7wEmE8DTcDA1Qr9M0LCfaJB42';
      configuration.legacyOptimizelySdkKey = 'SYdUkaWBvYC393M2avQY1Z';
      configuration.optimizelySdkKey = 'C26LtvjWBrAQtMFRZDsAg';
      configuration.featureFlags.disputes = true;
      configuration.featureFlags.csvV2Enabled = true;
    },
    applyPreviewConfiguration: () => {
      configuration.featureFlags.statements = true;
      configuration.featureFlags.disputes = true;
      configuration.featureFlags.insights = true;
    }
  },
  {
    name: 'Sandbox-GB',
    hostnames: ['merchant.sand.gb.zip.co'],
    overrideConfiguration: () => {
      configuration.debug.optimizely = true;
      // Content
      configuration.dateFormat = "MMM D, YYYY";
      configuration.address.includeState = false;
      configuration.csvDateFormat = "DD/MM/YYYY";
      configuration.environmentName = "Sandbox";
      configuration.serviceDomains = ['zip.co', 'zip.xyz'];
      configuration.supportEmail = 'noreply@zip.co';
      configuration.isVatEnabled = true;
      configuration.google.mapsAutocompleteApiKey = 'AIzaSyD3OF-_3zrf8ksgSPM7MFRMAvdcmvQ0qPE';
      // Auth
      configuration.auth.audience = "https://auth-merchant.dev.gb.zip.co";
      configuration.auth.clientId = "1zjGI6T5pmSUusVdRNCHepiR97df7sAM";
      configuration.auth.domain = "merchant-login.dev.gb.zip.co";
      // Links
      configuration.links.apiDomain = "https://api.sand.gb.zip.co/";
      configuration.links.shopifyIntegrationVideoLink = "https://www.youtube.com/embed/YuhO-2Bce1s";
      configuration.links.msaTermsAndConditionsStandardLink = "https://zip.co";
      configuration.links.msaTermsAndConditionsHairLink = "https://zip.co";
      configuration.links.privacyLink = "https://zip.co";
      configuration.links.customerTermsLink = "https://zip.co";
      // Stripe
      configuration.stripe.stripeClientId = "ca_IUKpKIalmbS6ADAiRPoMoUgYfX2ZVBtC";
      configuration.stripe.stripeClientIdDev = "ca_IUKpBKDZ5u5CyTwDu0jSfXiFMgDb67ZT";
      configuration.stripe.stripeConnectBaseUrl = "https://connect.stripe.com/express/oauth/authorize";
      configuration.stripe.stripeConnectRedirectUrl = "https://redirect";
    }
  },
  {
    name: 'Sandbox-MX',
    hostnames: ['merchant.sand.mx.zip.co'],
    overrideConfiguration: () => {
      // Feature flag
      configuration.featureFlags.intercom = false;

      configuration.debug.optimizely = true;
      // Content
      configuration.dateFormat = "MMM D, YYYY";
      configuration.address.includeState = false;
      configuration.csvDateFormat = "DD/MM/YYYY";
      configuration.environmentName = "Sandbox";
      configuration.serviceDomains = ['zip.co'];
      configuration.supportEmail = 'mx@partners.zip.co';
      configuration.isVatEnabled = false;
      configuration.google.mapsAutocompleteApiKey = 'AIzaSyD3OF-_3zrf8ksgSPM7MFRMAvdcmvQ0qPE';
      // Auth
      configuration.auth.audience = "https://auth-merchant.dev.mx.zip.co";
      configuration.auth.clientId = "q5kRGwluqSBQC1AbIFQBFojHuiKfpnhr";
      configuration.auth.domain = "merchant-login.dev.mx.zip.co";
      // Links
      configuration.links.apiDomain = "https://api.sand.mx.zip.co/";
      configuration.links.shopifyIntegrationVideoLink = "https://www.youtube.com/embed/YuhO-2Bce1s";
      configuration.links.msaTermsAndConditionsStandardLink = "https://zip.co";
      configuration.links.msaTermsAndConditionsHairLink = "https://zip.co";
      configuration.links.privacyLink = "https://zip.co/es-mx/politicas-de-privacidad";
      configuration.links.customerTermsLink = "https://zip.co/es-mx/terminos-y-condiciones";
      // Stripe
      configuration.stripe.stripeClientId = "placeholder";
      configuration.stripe.stripeClientIdDev = "placeholder";
      configuration.stripe.stripeConnectBaseUrl = "https://connect.stripe.com/express/oauth/authorize";
      configuration.stripe.stripeConnectRedirectUrl = "https://redirect";
    }
  },
  {
    name: 'Production',
    hostnames: ['merchant-portal.quadpay.com', 'merchant.us.zip.co', 'merchant.ca.zip.co'],
    overrideConfiguration: () => {
      configuration.environmentName = "Production";
      configuration.auth.audience = 'https://merchants-auth.quadpay.com';
      configuration.auth.clientId = 'PlgiNyStPRkObbMdLzco03cedi0vsunx';
      configuration.auth.domain = 'merchant-login.us.zip.co';
      configuration.debug.appLogEnabled = false;
      configuration.google.mapsAutocompleteApiKey = 'AIzaSyC39dgupFn7YOwZE8Ezr6y1G8iiRu_5tL4';
      configuration.intercom.applicationId = 'psvwjnbo';
      configuration.links.apiDomain = 'https://api.quadpay.com/';
      configuration.segmentKey = 'xaNctDFEUj8G9HPTE9RuvZnEJ7umxzfI';
      configuration.featureFlags.fullstory = true;
      configuration.legacyOptimizelySdkKey = 'PLGypLdCUhtoNoR9LkwpT8';
      configuration.optimizelySdkKey = 'C26LtvjWBrAQtMFRZDsAg';
      configuration.featureFlags.csvV2Enabled = true;
      configuration.contentful.environment = 'master';
      configuration.contentful.contentDeliveryToken = 'MRd6K6FgaB413cwi4IWgFdZwF1eTrRnnCihblfC9qIY';
      configuration.contentful.contentPreviewToken = 'Z60ImCurByqY4Xeye9Xs1g-noPLV0vXjBFdNTXwoUao';
    },
    applyPreviewConfiguration: () => {
      configuration.featureFlags.statements = true;
      configuration.featureFlags.disputes = true;
      configuration.featureFlags.insights = true;
    }
  },
  {
    name: 'Production-GB',
    hostnames: ['merchant.gb.zip.co'],
    overrideConfiguration: () => {
      configuration.debug.optimizely = true;
      // Content
      configuration.dateFormat = "MMM D, YYYY";
      configuration.address.includeState = false;
      configuration.csvDateFormat = "DD/MM/YYYY";
      configuration.environmentName = "Production";
      configuration.serviceDomains = ['zip.co'];
      configuration.supportEmail = 'noreply@zip.co';
      configuration.isVatEnabled = true;
      configuration.featureFlags.intercom = false;
      configuration.google.mapsAutocompleteApiKey = 'AIzaSyC39dgupFn7YOwZE8Ezr6y1G8iiRu_5tL4';
      // Auth
      configuration.auth.audience = "https://auth-merchant.gb.zip.co";
      configuration.auth.clientId = "eDhYqBnc17FZPDtSptad7clLIgJXLSd7";
      configuration.auth.domain = "merchant-login.gb.zip.co";
      // Links
      configuration.links.apiDomain = "https://api.gb.zip.co/";
      configuration.links.shopifyIntegrationVideoLink = "https://www.youtube.com/embed/YuhO-2Bce1s";
      configuration.links.msaTermsAndConditionsStandardLink = "https://zip.co";
      configuration.links.msaTermsAndConditionsHairLink = "https://zip.co";
      configuration.links.privacyLink = "https://zip.co";
      configuration.links.customerTermsLink = "https://zip.co";
      // Stripe
      configuration.stripe.stripeClientId = "ca_IUKpKIalmbS6ADAiRPoMoUgYfX2ZVBtC";
      configuration.stripe.stripeClientIdDev = "ca_IUKpBKDZ5u5CyTwDu0jSfXiFMgDb67ZT";
      configuration.stripe.stripeConnectBaseUrl = "https://connect.stripe.com/express/oauth/authorize";
      configuration.stripe.stripeConnectRedirectUrl = "https://redirect";
      // segment
      configuration.segmentKey = 'RWF2eEWig3pQm5CdNrJlNORAhlAO9WXk';
    }
  },
  {
    name: 'Production-MX',
    hostnames: ['merchant.mx.zip.co'],
    overrideConfiguration: () => {
      // Feature flag
      configuration.featureFlags.intercom = false;
      
      configuration.debug.optimizely = true;
      // Content
      configuration.dateFormat = "MMM D, YYYY";
      configuration.address.includeState = false;
      configuration.csvDateFormat = "DD/MM/YYYY";
      configuration.environmentName = "Production";
      configuration.serviceDomains = ['zip.co'];
      configuration.supportEmail = 'mx@partners.zip.co';
      configuration.isVatEnabled = false;
      configuration.google.mapsAutocompleteApiKey = 'AIzaSyC39dgupFn7YOwZE8Ezr6y1G8iiRu_5tL4';
      // Auth
      configuration.auth.audience = "https://auth-merchant.mx.zip.co";
      configuration.auth.clientId = "SNavCp2uSrevnGHCykBw7YkiP6ZL5gon";
      configuration.auth.domain = "merchant-login.mx.zip.co";
      // Links
      configuration.links.apiDomain = "https://api.mx.zip.co/";
      configuration.links.shopifyIntegrationVideoLink = "https://www.youtube.com/embed/YuhO-2Bce1s";
      configuration.links.msaTermsAndConditionsStandardLink = "https://zip.co";
      configuration.links.msaTermsAndConditionsHairLink = "https://zip.co";
      configuration.links.privacyLink = "https://zip.co/es-mx/politicas-de-privacidad";
      configuration.links.customerTermsLink = "https://zip.co/es-mx/terminos-y-condiciones";
      // Stripe
      configuration.stripe.stripeClientId = "placeholder";
      configuration.stripe.stripeClientIdDev = "placeholder";
      configuration.stripe.stripeConnectBaseUrl = "https://connect.stripe.com/express/oauth/authorize";
      configuration.stripe.stripeConnectRedirectUrl = "https://redirect";
      // segment
      configuration.segmentKey = 'EFtrBHns6lsxHUuUSHKGIoxDH679LeMe';
    }
  },
];

function getEeApiDomain(){
  if(!process.env.VUE_APP__QUADPAY_ENV__EE) {
   return null;
  }

  return `https://pr-${process.env.VUE_APP__QUADPAY_ENV__EE}.branch.quadpay.xyz/`;
}

function addEnvironmentVariableConfigurationOverrides() {
  configuration.serviceDomains = process.env.VUE_APP__QUADPAY_ENV__serviceDomains
    ? [...configuration.serviceDomains, ...process.env.VUE_APP__QUADPAY_ENV__serviceDomains.split(',')]
    : configuration.serviceDomains;

  configuration.auth.audience                           = process.env.VUE_APP__QUADPAY_ENV__auth__audience                           || configuration.auth.audience;
  configuration.auth.clientId                           = process.env.VUE_APP__QUADPAY_ENV__auth__clientId                           || configuration.auth.clientId;
  configuration.auth.domain                             = process.env.VUE_APP__QUADPAY_ENV__auth__domain                             || configuration.auth.domain;
  configuration.csvDateFormat                           = process.env.VUE_APP__QUADPAY_ENV__csvDateFormat                            || configuration.csvDateFormat;
  configuration.links.apiDomain                         = getEeApiDomain() || process.env.VUE_APP__QUADPAY_ENV__links__apiDomain     || configuration.links.apiDomain;
  configuration.links.customerTermsLink                 = process.env.VUE_APP__QUADPAY_ENV__links__customerTermsLink                 || configuration.links.customerTermsLink;
  configuration.links.msaTermsAndConditionsHairLink     = process.env.VUE_APP__QUADPAY_ENV__links__msaTermsAndConditionsHairLink     || configuration.links.msaTermsAndConditionsHairLink;
  configuration.links.msaTermsAndConditionsStandardLink = process.env.VUE_APP__QUADPAY_ENV__links__msaTermsAndConditionsStandardLink || configuration.links.msaTermsAndConditionsStandardLink;
  configuration.links.privacyLink                       = process.env.VUE_APP__QUADPAY_ENV__links__privacyLink                       || configuration.links.privacyLink;
  configuration.links.shopifyIntegrationVideoLink       = process.env.VUE_APP__QUADPAY_ENV__links__shopifyIntegrationVideoLink       || configuration.links.shopifyIntegrationVideoLink;
  configuration.legacyOptimizelySdkKey                  = process.env.VUE_APP__QUADPAY_ENV__optimizelySdkKey                         || configuration.legacyOptimizelySdkKey;
  configuration.segmentKey                              = process.env.VUE_APP__QUADPAY_ENV__segmentKey                               || configuration.segmentKey;
  configuration.stripe.stripeClientId                   = process.env.VUE_APP__QUADPAY_ENV__stripe__stripeClientId                   || configuration.stripe.stripeClientId;
  configuration.stripe.stripeClientIdDev                = process.env.VUE_APP__QUADPAY_ENV__stripe__stripeClientIdDev                || configuration.stripe.stripeClientIdDev;
  configuration.stripe.stripeConnectBaseUrl             = process.env.VUE_APP__QUADPAY_ENV__stripe__stripeConnectBaseUrl             || configuration.stripe.stripeConnectBaseUrl;
  configuration.stripe.stripeConnectRedirectUrl         = process.env.VUE_APP__QUADPAY_ENV__stripe__stripeConnectRedirectUrl         || configuration.stripe.stripeConnectRedirectUrl;
  configuration.supportEmail                            = process.env.VUE_APP__QUADPAY_ENV__supportEmail                             || configuration.supportEmail;

  if(process.env.VUE_APP__QUADPAY_ENV__links__proxyApiDomain) {
    configuration.links.apiDomain = "https://localhost:8080/api/";
  }
}

const nodeEnvMatchedEnvironment = environments.find((environment) => environment.nodeEnv?.toLowerCase() === process.env.NODE_ENV?.toLowerCase());
const hostnameMatchedEnvironment = environments.find((environment) => environment.hostnames?.includes(window.location.hostname.toLowerCase()));

/**
 * Override configuration for the specific environment.  If a node process env is matched, we'll use that.  Otherwise,
 * we use the hostname matched environment.  If both are matched, only the node process env match is used.
 */
if (nodeEnvMatchedEnvironment?.overrideConfiguration) {
  nodeEnvMatchedEnvironment.overrideConfiguration();
} else if (hostnameMatchedEnvironment?.overrideConfiguration) {
  hostnameMatchedEnvironment.overrideConfiguration();
}

/**
 * Apply preview configurations for the specific environment.
 * This is useful to switch on new features for only users who know how to enable them, and the reset of the users
 * would be still getting standard set of features. 
 */
try
{
  if (localStorage.getItem('PreviewEnabled') === 'true') {
    if (nodeEnvMatchedEnvironment?.applyPreviewConfiguration) {
      nodeEnvMatchedEnvironment.applyPreviewConfiguration();
    } else if (hostnameMatchedEnvironment?.applyPreviewConfiguration) {
      hostnameMatchedEnvironment.applyPreviewConfiguration();
    }
  }
} catch {
  // silently consumed the error as it is not critical. app can continue as usual.
}


addEnvironmentVariableConfigurationOverrides();

export default Object.freeze(configuration);
