import configuration from '@/configuration';
import LogMethod from '@/decorators/logger-decorator';
import { handleResponseErrors } from '@/errors';
import { mssAfterStripePathName, mssRootPathName } from '@/router';
import store, { authenticationStore, merchantStore } from '@/store/store';
import { AuthorizationError } from '@/store/store-models';
import { stripeResult } from '@/store/stripe/stripe-models';
import {
  config,
  Action,
  Module,
  VuexModule,
} from 'vuex-module-decorators';

// Set rawError for all Actions in module to true
config.rawError = true;

// QP Merchant API Url
const merchantApiUrl = `${configuration.links.apiDomain}merchants/`;

/**
 * The merchant store is responsible for managing merchant data and business logic
 */
@Module({
  name: 'stripe',
  namespaced: true,
  store,
})
export default class StripeStore extends VuexModule {
  /**
   * Get Stripe Connect Link
   * @param onboarding whether or not the link being created is for onboarding
   */
  @Action
  @LogMethod
  async getStripeConnectLink(onboarding = true): Promise<string> {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    if (!merchantId) throw new Error('Cannot generate Stripe Connect link without merchantId');

    let url = '';

    if (onboarding) {
      const { onboardingMerchant } = merchantStore;
  
      if (!onboardingMerchant.businessInfo) throw new Error('Will not generate Stripe Connect link without business info');
      const email = authenticationStore.userInfo?.email ? authenticationStore.userInfo.email : '';
      const { businessName, website, firstName, lastName } = onboardingMerchant.businessInfo;
  
      url = `${merchantApiUrl}${merchantId}/stripe/connect-link`
        + `?businessType=company`
        + `&businessName=${encodeURIComponent(businessName || '')}`
        + `&country=${authenticationStore.currentTerritory}`
        + `&website=${encodeURIComponent(website || '')}`
        + `&firstName=${encodeURIComponent(firstName || '')}`
        + `&lastName=${encodeURIComponent(lastName || '')}`
        + `&redirectUrl=${window.location.origin}/${mssRootPathName}/${mssAfterStripePathName}`;
  
      if (email) url += `&email=${encodeURIComponent(email)}`;
    } else {
      url = `${merchantApiUrl}${merchantId}/stripe/connect-link`
        + `?businessType=company`
        + `&country=${authenticationStore.currentTerritory}`
        + `&redirectUrl=${window.location}`;
    }

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      if (response.status > 226) {
        await handleResponseErrors(response, 'Merchant ID');
        throw new Error('Unknown error getting Stripe Connect link');
      } else {
        const body = await response.json();
        // Verify there is a url
        if (!body.url) {
          throw new Error(`Get Stripe Connect link returned no link: ${JSON.stringify(body)}`);
        }
        else {
          return body.url;
        }
      }
    } catch (e) {
      // There generally will only be Responses and no caught errors other than one thrown from response body
      // If 401, throw auth error from above
      if (e instanceof AuthorizationError) throw e;
      // Else throw error message
      throw new Error(e.message);
    }
  }
  
  /**
   * Determine if Stripe sign up process was completed successfully
   * If it was, BE will prepare merchant for integration (create merchant in SQL, set platform, && generate keys)
   */
  @Action
  @LogMethod
  async determineStripeSuccess(): Promise<stripeResult> {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;
    if (!merchantId) throw new Error('Cannot prepare integration without merchantId');

    const url = `${merchantApiUrl}onboarding-users/${merchantId}/prepare-integration`;

    try {
      const response = await fetch(url, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'qp-territory': authenticationStore.currentTerritory,
        },
      });
      if (response.status > 226) {
        // Stripe failures will always come back as 422
        if (response.status === 422) {
          const body = await response.json();
          const failureReasonsArray = body.UnprocessableEntityExceptionMessage;
          // Even though the response is an array, all failure reasons will already be joined by | in the first (only) element of the array
          const failureReasons = failureReasonsArray[0];
          return { success: false, failureReasons };
        }
        // If it returns as any other bad status code (ex 401) throw error
        await handleResponseErrors(response, 'Merchant ID', false, true);
        // Must add return false to stay consistent with type
        return { success: false };
      } else {
        const body = await response.json();
        // Req body will hold updated onboardingMerchant, including stage, clientId and clientSecret
        const { stage, clientId, clientSecret } = body;
        merchantStore.setOnboardingMerchant({ ...merchantStore.onboardingMerchant, stage });
        merchantStore.setMerchant({ ...merchantStore.merchant, apiKey: { clientId, clientSecret } });
        return { success: true };
      }
    } catch (e) {
      // If 401, throw auth error
      if (e instanceof AuthorizationError) throw e;
      // Else throw error message
      throw new Error(e.message);
    }
  }
  
  /**
   * Determine if Stripe sign up process was completed successfully
   * If it was, BE will prepare merchant for integration (create merchant in SQL, set platform, && generate keys)
   */
  @Action
  @LogMethod
  async updateSFDCStripeFailure(failureReasons: string) {
    const { accessToken } = authenticationStore;
    const merchantId = authenticationStore.merchantId;

    const url = `${merchantApiUrl}onboarding-users/stripe-failure`;
    const body = {
      merchantId,
      failureReasons,
    };

    await fetch(url, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${accessToken}`,
        "content-type": "application/json-patch+json",
        'qp-territory': authenticationStore.currentTerritory,
      },
      body: JSON.stringify(body),
    });
  }
}
