













































































































import logger from '@/logger';
import {
  bigCommerce,
  magento2,
  oracleAtg,
  shopify,
  shopifyCartHook,
  spree,
  woocommerce,

} from '@/store/merchant/merchant-settings';
import { authenticationStore, merchantStore } from '@/store/store';
import { Component, Watch, Vue } from 'vue-property-decorator';

@Component
export default class MerchantIntegrationInformation extends Vue {

  /**
   * Return true if the merchant is an MSS-supported platform.  If not,
   * we will use that determination to show other integration credentials
   * to better ensure we can add more platforms without limiting the
   * merchant's experience with this component.
   */
  get hasCredentialDisplayHandlingForPlatform(): boolean {
    return [
      bigCommerce.systemName,
      magento2.systemName,
      oracleAtg.systemName,
      shopify.systemName,
      shopifyCartHook.systemName,
      spree.systemName,
      woocommerce.systemName,
    ].indexOf(merchantStore.platform) >= 0;
  }

  // Get values
  get merchantName(): string {
    return merchantStore.merchantName;
  }
  get merchantId(): string {
    return merchantStore.isExistingMerchant ? merchantStore.merchantId : merchantStore.onboardingMerchantId;
  }
  get clientId(): string {
    return merchantStore.clientId;
  }
  get clientSecret(): string {
    return merchantStore.clientSecret;
  }

  // Get show booleans
  // Always show merchantID regardless of platform
  showMerchantId = true;
  get showMerchantName(): boolean {
    return [
      bigCommerce.systemName,
      magento2.systemName,
      shopifyCartHook.systemName,
      spree.systemName,
    ].indexOf(merchantStore.platform) >= 0 || !this.hasCredentialDisplayHandlingForPlatform;
  }
  get showClientId(): boolean {
    return [
      magento2.systemName,
      oracleAtg.systemName,
      shopifyCartHook.systemName,
      spree.systemName,
      woocommerce.systemName,
    ].indexOf(merchantStore.platform) >= 0 || !this.hasCredentialDisplayHandlingForPlatform;
  }
  get showClientSecret(): boolean {
    return [
      magento2.systemName,
      oracleAtg.systemName,
      shopifyCartHook.systemName,
      woocommerce.systemName,
    ].indexOf(merchantStore.platform) >= 0 || !this.hasCredentialDisplayHandlingForPlatform;
  }
  get showApiKey(): boolean {
    return !this.hasCredentialDisplayHandlingForPlatform;
  }

  /**
    BigCommerce
    merchantId
    (SMI -- hide keys)

    Magento 2
    merchantName
    merchantId
    clientID
    clientSecret
    
    Oracle
    merchantId
    clientID
    clientSecret

    Salesforce Commerce Cloud
    merchantId

    Shopify
    merchantId
    api key (client secret)

    Spree
    merchantName
    merchantId
    clientId

    Sylius
    merchantId

    WooCommerce
    merchantId
    clientID
    clientSecret
   */

  copy(event: ClipboardEvent, elementId: string) {
    let elementToCopy = document.getElementById(elementId);
    if (!elementToCopy) return;
    (elementToCopy as any).select();

    try {
      document.execCommand('copy');
      // TODO better UX for successful copy
      logger.info('Copy successful');
    } catch (err) {
      alert(this.$t("merchantIntegrationInformation.unable-copy-alert"));
    }
    // Unselect the range
    (window as any).getSelection().removeAllRanges();
  }

  get currentIdentity() {
    return authenticationStore.currentIdentity;
  }

  @Watch('merchantId')
  async updateSearch() {
    if(this.merchantId){
      await merchantStore.getKeys(true);
    }
  }
  
  async mounted() {
    await merchantStore.getKeys(true);
  }
}
