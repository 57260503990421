














import configuration from '@/configuration';
import { stripeStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';
import eventBus from '@/event-bus';

@Component
export default class Settings extends Vue {
  redirectLink = '';

  get companyName(): string {
    return configuration.company.name;
  }

  async goToStripe() {
    if (!this.redirectLink) throw new Error('No Stripe Connect link');
    eventBus.publishTrackClickEvent('Stripe Connect Link');
    window.location.href = this.redirectLink;
  }

  async mounted() {
    this.redirectLink = await stripeStore.getStripeConnectLink(false);
  }
}
