import configuration from '@/configuration';
import eventBus from '@/event-bus';
import logger from '@/logger';
import { cspNonce } from '@/security';
import { authenticationStore, merchantStore } from '@/store/store';
import { isFeatureEnabled } from './feature-flags';

const applicationId: string = configuration.intercom.applicationId;
const intercomScriptId = 'qp-intercom-script';
const intercomSettings: Intercom_.IntercomSettings = {
  app_id: applicationId,
  background_color: '#AA8FFF',
  action_color: '#AA8FFF'
};

async function verifyOptimizelyEnabled(): Promise<boolean> {
  return await isFeatureEnabled(configuration.intercom.optimizely.key);
}

async function verifyAuthenticated(): Promise<boolean> {
  const maxTries = 100;
  let attempts = 0;
  let isAuthenticated = false;
  let hasMerchantLoaded = false;

  return await new Promise(resolve => {
    const interval = setInterval(() => {
      attempts++;

      isAuthenticated = authenticationStore.authenticated;
      hasMerchantLoaded = merchantStore.hasMerchantLoaded;

      if (attempts === maxTries || (isAuthenticated && hasMerchantLoaded)) {
        resolve(isAuthenticated);
        clearInterval(interval);
      }
    }, 50);
  });
}

function addIntercomScript() {
  intercomSettings.name = merchantStore.merchantContactName;
  intercomSettings.email = merchantStore.merchantEmail;

  // If the email still isn't set, use the email used for authentication
  if (intercomSettings.email?.length === 0 && authenticationStore.authenticated) {
    intercomSettings.email = authenticationStore.userInfo.email;
  }

  const script = document.createElement('script');

  script.id = intercomScriptId;
  script.type = 'text/javascript';
  script.setAttribute('nonce', cspNonce);

  /*
    Intercom's default script attempts to add the creation of the custom script element to an event listener. This caused issues
    due to the assumption that the event is fired before the action is added to the event listener, resulting in the script never
    getting created after logging into the application. To get around this, the addition to the event listener has been removed
    and the event is fired at the end of the following script.
  */
  script.innerHTML += `window.intercomSettings = ${JSON.stringify(intercomSettings)};(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${applicationId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};l()}})();`;

  document.getElementsByTagName('body')[0].appendChild(script);
}

function updateIntercom(firstName: string, lastName: string) {
  intercomSettings.name = `${firstName} ${lastName}`;

  if (isIntercomSet()) {
    Intercom('update', intercomSettings);
  }
}

function logResult() {
  const isLoaded = isIntercomSet();

  if (isLoaded) {
    logger.info('Successfully loaded Intercom!');
    eventBus.publishIntercomLoadedEvent();
    return;
  }

  logger.warn('Intercom did not load!');
}

function isIntercomSet(): boolean {
  return !!document.getElementById(intercomScriptId);
}

export default {
  async load(): Promise<void> {
    const enabled = await verifyOptimizelyEnabled();

    if (enabled) {
      const authenticated = await verifyAuthenticated();

      if (authenticated) {
        addIntercomScript();
      }
    }

    logResult();
  },

  async updateSettings(firstName: string, lastName: string): Promise<void> {
    const enabled = await isFeatureEnabled(configuration.intercom.optimizely.key);

    if (enabled) {
      updateIntercom(firstName, lastName);
    }
  }
};