











import CenteredImage from '@/components/common/CenteredImage.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    'centered-image': CenteredImage,
  },
})
export default class LoadingSpinner extends Vue {
  get spinnerSrc(): string {
    return 'loading-light.gif';
  }
}
