











import { ComparableAggregatedResult } from '@/store/insights/insights-models';
import { Component, Prop, Vue } from 'vue-property-decorator';
import AggregatedResultChart from './AggregatedResultChart.vue';

@Component({
  components: {
    'aggregated-result-chart': AggregatedResultChart
  }
})
export default class OrderCountChart extends Vue {
  @Prop({ required: true })
  data: ComparableAggregatedResult;

  @Prop({ required: false })
  loading: boolean;

  getValue(entry: any): number {
    return entry?.entityCount ?? 0;
  }

  formatValue(value: number): string {
    return `${value.toFixed(0)}`;
  }
}
