








import eventBus from '@/event-bus';

import { settingsPageName } from '@/router';
import { applicationStore } from '@/store/store';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class TimezoneNotice extends Vue {
  get canSwitchTimezones(): boolean {
    if (!applicationStore.supportedTimezones) {
      return false;
    }

    if (Array.isArray(applicationStore.supportedTimezones)) {
      return applicationStore.supportedTimezones.length > 1
    }
    
    return Object.keys(applicationStore.supportedTimezones).length > 1;
  }

  get currentTimezone(): string {
    return this.$t(`timezones.${applicationStore.currentTimezone.code}`).toString();
  }

  get settingsPageName(): string {
    return settingsPageName;
  }

  mounted() {
    if(this.$refs['timezoneSwitcher']) {
      const ref = this.$refs['timezoneSwitcher'] as HTMLElement;
      ref.getElementsByTagName('a').item(0)?.addEventListener('click', () => {
        eventBus.publishTrackClickEvent('Timezone Switcher Clicked');
      })
    }
  }
}
